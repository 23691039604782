/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/12/2023
 * @description  React component PrivacyPolicySocial presenting a privacy policy for social media use. It includes sections detailing user information, profile usage, publications, and regulations concerning minors or individuals with special needs. The component utilizes various UI elements like Container, Box, Heading, Text, and DotList for structured content display.
 */
import Container from "../../components/Container/Container";
import {Box, Heading, Text} from "native-base";
import DotList from "../../components/ListDot/ListDot";
import {theme} from "../../styles/theme";


const firstDotList = [
    {
        value: "Tramitar solicitudes y consultas planteadas al responsable",
    },
    {
        value: "Informar sobre actividades y eventos organizados por el responsable"
    }, {
        value: "Informar sobre productos o servicios ofrecidos por el responsable"
    }, {
        value: "Interactuar a través de los perfiles oficiales"
    }
]


const secondDotList = [
    {
        value: "Derecho a retirar el consentimiento en cualquier momento",
    },
    {
        value: "Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento"
    }, {
        value: "Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente"
    }
]

const thirdDotList = [
    {
        value: "Acceso a la información pública del perfil",
    },
    {
        value: "Publicación en el perfil del USUARIO de toda aquella información ya publicada en la red social del RESPONSABLE"
    }, {
        value: "Enviar mensajes personales e individuales a través de los canales de la red social"
    }, {
        value: "Actualizaciones del estado de la página que se publicarán en el perfil del USUARIO"
    }
]

export default function PrivacyPolicySocial() {
    return <Container>

        <Box mb={6}>
            <Heading mb={3}>1. INFORMACIÓN AL USUARIO</Heading>
            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Quién es el responsable del tratamiento de
                tus datos personales?</Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L, en adelante, <Text fontWeight={700}>RESPONSABLE</Text>, informa
                al <Text fontWeight={700}>USUARIO</Text> de que ha
                procedido a crear un perfil en las Redes Sociales Facebook, Instagram, Twitter, LinkedIn, Youtube, Vimeo
                y Google+, que es el responsable del tratamiento de los datos personales del usuario que se lleve a cabo
                en dichas redes sociales y le informa de que estos datos serán tratados de conformidad con lo dispuesto
                en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre
                (LOPDGDD), por lo que se le facilita la siguiente información del tratamiento:</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Para qué tratamos tus datos
                personales?</Text>
            <Text mb={3}><Text fontWeight={700}>Fin del tratamiento:</Text> mantener una relación entre el USUARIO y el
                RESPONSABLE que puede incluir las
                siguientes operaciones:</Text>

            <DotList data={firstDotList}></DotList>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Por qué motivo podemos tratar tus datos
                personales?</Text>
            <Text mb={3}><Text fontWeight={700}>Base jurídica del tratamiento:</Text> artículo 6.1.a GDPR, el interesado
                ha dado su consentimiento para el
                tratamiento de sus datos personales para uno o varios fines específicos. El USUARIO dispone de un perfil
                en la misma red social y ha decidido unirse a la red social del RESPONSABLE mostrando así interés en
                la información que se publique en la misma, por tanto, en el momento de solicitar seguir nuestros
                perfiles
                oficiales, nos facilita su consentimiento para el tratamiento de aquellos datos personales publicados en
                su
                perfil.</Text>
            <Text mb={3}>El USUARIO puede acceder en todo momento a las políticas de privacidad de la propia red social,
                así
                como configurar su perfil para garantizar su privacidad.</Text>
            <Text mb={3}>El RESPONSABLE tiene acceso y trata aquella información pública del USUARIO, en especial, su
                nombre de contacto. Estos datos solo son utilizados dentro de la propia red social y únicamente se
                incorporarán a un fichero del RESPONSABLE cuando sea necesario para tramitar la petición del
                USUARIO.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Durante cuánto tiempo guardaremos tus
                datos personales?</Text>
            <Text mb={3}><Text fontWeight={700}>Criterios de conservación de los datos:</Text> se conservarán mientras
                el USUARIO no revoque el
                consentimiento prestado tal y como se indica en esta política de privacidad.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿A quién facilitamos tus datos
                personales?</Text>
            <Text mb={3}><Text fontWeight={700}>Comunicación de los datos:</Text> la información facilitada por el
                USUARIO a través de las redes sociales del
                RESPONSABLE, incluidos sus datos personales, puede ser publicada, siempre en función de los
                servicios que el USUARIO utilice, por lo que podrá quedar a disposición pública de otros terceros
                usuarios
                de las redes sociales. Desde el perfil de cada red social, el USUARIO puede configurar qué información
                quiere hacer pública en cada caso, ver los permisos que se han concedido, eliminarlos o desactivarlos,
                como cualquier aplicación de un tercero que ya no se desea utilizar.</Text>
            <Text mb={3}>No está prevista ninguna comunicación de datos personales a terceros fuera de la red social
                salvo, si
                fuese imprescindible para el desarrollo y ejecución de las finalidades del tratamiento, a nuestros
                proveedores de servicios relacionados con comunicaciones, con los cuales el RESPONSABLE tiene
                suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos por la normativa
                vigente de privacidad.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Cuáles son tus derechos?</Text>
            <Text mb={3}><Text fontWeight={700}>Derechos que asisten al USUARIO:</Text> solo podrán satisfacerse en
                relación a aquella información que se
                encuentre bajo el control del RESPONSABLE.</Text>
            <DotList data={secondDotList}></DotList>

            <Text mb={3} fontWeight={700}>Datos de contacto para ejercer sus derechos:</Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>
        </Box>


        <Box mb={6}>
            <Heading mb={3}>2. UTILIZACIÓN DEL PERFIL</Heading>
            <Text mb={3}>El RESPONSABLE realizará las siguientes actuaciones:</Text>

            <DotList data={thirdDotList}></DotList>

            <Text mb={3}>El USUARIO siempre puede controlar sus conexiones, eliminar los contenidos que dejen de
                interesarle y
                restringir con quién comparte sus conexiones; para ello deberá acceder a su configuración de
                privacidad.</Text>

        </Box>

        <Box mb={6}>
            <Heading mb={3}>3. PUBLICACIONES</Heading>
            <Text mb={3}>El USUARIO, una vez sea seguidor o se haya unido a la red social del RESPONSABLE, podrá
                publicar en
                esta comentarios, enlaces, imágenes, fotografías o cualquier otro tipo de contenido multimedia soportado
                por la misma. El USUARIO, en todos los casos, debe ser el titular del contenido publicado, gozar de los
                derechos de autor y de propiedad intelectual o contar con el consentimiento de los terceros
                afectados.</Text>
            <Text mb={3}>Se prohíbe expresamente cualquier publicación en la red social, ya sean textos, gráficos,
                fotografías,
                vídeos, etc. que atenten o sean susceptibles de atentar contra la moral, la ética, el buen gusto o el
                decoro,
                y/o que infrinjan, violen o quebranten los derechos de propiedad intelectual o industrial, el derecho a
                la
                imagen o la Ley.</Text>
            <Text mb={3}>En estos casos, el RESPONSABLE se reserva el derecho a retirar de inmediato el contenido, sin
                comunicación previa, pudiendo solicitar el bloqueo permanente del USUARIO.</Text>
            <Text mb={3}>El RESPONSABLE no se hará responsable de los contenidos que libremente ha publicado un
                USUARIO.</Text>
            <Text mb={3}>El USUARIO debe tener presente que sus publicaciones serán conocidas por otros usuarios, por lo
                que él
                mismo es el principal responsable de su privacidad.</Text>
            <Text mb={3}>Las imágenes que puedan publicarse en la red social no serán almacenadas en ningún fichero por
                parte
                del RESPONSABLE, pero sí que permanecerán en la red social.</Text>


        </Box>

        <Box mb={6}>
            <Heading mb={3}>4. DATOS DE MENORES DE EDAD O PERSONAS CON CAPACIDADES ESPECIALES</Heading>
            <Text mb={3}>El acceso y registro a través de la redes sociales del RESPONSABLE está prohibido a menores de
                18
                años. Por su parte, si el USUARIO tiene capacidades especiales, será necesaria la intervención del
                titular
                de su patria potestad o tutela, o de su representante legal mediante documento válido que acredite la
                representación.</Text>
            <Text mb={3}>El RESPONSABLE quedará expresamente exonerado de cualquier responsabilidad que pudiera
                derivarse
                del uso de las redes sociales por parte de menores o personas con capacidades especiales. Las redes
                sociales del RESPONSABLE no recogen conscientemente ninguna información personal de menores de
                edad, por ello, si el USUARIO es menor de edad, no debe registrarse, ni utilizar las redes sociales del
                RESPONSABLE ni tampoco proporcionar ninguna información personal.</Text>


        </Box>

    </Container>
}
