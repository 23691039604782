/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/12/2023
 * @description This React component renders legal advice content structured into sections. It includes details such as identification data, intellectual property rights, exemptions of responsibility, cookie usage policy, and jurisdiction. The content is presented within Box and Text components from a library like Material-UI, with headings for each section. Links are provided for external references. The component follows a simple functional component pattern and can be further customized with styles and additional functionality if needed.
 */
import Container from "../../components/Container/Container";
import {Box, Heading, Link, Text} from "native-base";

export default function LegalAdvisePage() {
    return <Container>
        <Box mb={6}>
            <Heading mb={3}>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN (L.S.S.I.)</Heading>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L, responsable del sitio web, en adelante RESPONSABLE, pone a disposición de
                los usuarios el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas
                en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio
                Electrónico (LSSICE), BOE N.º 166, así como informar a todos los usuarios del sitio web respecto a
                cuáles son las condiciones de uso. </Text>
            <Text mb={3}>Toda persona que acceda a este sitio web asume el papel de usuario,
                comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como
                a cualquier otra disposición legal que fuera de aplicación. </Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L se reserva el
                derecho de modificar cualquier tipo de información que pudiera aparecer en el sitio web, sin que exista
                obligación de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendiéndose como
                suficiente la publicación en el sitio web de PRUDENZIA BROKER GROUP S.L. </Text>
        </Box>

        <Box mb={6}>
            <Heading mb={3}>1. DATOS IDENTIFICATIVOS</Heading>
            <Text mb={3}>Nombre de dominio: <Link href={"https://www.prudenzia.es"} isExternal={true}>www.prudenzia.es</Link></Text>

            <Text mb={3}>Denominación social: PRUDENZIA BROKER GROUP S.L</Text>

            <Text mb={3}>NIF: B05461165</Text>

            <Text mb={3}>Domicilio social: PASEO 12 ESTRELLAS, 2 1 A 28042 Madrid (Madrid)</Text>

            <Text mb={3}>Teléfono: 910059107</Text>

            <Text mb={3}>E-mail: administracion@prudenzia.es</Text>

            <Text mb={3}>Inscrita en el Registro Mercantil de Madrid, en la Hoja M-742.842, Tomo 41.948, Folio 121.</Text>
        </Box>

        <Box mb={6}>

            <Heading mb={3}>2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</Heading>
            <Text mb={3}>El sitio web, incluyendo a título enunciativo pero no limitativo su programación, edición, compilación
                y
                demás elementos necesarios para su funcionamiento, los diseños, logotipos, texto y/o gráficos, son
                propiedad
                del RESPONSABLE o, si es el caso, dispone de licencia o autorización expresa por parte de los autores.
                Todos
                los contenidos del sitio web se encuentran debidamente protegidos por la normativa de propiedad
                intelectual
                e industrial, así como inscritos en los registros públicos correspondientes. Independientemente de la
                finalidad para la que fueran destinados, la reproducción total o parcial, uso, explotación, distribución
                y
                comercialización, requiere en todo caso la autorización escrita previa por parte del RESPONSABLE.
                Cualquier
                uso no autorizado previamente se considera un incumplimiento grave de los derechos de propiedad
                intelectual
                o industrial del autor.</Text>

            <Text mb={3}>Los diseños, logotipos, texto y/o gráficos ajenos al RESPONSABLE y que pudieran aparecer en el sitio
                web,
                pertenecen a sus respectivos propietarios, siendo ellos mismos responsables de cualquier posible
                controversia que pudiera suscitarse respecto a los mismos.</Text>

                <Text mb={3}>El RESPONSABLE autoriza expresamente a que terceros puedan redirigir directamente a los contenidos
                concretos
                del sitio web, y en todo caso redirigir al sitio web principal de <Link href={"https://www.prudenzia.es"} isExternal={true}>www.prudenzia.es</Link>. El RESPONSABLE
                reconoce
                a favor de sus titulares los correspondientes derechos de propiedad intelectual e industrial, no
                implicando
                su sola mención o aparición en el sitio web la existencia de derechos o responsabilidad alguna sobre los
                mismos, como tampoco respaldo, patrocinio o recomendación por parte del mismo.</Text>


            <Text mb={3}>Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de
                propiedad
                intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a
                través
                del correo electrónico administracion@prudenzia.es.</Text>
        </Box>

        <Box mb={6}>

            <Heading mb={3}>3. EXENCIÓN DE RESPONSABILIDADES
            </Heading>
            <Text mb={3}>El RESPONSABLE se exime de cualquier tipo de responsabilidad derivada de la información publicada en
                su
                sitio web siempre que no tenga conocimiento efectivo de que esta información haya sido manipulada o
                introducida por un tercero ajeno al mismo o, si lo tiene, haya actuado con diligencia para retirar los
                datos
                o hacer imposible el acceso a ellos.</Text>

            <Text mb={3} fontWeight={"700"}>Uso de Cookies</Text>

            <Text mb={3}>Este sitio web puede utilizar cookies técnicas (pequeños archivos de información que el servidor envía
                al
                ordenador de quien accede a la página) para llevar a cabo determinadas funciones que son consideradas
                imprescindibles para el correcto funcionamiento y visualización del sitio.</Text>

            <Text mb={3}>Las cookies utilizadas tienen, en todo caso, carácter temporal, con la única finalidad de hacer más
                eficaz
                la navegación, y desaparecen al terminar la sesión del usuario. En ningún caso, estas cookies
                proporcionan
                por sí mismas datos de carácter personal y no se utilizarán para la recogida de los mismos.</Text>

            <Text mb={3}>Mediante el uso de cookies también es posible que el servidor donde se encuentra la web reconozca el
                navegador utilizado por el usuario con la finalidad de que la navegación sea más sencilla, permitiendo,
                por
                ejemplo, el acceso de los usuarios que se hayan registrado previamente a las áreas, servicios,
                promociones o
                concursos reservados exclusivamente a ellos sin tener que registrarse en cada visita. También se pueden
                utilizar para medir la audiencia, parámetros de tráfico, controlar el progreso y número de entradas,
                etc.,
                siendo en estos casos cookies prescindibles técnicamente, pero beneficiosas para el usuario. Este sitio
                web
                no instalará <Text mb={3} fontWeight={"700"}>cookies</Text> prescindibles sin el consentimiento previo del usuario.</Text>

            <Text mb={3}>El usuario tiene la posibilidad de configurar su navegador para ser alertado de la recepción de cookies
                y
                para impedir su instalación en su equipo. Por favor, consulte las instrucciones de su navegador para
                ampliar
                esta información.</Text>


            <Text mb={3} fontWeight={"700"}>Política de enlaces</Text>

            <Text mb={3}>Desde el sitio web, es posible que se redirija a contenidos de terceros sitios web. Dado que EL
                RESPONSABLE
                no puede controlar siempre los contenidos introducidos por terceros en sus respectivos sitios web, no
                asume
                ningún tipo de responsabilidad respecto a dichos contenidos. En todo caso, procederá a la retirada
                inmediata
                de cualquier contenido que pudiera contravenir la legislación nacional o internacional, la moral o el
                orden
                público, procediendo a la retirada inmediata de la redirección a dicho sitio web, poniendo en
                conocimiento
                de las autoridades competentes el contenido en cuestión.</Text>


            <Text mb={3}>El RESPONSABLE no se hace responsable de la información y contenidos almacenados, a título enunciativo
                pero
                no limitativo, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquier otro medio
                que
                permita a terceros publicar contenidos de forma independiente en la página web del RESPONSABLE. Sin
                embargo,
                y en cumplimiento de lo dispuesto en los artículos 11 y 16 de la LSSICE, se pone a disposición de todos
                los
                usuarios, autoridades y fuerzas de seguridad, colaborando de forma activa en la retirada o, en su caso,
                bloqueo de todos aquellos contenidos que puedan afectar o contravenir la legislación nacional o
                internacional, los derechos de terceros o la moral y el orden público. En caso de que el usuario
                considere
                que existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación, se ruega
                lo
                notifique de forma inmediata al administrador del sitio web.</Text>

            <Text mb={3}>Este sitio web se ha revisado y probado para que funcione correctamente. En principio, puede
                garantizarse el
                correcto funcionamiento los 365 días del año, 24 horas al día. Sin embargo, el RESPONSABLE no descarta
                la
                posibilidad de que existan ciertos errores de programación, o que acontezcan causas de fuerza mayor,
                catástrofes naturales, huelgas o circunstancias semejantes que hagan imposible el acceso a la página
                web.</Text>
                <Text mb={3} fontWeight={"700"}>Direcciones IP</Text>
                    <Text mb={3}>Los servidores del sitio web podrán detectar de manera automática la dirección IP y el
                nombre
                de dominio utilizados por el usuario. Una dirección IP es un número asignado automáticamente a un
                ordenador
                cuando este se conecta a Internet. Toda esta información se registra en un fichero de actividad del
                servidor
                que permite el posterior procesamiento de los datos con el fin de obtener mediciones únicamente
                estadísticas
                que permitan conocer el número de impresiones de páginas, el número de visitas realizadas a los
                servidores
                web, el orden de visitas, el punto de acceso, etc.</Text>
        </Box>

        <Box mb={6}>
            <Heading mb={3}>4. LEY APLICABLE Y JURISDICCIÓN
            </Heading>
            <Text mb={3}>Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de
                las
                actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten
                expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o
                relacionados con su uso los Juzgados y Tribunales del domicilio del USUARIO o el lugar del cumplimiento
                de
                la obligación.</Text>
        </Box>

    </Container>
}
