/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/12/2023
 * @description The `Table` component is designed to render tabular data with customizable themes ("basic" or "light"). It dynamically adjusts its width based on the container's size and supports horizontal scrolling for wider tables. The component can display a header row (`dataHeader`) and multiple data rows (`data`), styled according to the selected theme. The "basic" theme provides a straightforward presentation, while the "light" theme offers a more visually appealing layout with light backgrounds and distinct text styling. This component leverages React Native components like `ScrollView` and `View` for layout and scrolling capabilities, ensuring a responsive and flexible table display suitable for various screen sizes and device orientations.
 */
import {ScrollView, View} from "native-base";
import {Row, Table as TableN} from 'react-native-table-component';
import {StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {useState} from "react";

/**
 *
 * @param data
 * @param dataHeader
 * @param theme light or basic
 * @returns {JSX.Element}
 * @constructor
 */
export default function Table({data, dataHeader, theme = "basic"}) {
    const [width, setWidth] = useState();

    return <View style={styles[theme].container} onLayout={(event) => {
        setWidth(event.nativeEvent.layout.width - 2)
    }}>
        {width && <><ScrollView horizontal={true}>
            <View>

                {dataHeader && <TableN borderStyle={styles[theme].border} style={{width}}>
                    <Row data={dataHeader} style={styles[theme].header} textStyle={styles[theme].headerText}/>
                </TableN>}
                <TableN borderStyle={styles[theme].border} style={{width}}>
                    {
                        data.map((rowData, index) => (
                            <Row
                                key={index}
                                data={rowData}
                                textStyle={styles[theme].text}
                            />
                        ))
                    }
                </TableN>

            </View>
        </ScrollView>
        </>}
    </View>
}


const styles = StyleSheet.create({
    basic: {
        container: {flex: 1, width: "100%"},
        header: {height: 40, backgroundColor: theme.colors.secondary["100"]},
        text: {textAlign: 'center', fontWeight: '100', padding: 5},
        headerText: {textAlign: 'center', fontWeight: '100', padding: 5},
        dataWrapper: {marginTop: -1},
        border: {borderWidth: 1}
    },
    light: {
        container: {
            flex: 1,
            width: "100%",
            backgroundColor: theme.colors.gray[0],
            borderRadius: 6,
            borderWidth: 1,
            borderColor: theme.colors.gray[100],
            overflow: "hidden"
        },
        header: { backgroundColor: theme.colors.gray[0]},
        text: {
            textAlign: "center",
            fontWeight: '100',
            padding: 10
        }, headerText: {
            color: theme.colors.secondary["700"],
            textAlign: "center",
            fontWeight: 600,
            padding: 10
        },
        dataWrapper: {margin: -2, backgroundColor: theme.colors.gray[0]},
        border: {borderWidth: 0, borderColor: theme.colors.gray[100]}

    }

});
