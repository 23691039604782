/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/10/2023
 * @description The `AdvisorPage` component showcases information about an assigned advisor within a user's interface, leveraging the `GlobalContext` to access advisor data. It adapts its display based on the screen size, determining if the device is a desktop or mobile through media queries. On desktop devices, the advisor's detailed description might not be immediately visible, encouraging a more interactive engagement from the user to explore the advisor's details. Conversely, on mobile devices, where screen real estate is limited and user interaction patterns differ, the advisor's description is shown by default to ensure that essential information is readily accessible. This design choice enhances the user experience by tailoring the content's presentation to the device type, ensuring that users have the best interaction possible regardless of how they access the service.
 */
import {useBreakpointValue, useMediaQuery} from "native-base";
import Container from "../../components/Container/Container";
import Advisor from "../../components/Advisor/Advisor";
import {breakpoints} from "../../styles/theme";
import {useContext} from "react";
import {GlobalContext} from "../../consts/globalContext.const";

export default function AdvisorPage() {
    const {advisor} = useContext(GlobalContext)
    const minWidth = useBreakpointValue({
        md: breakpoints.md
    });
    const [isDesktop] = useMediaQuery({
        minWidth
    });

    return <Container>
        {advisor && <Advisor data={advisor} isShowingDescriptionByDefault={!isDesktop}/>}
    </Container>
}
