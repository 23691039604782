/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 19/09/2023
 * @description The `InsuranceGallery` component displays a responsive gallery of insurance options, adjusting its layout based on the screen size. It dynamically sets the size of each item based on the number of columns, which changes between desktop and mobile views. Each item can display an image or a custom icon and includes the insurance name. Items are selectable, with selected items visually distinguished from others. The gallery supports conditional rendering, only showing items marked as visible. This component is designed for flexibility and responsiveness, fitting various screen sizes and enhancing user interaction with visual feedback on selection.
 */
import {
  Box,
  Column,
  Image,
  Pressable,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from "native-base";
import { StyleSheet } from "react-native";
import { breakpoints, theme } from "../../styles/theme";
import React, { useState } from "react";
import { CustomIcons } from "../../App";

const gap = 15;
export default function InsuranceGallery({ data, selectedData, onPress }) {
  const [individualSize, setIndividualSize] = useState();
  const minWidth = useBreakpointValue({
    md: breakpoints.md,
  });
  const [isDesktop] = useMediaQuery({
    minWidth,
  });
  const numColumns = isDesktop ? 5 : 3;
  return (
    <Box
      onLayout={(event) =>
        setIndividualSize(
          event.nativeEvent.layout.width / numColumns - gap + gap / numColumns
        )
      }
      style={styles.container}
    >
      {individualSize &&
        data.map((item, index) =>
          item.visible ? (
            <Pressable
              onPress={() => onPress(item)}
              key={index}
              style={
                selectedData &&
                selectedData.findIndex(
                  (selectedItem) => selectedItem.id === item.id
                ) !== -1
                  ? { ...styles.box, ...styles.selectedBox }
                  : styles.box
              }
              width={individualSize}
            >
              {!item.logoLibrary && (
                <Image
                  style={styles.image}
                  resizeMode={"contain"}
                  source={{
                    uri: process.env.REACT_APP_IMAGE_URL + item.logo,
                  }}
                  alt={item.name}
                />
              )}
              {item.logoLibrary && (
                <CustomIcons
                  name={item.logo}
                  color={theme.colors.primary["700"]}
                  size={individualSize / 2}
                />
              )}
              <Column
                height={"30%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {/*{item.logoLibrary === "COMMUNITY" &&*/}
                {/*    <MaterialCommunityIcons name={item.logo} color={theme.colors.primary["700"]}*/}
                {/*                            size={individualSize / 2}/>}*/}
                {/*{item.logoLibrary === "MATERIAL" &&*/}
                {/*    <MaterialIcons name={item.logo} color={theme.colors.primary["700"]} size={individualSize / 2}/>}*/}

                <Box width={individualSize - 20}>
                  <Text
                    mt={1}
                    textAlign={"center"}
                    fontSize={12}
                    numberOfLines={2}
                  >
                    {item.name}
                  </Text>
                </Box>
              </Column>
            </Pressable>
          ) : (
            <></>
          )
        )}
    </Box>
  );
}

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    gap,
    flexDirection: "row",
    width: "100%",
  },
  box: {
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: theme.colors.gray["100"],
    backgroundColor: theme.colors.white,
    padding: 10,
    borderRadius: 6,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
  selectedBox: {
    borderColor: theme.colors.secondary["500"],
    backgroundColor: theme.colors.secondary["50"],
  },
  image: {
    width: "100%",
    height: "70%",
  },
});
