const baseStyle = {
  shadow: 4,
  borderRadius: 'md',
  padding: 4,
  overflow: 'hidden',
};
const defaultProps = {};
export default {
  baseStyle,
  defaultProps,
};
