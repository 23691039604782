import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {updateUserUtil} from "../utils/updateUser.util";

import { useContext } from "react";
import {GlobalContext} from "../consts/globalContext.const";

export const API = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    timeout: 1000000,
    // headers: APIHeaders,
});


// const logout = async () => {
//     await AsyncStorage.removeItem("token")
//     await AsyncStorage.removeItem("tokenTime")
//     await AsyncStorage.removeItem("refreshToken")
//     await AsyncStorage.removeItem('refreshTokenTime');
//
// }

export const refreshToken = async () => {
    const refreshToken = await AsyncStorage.getItem('refreshToken')
    const {data: res} = await API.post("user/refresh", {token: refreshToken});
    await updateUserUtil(null, null, null, res.accessToken, res.expiresIn, res.refreshToken, res.refreshTokenExpiresIn)
    API.defaults.headers.Authorization = 'Bearer ' + res.accessToken;
}
