/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description This React Native component handles app navigation and authentication. It uses context for global state, responsive design hooks for layout, and interceptors for API request management. It dynamically renders screens based on authentication status, and includes a logout function to clear tokens and navigate to the login screen.
 */

import {breakpoints, theme} from "../styles/theme";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import React, {useContext, useEffect} from "react";

import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {Box, Heading, Row, useBreakpointValue, useMediaQuery,} from "native-base";
import {GlobalContext} from "../consts/globalContext.const";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoginPage from "./LoginPage/LoginPage";
import VerificationPage from "./VerificationPage/VerificationPage";
import {API, refreshToken} from "../queries/api";
import RegisterPage from "./RegisterPage/RegisterPage";
import {menuLinksList, menuList} from "../components/Menu/Menu";
import Toast from "react-native-toast-message";
import {es} from "../assets/i18n/es";
import {createStackNavigator} from "@react-navigation/stack";
import Imagotype from "../assets/images/Imagotype";
import {headerHeight} from "../components/Container/Container";

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
export default function Routes() {
    const minWidth = useBreakpointValue({
        md: breakpoints.md,
    });
    const [isDesktop] = useMediaQuery({
        minWidth,
    });

    const navigation = useNavigation();

    const {isLogged, setIsLogged, loadContent, setLoadContent, setIsLoading} =
        useContext(GlobalContext);

    useEffect(() => {
        const addInterceptors = () => {
            API.interceptors.request.use(
                async (config) => {
                    setIsLoading(true);
                    const token = await AsyncStorage.getItem("token");
                    if (token) {
                        config.headers.Authorization = "Bearer " + token;
                    }
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );

            API.interceptors.response.use(
                function (response) {
                    setIsLoading(false);
                    // Do something with response data
                    return response;
                },
                async function (error) {
                    setIsLoading(false);
                    const originalConfig = error.config;

                    if (error.response) {
                        // Access Token was expired
                        if (error.response.status === 401 && !originalConfig._retry) {
                            originalConfig._retry = true;

                            try {
                                // we need to avoid refresh of refresh
                                if (error.config.url !== "user/refresh") {
                                    await refreshToken();
                                } else {
                                    await logout(navigation, setIsLogged);
                                }
                                return API(originalConfig);

                            } catch (_error) {
                                await logout(navigation, setIsLogged);
                                if (_error.response && _error.response.data) {
                                    return Promise.reject(_error.response.data);
                                }
                                return Promise.reject(_error);
                            }
                        }
                    }

                    Toast.show({
                        type: "error",
                        props: {uuid: "bba1a7d0-6ab2-4a0a-a76e-ebbe05ae6d70"},
                        text1: es[error.response.status], // if app have extra language here need to be selected by condition
                        // specific language
                        text2: Object.values(error.response.data).join("\n"),
                    }); // Do something with response error
                    return Promise.reject(error);
                }
            );
        };

        async function checkRefreshToken() {
            const tokenTime = await AsyncStorage.getItem("tokenTime");
            const refreshTokenTime = await AsyncStorage.getItem("refreshTokenTime");
            if (tokenTime) {
                const tokenTimeMilSeconds = new Date(tokenTime).getTime();
                const refreshTokenMilSeconds = new Date(refreshTokenTime).getTime();
                const actualTimeMilSeconds = new Date().getTime();
                if (actualTimeMilSeconds < tokenTimeMilSeconds) {
                    setLoadContent(true);
                    setIsLogged(true);
                } else if (
                    actualTimeMilSeconds > tokenTimeMilSeconds &&
                    actualTimeMilSeconds < refreshTokenMilSeconds
                ) {
                    // const isLoggedOk = await putRefreshToken(user, setUser, setIsLogged);
                    // Redirect them to the /login page, but save the current location they were
                    // trying to go to when they were redirected. This allows us to send them
                    // along to that page after they login, which is a nicer user experience
                    // than dropping them off on the home page.

                    await refreshToken();
                    setLoadContent(true);
                    setIsLogged(true);

                    // if (!isLoggedOk) {
                    //     await logout(navigation)
                    // }
                } else {
                    setLoadContent(true);
                    await logout(navigation, setIsLogged);
                }
            } else {
                setLoadContent(true);
            }
        }

        addInterceptors();
        checkRefreshToken();


    }, []);

    const {t} = useTranslation();
    if (!loadContent) {
        // We haven't finished checking for the token yet
        return <></>;
    }
    const headerTemplate = (e) =>
        isDesktop ? (
            <Row alignItems={"center"}>
                <Box mr={3}>
                    <Imagotype width={210} height={30}/>
                </Box>
                <Heading ml={10} color={"white"} fontSize={"2xl"}>
                    {e.children}
                </Heading>
            </Row>
        ) : (
            <Row justifyConent={"center"} alignItems={"center"}>
                <Imagotype width={210} height={30}/>
            </Row>
        );

    return (
        <Stack.Navigator
            initialRouteName="policies"
            screenOptions={{
                headerTitle: (e) => headerTemplate(e),
                headerStyle: {
                    height: headerHeight,
                    backgroundColor: "#12162E",
                },
                headerTintColor: "#fff",
                headerTitleStyle: {
                    fontFamily: "RobotoSlab",
                },
                tabBarActiveTintColor: "#12162E",
            }}
            backBehavior={"history"}
            sceneContainerStyle={{backgroundColor: theme.colors.bg["white"]}}
        >
            {loadContent && isLogged ? (
                <>
                    {menuList.map((menuItem) => (
                        <Tab.Screen
                            key={menuItem.to}
                            name={menuItem.to}
                            component={menuItem.component}
                            options={{
                                title: t(menuItem.name),
                                headerBackTitleVisible: false,
                                [menuItem.icon && !menuItem.hideOnMobile
                                    ? "tabBarIcon"
                                    : "tabBarButton"]:
                                    menuItem.icon && !menuItem.hideOnMobile
                                        ? ({color}) => (
                                            <MaterialCommunityIcons
                                                name={menuItem.icon}
                                                color={color}
                                                size={26}
                                            />
                                        )
                                        : () => null,
                            }}
                        />
                    ))}

                </>
            ) : (
                <></>
            )}
            {loadContent && (
                <>

                    <Tab.Screen
                        name="login"
                        component={LoginPage}
                        // component={() => <Stack.Navigator><Stack.Screen name={"pepe"} component={LoginPage}/></Stack.Navigator>}
                        options={{
                            title: t("Login"),
                            tabBarStyle: {display: "none"},
                            headerBackTitleVisible: false,
                            tabBarButton: () => null,
                        }}
                    />
                    <Tab.Screen
                        name="register"
                        component={RegisterPage}
                        options={{
                            title: t("Register"),
                            tabBarStyle: {display: "none"},
                            headerBackTitleVisible: false,
                            tabBarButton: () => null,
                        }}
                    />
                    <Tab.Screen
                        name="verification"
                        component={VerificationPage}
                        options={{
                            tabBarStyle: {display: "none"},
                            title: t("Verification SMS"),
                            headerBackTitleVisible: false,
                            tabBarButton: () => null,
                        }}
                    />
                    {menuLinksList.map((menuItem) => (
                        <Tab.Screen
                            key={menuItem.to}
                            name={menuItem.to}
                            component={menuItem.component}
                            options={{
                                title: t(menuItem.name),
                                headerBackTitleVisible: false,
                                ["tabBarButton"]: () => null,
                            }}
                        />
                    ))}
                </>
            )}
        </Stack.Navigator>
    );
}
export const logout = async (navigation, setIsLogged) => {
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("tokenTime");
    await AsyncStorage.removeItem("refreshToken");
    await AsyncStorage.removeItem("refreshTokenTime");
    if (setIsLogged) {
        setIsLogged(false);
    }
    navigation.navigate("login");
};
