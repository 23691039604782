/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/12/2023
 * @description The `DotList` component renders a list of items with a leading dot character, using recursion to support nested lists. Each item can have its own children, represented as a sublist with a different dot character for visual hierarchy. The component is designed to display hierarchical data structures, such as categories or outlines, in a clear and organized manner.
 */
import {FlatList, Text, View} from "native-base";

export default function DotList({data, dotCode = "\u2022"}) {
    return <FlatList
        ml={6}
        data={data}
        renderItem={({item}) => {
            return (
                <View mb={3}>
                    <Text>{`${dotCode} ${item.value}`}</Text>
                    {item.children && <View ml={6} mt={3}><DotList data={item.children} dotCode={"\u25E6"}/></View>}
                </View>
            );
        }}
    />
}
