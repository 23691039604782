import React from 'react';
import { StyleSheet } from 'react-native';
import { Text, Linking, TouchableOpacity } from 'react-native';

export const sendEmail = (email) => {
    console.log("Email: "+ email);
    if (window.isNativeApp) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'email', email })); 
    } else {
        webSendEmail(email);
    }
};

const webSendEmail = (email) => {
    console.log("Email web");
    EmailLink.sendEmail(email);
};

export const styles = StyleSheet.create({
    link: {
      color: '#007BFF',
      textDecorationLine: 'underline',
    },
});

const EmailLink = ({ email, children }) => (
  <TouchableOpacity onPress={() => sendEmail(email)}>
    {children ? children : <Text style={styles.link}>{email}</Text>}
  </TouchableOpacity>
);

export default EmailLink;
