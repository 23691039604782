export const formErrorValidationsConst = (type, t) => {
    const formErrors = {
        phone: { value: /^[67][0-9]{8}$/i, message: t("Number need to have 9 digits and start with 6 or 7") },
        postalCode: { value: 5, message: t("Need to be 4 digits") },
        sms: { value: 4, message: t("Need to be 4 digits") },
        iban: { value: /^[ES]{2}\d{22}$/
            , message: t("You need to insert a valid Spanish IBAN") }
    }
    return formErrors[type];
}
