import {extendTheme} from "native-base";


export const breakpoints = {
    base: 0,
    sm: 1280,
    md: 1280,
    lg: 1920,
    xl: 1920,
}

export const theme = extendTheme({
    colors: {
        primary: {
            700: "#12162E" //heading
        },
        secondary: {
            50: '#e7f4fd',
            100: '#b8dff9',
            200: '#89caf5',
            300: '#5ab5f2',
            400: '#2ba0ee',
            500: '#1395ec', // 1395ec
            600: '#0d68a5',
            700: '#0a4a76', //heading 27afe8
            800: '#062d47',
            900: '#020f18',
        },
        gray: {
            0: "#FFFFFF",
            50: '#f9f9f9', // border-light
            100: '#d3dbe3', // border-light
            200: '#a3a3a3', //input placeholder
            400: '#6c6d77', // label
            700: '#171717',
            900: '#0a0d10',
        },
        loading: {
            background: "#ffffff80"
        },
        chat:{
            bot:"#ddf1ff",
            human:"#FFFFFF"
        },
        bg: {
            500: '#eff3f8',
            600: '#e4edf9'
        },
        error: {
            200: "#ffc2be",
            500: "#EC665E",
            700: "#DC2626"
        }
    }
    ,
    components: {

        Heading: {
            defaultProps: {
                color: "primary.700",
            },
            baseStyle: {
                fontFamily: "RobotoSlab",
            },
        },
        Text: {
            defaultProps: {
                fontFamily: 'body',
                color: "gray.400",
                fontSize: "xs",
                fontWeight: 300
            },
        },
        Input: {
            defaultProps: {
                size: "lg",
                fontSize: 12,
                fontFamily: "Roboto-Light",
                height: "48px",
                backgroundColor: "white",

            },
            baseStyle: {
                borderRadius: '6',
            }
        },
        Checkbox: {
            defaultProps: {
                _checked: {
                    backgroundColor: "#1395ec",
                    borderColor: "#1395ec",
                },
            },

        },
        Button: {
            defaultProps: {
                size: "lg",
                backgroundColor: "#1395ec",
                zIndex: -1,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 16
            },
            baseStyle: {
                borderRadius: '6',
                color: "Roboto-Bold", // not working
            },
            modifiers: {
                outline: {
                    backgroundColor: "#FFFFFF",
                    borderColor: "#1395ec",
                    color: "#1395ec",
                    borderWidth: 1,
                    fontSize: 12,
                    padding: 6,
                },
                cancel: {
                    backgroundColor: "transparent",
                    borderColor: "#6c6d77",
                    color: "#6c6d77",
                    borderWidth: 1,
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 6,
                    paddingBottom: 6,

                }
            }
        },
        Link: {
            defaultProps: {
                cursor: "pointer"
            }
        },

    },

    fontConfig: {
        Roboto: {
            300: {
                normal: "Roboto-Light",
            },
            500: {
                normal: "Roboto-Medium",
            },
            700: {
                normal: "Roboto-Bold",
            },
        },
        RobotoSlab: {
            400: {
                normal: "RobotoSlab",
            }, 500: {
                normal: "RobotoSlab",
            }, 600: {
                normal: "RobotoSlab",
            }, 700: {
                normal: "RobotoSlab",
            }
        },
    },


    // Make sure values below matches any of the keys in `fontConfig`
    fonts: {
        heading: "RobotoSlab",
        body: "Roboto",
        mono: "Roboto",
    },
    breakpoints
});
