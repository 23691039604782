/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description The `ChatBotPage` component initializes and displays a chatbot interface tailored for either creating a proposal or filing a claim, based on parameters passed via navigation. It fetches field definitions dynamically from an API, based on the policy type and whether the operation concerns a risk or a claim. This process includes transforming boolean fields into selector options for user-friendly chatbot interactions. The component:
 *
 * 1. Retrieves necessary identifiers (for policy type, proposal, or claim) from local storage.
 * 2. Fetches the required field list from an API, adapting the data for the chatbot's needs, including setting default values for boolean fields.
 * 3. Displays the `ChatBot` component, passing the processed field list and default values.
 * 4. Handles submission, calling different API endpoints based on whether the user is creating a proposal or filing a claim, then navigates the user to the appropriate page upon completion.
 *
 * This setup allows for a dynamic and interactive user experience, where form fields are adapted to the chat interface, making data submission intuitive and engaging.
 */
import React, {useEffect, useState} from "react";
import Container from "../../components/Container/Container";
import {API} from "../../queries/api";
import ChatBot from "../../components/ChatBot/ChatBot";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useNavigation, useRoute} from "@react-navigation/native";
import {transformDynamicData} from "../../utils/transformDynamicDataUtil";
import {goToUtil} from "../../utils/goTo.util";

let defaultValues;
export default function ChatBotPage() {
    const [fieldsList, setFieldsList] = useState();
    const {params} = useRoute();
    const navigation = useNavigation();

    useEffect(() => {
        const getPolicyTypesFields = async () => {
            const actualPolicyTypeId = await AsyncStorage.getItem("actualPolicyTypeId")
            const {data} = await API(`policy/policy-type/field/list/${actualPolicyTypeId}/${params.type === "proposal" ? "RISK" : "CLAIM"}?extraFields=options,relations,relations.rules`)
            const [defaults, values] = createDefaultValuesAndTransformBooleans(data)
            defaultValues = defaults;
            setFieldsList(values);
        }

        if (params) {
            getPolicyTypesFields();
        }
        // I don't know why on mobile phones params is getting first time undefined
    }, [params])


    const doSubmit = async (formData) => {
        if (params.type === "proposal") {
            const actualPolicyTypeId = await AsyncStorage.getItem("actualPolicyTypeId")
            const actualProposalId = await AsyncStorage.getItem("actualProposalId")
            await API.put(`policy/proposal/risk/update/${actualPolicyTypeId}/${actualProposalId}`, transformDynamicData(formData))
            goToUtil('offers', navigation)
        } else if (params.type === "claim") {
            const actualClaimId = await AsyncStorage.getItem("actualClaimId")
            await API.put(`policy/claim/risk/update/${actualClaimId}`, transformDynamicData(formData))
            goToUtil('policies', navigation)
        }
    }


    return <Container>
        {fieldsList && <ChatBot fieldsList={fieldsList} setFieldsList={setFieldsList} defaultValues={defaultValues}
                                onSubmit={doSubmit}/>}
    </Container>

}

// we need to create defaultValue for boolean type to insert like false
const createDefaultValuesAndTransformBooleans = (fieldsList) => {
    let defaultValues = {}
    const fieldsListCopy = [];
    for (let field of fieldsList) {
        if (field && field.type === "boolean") {
            defaultValues[field.key] = "false"
            fieldsListCopy.push({
                ...field,
                type: "selector",
                options: [{value: "Yes", key: "true"}, {value: "No", key: "false"}]
            })
        } else {
            fieldsListCopy.push(field)
        }
    }
    return [defaultValues, fieldsListCopy]
}
