/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 27/10/2023
 * @description The `MenuMobile` component displays a mobile-friendly bottom navigation menu derived from a global menu list. Each menu item is conditionally rendered based on its visibility on mobile devices and includes an icon, label, and optional notification bubble for specific items (like offers). It utilizes the `useNavigation` and `useTranslation` hooks for navigation and internationalization, respectively. The component also checks the current route to highlight the active menu item. A notification bubble displays the count of user pending offers, adding dynamic content to the menu. The layout is designed to be fixed at the bottom of the screen, with styling that ensures it's visually separated from the main content area.
 */
import {Box, Column, Icon, Pressable, Text} from "native-base";
import {useNavigation, useRoute} from "@react-navigation/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {goToUtil} from "../../utils/goTo.util";
import {StyleSheet} from "react-native";
import {menuList} from "../Menu/Menu";
import {theme} from "../../styles/theme";
import {GlobalContext} from "../../consts/globalContext.const";

export default function MenuMobile() {
    const navigation = useNavigation();
    const {t} = useTranslation()
    const route = useRoute();
    const {offersBody} = useContext(GlobalContext)


    return <Box style={styles.container}>
        {menuList.map((menuItem) => menuItem.icon && !menuItem.hideOnMobile ?
            <Pressable onPress={() => goToUtil(menuItem.to, navigation)} key={menuItem.to}>
                <Column style={styles.item}  borderColor={"gray.100"}>
                    <Icon style={{marginRight: 0}} color={route.name === menuItem.to ? "secondary.500" : "gray.400"} size={6}
                          as={MaterialCommunityIcons} name={menuItem.icon} mr={3}/>
                    <Text color={route.name === menuItem.to ? "secondary.500" : "gray.400"}
                          fontSize={12}>{t(menuItem.name)}</Text>
                    {menuItem.to === "offers" && offersBody.countUserPendingOffers > 0 && <Box style={styles.bubble}><Text
                        color={theme.colors.gray["0"]}>{offersBody.countUserPendingOffers}</Text></Box>}

                </Column>
            </Pressable> : <React.Fragment key={menuItem.to}/>
        )}
    </Box>


}


const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 60,
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: theme.colors.gray["0"],
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: .1,
        shadowRadius: 3,
        elevation: 5,
    },
    item: {
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    bubble: {
        backgroundColor: theme.colors.secondary["500"],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        width: 35,
        height: 35,
        borderRadius: 50,
        position: "absolute",
        right: 0,
        top: -12.5
    }

});
