// import { useWindowDimensions } from 'react-native';
// import { isResponsiveAnyProp } from '../theme/tools';

//  @ts-ignore eslint-disable-next-line
export function useHasResponsiveProps(props: any) {
  if (props) {
    //
  }
  return false;
  //   const windowDimensions = useWindowDimensions();

  //   if (!windowDimensions.width || !windowDimensions.height) {
  //     const responsivePropsExists = isResponsiveAnyProp(props);
  //     if (responsivePropsExists) {
  //       return true;
  //     }
  //   }
  //   return false;
}
