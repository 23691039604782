/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 03/10/2023
 * @description This React component, `Advisor`, presents an advisor profile, including image, name, contact information, and optional descriptive information such as about, education, and experience. It supports toggling the visibility of the descriptive information based on user interaction or an initial prop (`isShowingDescriptionByDefault`). The component uses translation for text elements and includes contact information (telephone and email) with actionable links. The `InfoBox` sub-component is used to conditionally render content within a scrollable view or as static content. The presentation and layout are managed with a column layout and custom styles, emphasizing readability and user interaction.
 */
import {Box, Column, Image, Link, Row, ScrollView, Text} from "native-base";
import {StyleSheet} from "react-native";
import {useTranslation} from "react-i18next";
import {theme} from "../../styles/theme";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {useState} from "react";
import AdvisorImage from "../../assets/images/Advisor";
import TelephoneLink from "../TelephoneLink/TelephoneLink ";
import EmailLink from "../EmailLink/EmailLink";

export default function Advisor({data, isShowingDescriptionByDefault}) {
    const {t} = useTranslation();
    const [isShowingDescription, setIsShowingDescription] = useState(false);
    const InfoBox = ({children, isScrollable}) => <Box style={styles.infoBox}>
        {isScrollable && <ScrollView height={350} width="100%">{children}</ScrollView>}
        {!isScrollable && children}
    </Box>

    return <Column alignItems={"center"}>
        {data.image && <Image style={styles.image} resizeMode={"contain"} source={{
            uri: process.env.REACT_APP_IMAGE_URL + data.image
        }} alt={t("Asesor image")}/>}
        {!data.image && <AdvisorImage style={styles.image} width={100} height={100}/>}
        <Text fontSize={20} fontWeight={"600"}>{data.firstName} {data.lastName}</Text>
        <Text mb="6" fontSize={14}>{t("Your advisor")}</Text>
        {!isShowingDescriptionByDefault && (data.about || data.education || data.experience) &&
            <Text mb="6" fontSize={"14"} fontWeight={"600"}
                  onPress={() => setIsShowingDescription(!isShowingDescription)}>{t("Know more" +
                " about" +
                " me")}</Text>}


        <InfoBox isScrollable={false}>
            {data.telephone && <Row alignItems={"center"} marginBottom={6} space={3}>
                <MaterialCommunityIcons name="phone" color={theme.colors.secondary["700"]} size={20}/>
                <TelephoneLink telephone={data.telephone.toString()} ext={data.telephone.toString().charAt(0) === "9" ? "" : "+34"}>
                    <Text fontSize={"12"} color={theme.colors.secondary["700"]} textDecoration={"none"}>
                        {data.telephone}
                    </Text>
                </TelephoneLink>
            </Row>}

            {data.email && <Row alignItems={"center"} space={3}>
                <MaterialCommunityIcons name="email" color={theme.colors.secondary["700"]} size={20}/>
                <EmailLink email={data.email}>
                    <Text fontSize={"12"} color={theme.colors.secondary["700"]} textDecoration={"none"}>{data.email}</Text>
                </EmailLink>
            </Row>}
        </InfoBox>

        {(isShowingDescriptionByDefault || isShowingDescription) && (data.about || data.education || data.experience) &&
            <Box width="100%" mt={6}>
                <InfoBox isScrollable={!isShowingDescriptionByDefault}>
                    {data.about && <>
                        <Text fontWeight={500}>
                            {t("About me")}:
                        </Text>
                        <Text>
                            {data.about}
                        </Text>
                    </>}

                    {data.education && <>
                        <Text fontWeight={500}>
                            {t("My education")}:
                        </Text>
                        <Text>
                            {data.education}
                        </Text>
                    </>}


                    {data.experience && <>
                        <Text fontWeight={500}>
                            {t("My experience")}:
                        </Text>
                        <Text>
                            {data.experience}
                        </Text>
                    </>}

                </InfoBox></Box>}


    </Column>
}

const styles = StyleSheet.create({
    image: {
        width: 100,
        height: 100,
        borderRadius: 100
    },
    infoBox: {
        backgroundColor: theme.colors.bg["600"],
        padding: 16,
        width: "100%",
    }
});
