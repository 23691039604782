import AsyncStorage from "@react-native-async-storage/async-storage";

export const updateUserUtil = async (user, setUser, setIsLogged, token, expiration, refreshToken, refreshTokenExpiration) => {
    if (user) {
        await AsyncStorage.setItem("user", JSON.stringify(user));
        if (setUser) {
            setUser(user);
        }
    }

    if (token) {
        await AsyncStorage.setItem("token", token);
        const t = new Date();
        t.setSeconds(t.getSeconds() + expiration); // adding seconds
        await AsyncStorage.setItem("tokenTime", t.toString());
        if (setIsLogged) {
            setIsLogged(!!token);
        }
    }

    if (refreshToken) {
        const t = new Date();
        t.setSeconds(t.getSeconds() + refreshTokenExpiration); // adding seconds
        await AsyncStorage.setItem("refreshTokenTime", t.toString());
        await AsyncStorage.setItem("refreshToken", refreshToken);
    }
};
