/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/10/2023
 * @description This React `Dialog` component displays a modal with customizable content, header, and confirm/cancel actions. It uses `isOpen` and `setIsOpen` for visibility control, and `useTranslation` for localization. Callbacks `onConfirm` and `onCancel` are executed upon user interaction. It's built with an `AlertDialog` structure, including a close button, content area for children, and a footer with confirm and cancel buttons.
 */
import {AlertDialog, Button, Text} from "native-base";
import {useTranslation} from "react-i18next";
import React from "react";
import {theme} from "../../styles/theme";

export default function Dialog({isOpen, setIsOpen, children, header, onConfirm, onCancel}) {
    const {t} = useTranslation()
    const cancelRef = React.useRef(null);
    const doClose = () => {
        setIsOpen(false);
        onCancel()
    }

    const doConfirm = () => {
        setIsOpen(false);
        onConfirm()
    }


    return <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={doClose}>
        <AlertDialog.Content>
            <AlertDialog.CloseButton/>
            <AlertDialog.Header>{header}</AlertDialog.Header>
            <AlertDialog.Body>
                {children}
            </AlertDialog.Body>
            <AlertDialog.Footer>
                <Button.Group space={2}>
                    <Button style={theme.components.Button.modifiers.cancel} onPress={doClose} ref={cancelRef}>
                        <Text
                            color={theme.colors.gray["400"]}>{t("Cancel")}</Text>
                    </Button>
                    <Button onPress={doConfirm}>
                        {t("Confirm")}
                    </Button>
                </Button.Group>
            </AlertDialog.Footer>
        </AlertDialog.Content>
    </AlertDialog>
}
