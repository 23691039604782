/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/09/2023
 * @description The `VerificationPage` component is responsible for verifying a user's identity through SMS. It allows users to input the code received via SMS, resend the code, and proceed with login. Upon successful verification, user data is updated, and the user is navigated to the "policies" page. The component utilizes various hooks such as `useForm`, `useTranslation`, `useNavigation`, and `useRoute`, along with AsyncStorage for data storage. It imports components like `FormInput` and `SendAgain`, and API functions for communication.
 */
import {Box, Button, Center, Column, Heading, Link, Stack, Text, VStack} from "native-base";
import FormInput from "../../components/FormInput/FormInput";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import {useNavigation, useRoute} from "@react-navigation/native";
import {updateUserUtil} from "../../utils/updateUser.util";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {GlobalContext} from "../../consts/globalContext.const";
import {formErrorValidationsConst} from "../../consts/formErrorValidations.const";
import SendAgain from "../../components/SendAgain/SendAgain";
import {goToUtil} from "../../utils/goTo.util";

export default function VerificationPage() {
    const {setUser, setIsLogged, setLoadContent, setAdvisor} = useContext(GlobalContext)
    const [telephone, setTelephone] = useState("");

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            code: '',
        }
    });

    const navigation = useNavigation();

    const {t} = useTranslation();

    const sendAgain = async () => {
        await API.post("user/login", {telephone: Number(telephone)});
    }

    const onSubmit = async ({code}) => {
        try {
            const msgId = await AsyncStorage.getItem("msgId")
            const {data: resData} = await API.post("user/validate", {
                code: code,
                msgId,
                telephone: Number(telephone)
            });
            await updateUserUtil(null, null, setIsLogged, resData.accessToken, resData.expiresIn, resData.refreshToken, resData.refreshTokenExpiresIn)

            const userId = await AsyncStorage.getItem("userId");
            const {data: resUserData} = await API.get("user/user/view/" + userId + "?extraFields=advisor");


            const {data: advisor} = await API("user-bo/user/view/" + resUserData.advisorId);

            setAdvisor(advisor)
            await AsyncStorage.setItem("advisor", JSON.stringify(advisor));

            setLoadContent(true);
            await updateUserUtil(resUserData, setUser)


            goToUtil('policies', navigation)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
            const initLocalData = async () => {
                setTelephone(await AsyncStorage.getItem('phone'))
            }
            initLocalData()
        }
    )


    return <VStack h={"100%"} justifyContent="center" alignItems={"center"}>
        <Box p={12} backgroundColor="white" borderRadius="6" w={["90%", "90%", "600px"]}> <Box p={[0, 12]}>
            <Heading>{t("Verification SMS")}</Heading>

            <Stack direction={["column", "row"]} mt="10" space="3" justifyContent="center">
                <Box w={"100%"} alignItems="center">
                    <FormInput control={control} errors={errors} placeholder="SMS code" name={"code"}
                               onEnterPress={handleSubmit(onSubmit)}
                               type="string"
                               rules={{
                                   required: true,
                                   minLength: formErrorValidationsConst("sms", t),
                                   maxLength: formErrorValidationsConst("sms", t)
                               }}/>
                </Box>
            </Stack>

            <Column space={3}>
                <Text mt="5">
                    {t("Please, insert code we sent you by SMS to")}
                </Text>

                <Center>
                    <Text mt="5">
                        {t("o")}
                    </Text>
                </Center>

                <Center mt="5">
                    <SendAgain onPress={sendAgain}>{t("Send again to")} +{telephone}</SendAgain>
                </Center>
                <Center>
                    <Box>
                        <Link mt="5" onPress={() => navigation.navigate('login')}>
                            +{telephone} {t("is not my phone number")}
                        </Link>
                    </Box>
                </Center>

                <Button mt={"8"} w="100%" onPress={handleSubmit(onSubmit)}>{t("Log in")}</Button>

            </Column>

        </Box>
        </Box>
    </VStack>
}
