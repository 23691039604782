/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/09/2023
 * @description This React component represents a "Policies Detail" page. It fetches details of a specific policy based on the provided ID and displays them using a Policy component. It utilizes hooks like useState, useEffect, useRoute, and useNavigation for state management, lifecycle methods, routing, and navigation respectively. The component fetches policy details including policy type and company information. It provides options to edit or delete the policy. When the user clicks on the edit button, it navigates to the "Policies Edit" page, passing the policy ID as a parameter. When the user clicks on the delete button, it deletes the policy and navigates back to the "Policies" page. The UI elements are structured within a Container component, with conditional rendering based on the availability of policy details.
 */
import Container from "../../components/Container/Container";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {useNavigation, useRoute} from "@react-navigation/native";
import Policy from "../../components/Policy/Policy";
import {goToUtil} from "../../utils/goTo.util";


export default function PoliciesDetailPage() {
    const [policy, setPolicy] = useState()
    const {params: {id}} = useRoute();
    const navigation = useNavigation();


    const doEdit = async (data) => {
        goToUtil("policies-edit", navigation, {id: data.id})
    }

    const doDelete = async (data) => {
        try {
            await API.delete(`policy/policy/delete/${data.id}`);
            goToUtil('policies', navigation)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        async function getPolicy() {
            try {
                const {data} = await API(`policy/policy/view/${id}?extraFields=policyType,company`);
                setPolicy(data)
            } catch (e) {
                console.log(e)
            }
        }

        getPolicy();
    }, [])

    return <Container>
        {policy && <Policy data={policy} onDelete={doDelete} onEdit={doEdit}/>}
    </Container>
}
