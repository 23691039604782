const baseStyle = {};
const defaultProps = {};
// sizes in stack means the height or width of the spacer elements that get's added between the children, in other
// words it maps to space prop that user passes.
const sizes = {
  'gutter': 0,
  '2xs': 1,
  'xs': 2,
  'sm': 3,
  'md': 4,
  'lg': 6,
  'xl': 7,
  '2xl': 8,
};
export default {
  baseStyle,
  defaultProps,
  sizes,
};
