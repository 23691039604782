/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 08/09/2023
 * @description The `AddPolicyPage` component allows users to add insurance policies to their profile. It manages a list of selected insurances and associated policies, offering functionalities to add, edit, and remove policies. When a user selects an insurance, a form (`AddPolicyForm`) is presented to enter or edit policy details. Users can add multiple policies for each insurance company. The component checks if all selected insurances have at least one policy before saving; if not, it prompts the user with a confirmation dialog (`Dialog`) to proceed with saving only those insurances with policies or cancel the action. The saving process involves calling an API to create policies in the backend. This component integrates with the global context to access user information and uses navigation for page transitions, providing a seamless experience for managing insurance policies within the app.
 */
import {useContext, useEffect, useState} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AddPolicyGallery from "../../components/AddPolicyGallery/AddPolicyGallery";
import Container from "../../components/Container/Container";
import AddPolicyForm from "../../components/AddPolicyForm/AddPolicyForm";
import {useTranslation} from "react-i18next";
import {Box, Button, Row, Text} from "native-base";
import {API} from "../../queries/api";
import {GlobalContext} from "../../consts/globalContext.const";
import {transformFileLocationUtil} from "../../utils/transformFileLocation.util";
import {useNavigation} from "@react-navigation/native";
import Dialog from "../../components/Dialog/Dialog";
import {goToUtil} from "../../utils/goTo.util";

export default function AddPolicyPage() {
    const {t} = useTranslation()
    const {user} = useContext(GlobalContext)
    const [selectedInsurances, setSelectedInsurances] = useState([]);
    const [isShowingDialog, setIsShowingDialog] = useState(false);
    const navigation = useNavigation();

    const [actualInsurance, setActualInsurance] = useState();
    const [actualPolicy, setActualPolicy] = useState();
    const [actualPolicyIndex, setActualPolicyIndex] = useState();

    const showPolicyForm = (insurance) => {
        setActualInsurance(insurance)
    }

    const savePolicyToInsurance = (policy) => {
        const copySelectedInsurances = [...selectedInsurances];
        const insuranceIndex = copySelectedInsurances.findIndex((insurance => insurance.id === actualInsurance.id))

        if (actualPolicyIndex >= 0) {
            copySelectedInsurances[insuranceIndex].policies[actualPolicyIndex] = policy
        } else {
            copySelectedInsurances[insuranceIndex] = {
                ...copySelectedInsurances[insuranceIndex],
                policies: copySelectedInsurances[insuranceIndex].policies ? [...copySelectedInsurances[insuranceIndex].policies, policy] : [policy]
            }
        }

        setSelectedInsurances(copySelectedInsurances)
        setActualInsurance()
        setActualPolicy()
        setActualPolicyIndex()

    }

    const doConfirmSave = async () => {
        const isNeededConfirm = checkInsurancesHavePolicies(selectedInsurances)
        if (isNeededConfirm) {
            setIsShowingDialog(true)
        } else {
            await doSave()
        }

    }

    const doSave = async () => {
        const promises = []
        for (const selectedInsurance of selectedInsurances) {
            if (selectedInsurance.policies) {
                for (const policy of selectedInsurance.policies) {

                    promises.push(await API.post("policy/policy/create", transformFileLocationUtil({
                        ...policy,
                        companyId: selectedInsurance.id,
                        annualPayment: parseFloat(policy.annualPayment),
                        referenceType: "RISK", // this is by default on app
                        paymentMethod: "COMPANY", // PM told me to insert by default
                        policyTypeId: Number(policy.policyTypeId),
                        userId: user.id
                    }, "fileLocation")))
                }
            }

            await Promise.all(promises)

            goToUtil("policies", navigation)
        }
    }

    const checkInsurancesHavePolicies = (insurances) => {
        const insurancesWithPolicies = insurances.filter(insurance => insurance.policies);
        return insurances.length !== insurancesWithPolicies.length
    }

    const removePolicy = (insuranceIndex, policyIndex) => {
        const copySelectedPolicies = [...selectedInsurances];
        copySelectedPolicies[insuranceIndex].policies.splice(policyIndex, 1);
        setSelectedInsurances(copySelectedPolicies)
    }

    const editPolicy = (insurance, policy, policyIndex) => {
        setActualInsurance(insurance)
        setActualPolicy(policy)
        setActualPolicyIndex(policyIndex)
    }

    useEffect(() => {
        const getLocalStorageData = async () => {
            setSelectedInsurances(JSON.parse(await AsyncStorage.getItem("selectedInsurances")));
        }
        getLocalStorageData();
    }, [])

    return <Container>
        {actualInsurance &&
            <AddPolicyForm data={actualPolicy} onSubmit={savePolicyToInsurance} onCancel={() => {
                setActualInsurance();
                setActualPolicy()
                setActualPolicyIndex()
            }}/>}
        {!actualInsurance && <>
            <AddPolicyGallery data={selectedInsurances} onPress={showPolicyForm} onSubRemove={removePolicy}
                              onSubEdit={editPolicy}/>

            <Row mt={"3"} justifyContent={"center"}>
                <Text mt={"6"}
                      textAlign={"center"}>{t("You can upload multiple policies from one company clicking on \"Add policy\"")}</Text>
            </Row>

            <Box mt={"3"}>
                <Button onPress={doConfirmSave}>{t("Save")}</Button>
            </Box>
        </>}

        <Dialog isOpen={isShowingDialog} setIsOpen={setIsShowingDialog} header={t("Are you sure?")} onConfirm={doSave}>
            {t("You have insurances without policies. You will not save any policy from those companies")}
        </Dialog>
    </Container>
}
