/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/10/2023
 * @description The `IconBtn` component is a customizable button that includes an icon and text. It allows for the selection of icons from either the Material Icons or Material Community Icons libraries, indicated by the `iconLibrary` prop. The icon name is passed through the `iconName` prop, and the button's label through the `text` prop. This component is designed to be versatile, fitting various UI needs where an icon button might be used, such as in toolbars, forms, or as action buttons. The styling is predefined to ensure consistency and visual appeal, with the ability to customize the icon's color, size, and the button's background. The component uses a `Pressable` wrapper for handling click or touch events, making it suitable for both web and mobile applications.
 */
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { theme } from "../../styles/theme";
import { Pressable, Row, Text } from "native-base";
import { StyleSheet } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

export default function IconBtn ({ onPress, text, iconName, iconLibrary = "MATERIAL" }) {
    return <Pressable onPress={onPress}><Row style={styles.container} alignItems={"center"} space={1}>

        {iconLibrary === "MATERIAL" &&
        <MaterialIcons style={styles.icon} name={iconName} color={theme.colors.gray["100"]} fontWeight="300"
                       size={24}/>}

        {iconLibrary === "COMMUNITY" &&
        <MaterialCommunityIcons style={styles.icon} name={iconName} color={theme.colors.gray["100"]} fontWeight="300"
                                size={24}/>}
        <Text ml={1}>{text}</Text>
    </Row></Pressable>
}
const styles = StyleSheet.create({
    container: {
        backgroundColor: theme.colors.gray["0"],
        padding: 6,
        cursor: "pointer"
    },
    icon: {
        borderRadius: 20,
        borderWidth: 1,
        backgroundColor: theme.colors.gray["0"],
        borderColor: theme.colors.secondary["500"],
        width: 40,
        height: 40,
        padding: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // shadowOffset: { width: 0, height: 0 },
        // shadowOpacity: .1,
        // shadowRadius: 3,
        elevation: 5,
        color: theme.colors.secondary["500"]

    }

});
