const borderWidths = {
  '0': 0,
  '1': '1px',
  '2': '2px',
  '4': '4px',
  '8': '8px',
};

export type IBorderWidth = keyof typeof borderWidths;

export default borderWidths;
