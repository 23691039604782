/**
 * @description A React component for an insurance policy addition form, utilizing useForm for form management and state handling. It fetches policy types from an API on mount and allows the user to input policy details, including the insured object, policy type, payment details, and a file upload. The form supports validation and uses translations for placeholders and labels. Upon submission or cancellation, it triggers respective passed-in callback functions.
 */
import { Box, Button, Pressable, Row, Stack, Text } from "native-base";
import FormInput from "../FormInput/FormInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { paymentFrequencyConst } from "../../consts/paymentFrequency.const";
import { theme } from "../../styles/theme";
import { useEffect, useState } from "react";
import { API } from "../../queries/api";

export default function AddPolicyForm ({ data, onSubmit, onCancel }) {
    const {
        control, formState: { errors, dirtyFields },
        handleSubmit
    } = useForm({ defaultValues: data });
    const { t } = useTranslation();
    const [policyTypes, setPolicyTypes] = useState([]);
    const [hasImages, setHasImages] = useState(true);
    const [fileLocationResult, setFileLocationResult] = useState();

    useEffect(() => {
        async function getPolicyTypes () {
            const { data } = await API("policy/policy-type/list");
            setPolicyTypes(data.map(item => ({ label: item.name, value: item.id.toString() })));
        }

        getPolicyTypes();
    }, []);

    // useEffect(() => {
    //     debugger
    // }, [setFileLocationResult]);

    let isImagePickerVisible = true;
    // let isFilePickerVisible = true;

    const filePickerOnChange = function(control) {
        isImagePickerVisible = !(control._formValues["fileLocation"] && control._formValues["fileLocation"].length > 0) || hasImages;
    }.bind(this);

    return (
        <Box>
            <FormInput control={control} errors={errors} placeholder={t("Who or what you have insured?")}
                       name={"referenceValue"}
                       rules={{ required: true }}/>

            <Box w={"100%"} mt={"3"}>
                <FormInput control={control} errors={errors} placeholder={t("Insurance type")} name={"policyTypeId"}
                           type={"select"}
                           options={policyTypes}
                           rules={{ required: true }}/>
            </Box>

            <Stack direction={["column", "row"]} mt={"3"} alignItems={"center"}>
                <Box w={["100%", "48%"]}>
                    <FormInput control={control} errors={errors} placeholder={t("Price")} name={"annualPayment"}
                               type={"decimal"}
                               rules={{ required: true }}/>

                </Box>

                <Row mt={[3, 0]} mb={[3, 0]} w={["100%", "4%"]} justifyContent="center" textAlign={"center"}>
                    <Text>{t("to")}</Text>
                </Row>

                <Box w={["100%", "48%"]} alignItems={"center"}>
                    <FormInput control={control} errors={errors} placeholder={t("Frequency")} name={"frequency"}
                               type={"select"}
                               options={paymentFrequencyConst}
                               rules={{ required: true }}/>
                </Box>
            </Stack>

            <Box mt={"3"}>
                <FormInput control={control} errors={errors} placeholder={t("Renovation date")}
                           name={"expirationDate"}
                           type={"calendar"}
                           rules={{ required: true }}/>
            </Box>

            {!hasImages && (
                <Box mt={"3"}>
                    <FormInput control={control} errors={errors} name={"fileLocation"}
                               type={"file"} acceptedFileTypes={["application/pdf"]}
                               value={setFileLocationResult}
                               rules={{ required: true }} 
                               onChange={filePickerOnChange(control)}/>
                </Box>
            )}

            {isImagePickerVisible && (<Box mt={"3"}>
                <FormInput control={control} errors={errors} name={"fileLocation"}
                        type={"image-picker"} acceptedFileTypes={["application/pdf"]}
                        rules={{ required: true }} 
                        hasImages={setHasImages} onChange={filePickerOnChange(control)}/>
                </Box>
            )}

            <Box mt={"6"}>
                <Button onPress={handleSubmit((dataF) => onSubmit(dataF, dirtyFields))}>{t("Save")}</Button>
            </Box>

            <Row mt={"3"} justifyContent={"center"}>
                <Pressable onPress={onCancel}>
                    <Text fontSize={16} color={theme.colors.error["500"]}>{t("Cancel")}</Text>
                </Pressable>
            </Row>
        </Box>
    );
}