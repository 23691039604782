/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/07/2023
 * @description This React component represents a "My Account" page. It uses hooks like useNavigation and useContext to handle navigation and manage global state. The component lists various links related to the user's account, including "Profile" and other menu links. The "Logout" link triggers a logout function when pressed, which navigates to the logout page and updates the logged-in state. The layout is structured within a Container component, and the links are rendered using a ListLink component, passing the linksList data as props.
 */
import ListLink from "../../components/ListLink/ListLink";
import {useNavigation} from "@react-navigation/native";
import Container from "../../components/Container/Container";
import {logout} from "../Routes";
import {useContext} from "react";
import {GlobalContext} from "../../consts/globalContext.const";
import {menuLinksList} from "../../components/Menu/Menu";


export default function MyAccountPage() {
    const navigation = useNavigation();
    const {setIsLogged} = useContext(GlobalContext)

    const linksList = [
        {name: "Profile", icon: "pencil", to: "profile"},
        ...menuLinksList,
        {name: "Logout", icon: "logout-variant", onPress: () => logout(navigation, setIsLogged)},
    ]

    return <Container>
        <ListLink data={linksList}/>
    </Container>

}
