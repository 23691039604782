import {Box, Button, HStack, Link, Pressable, Row, Text} from "native-base";
import FormInput from "../../../../components/FormInput/FormInput";
import AddressForm from "../../../../components/AddressForm/AddressForm";
import {goToUtil} from "../../../../utils/goTo.util";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {API} from "../../../../queries/api";
import {useForm} from "react-hook-form";
import {useNavigation} from "@react-navigation/native";
import {formErrorValidationsConst} from "../../../../consts/formErrorValidations.const";

export default function OffersHireConfirm({proposalId,onGoBack, onSubmit, userId}) {
    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm();
    const [addresses, setAddresses] = useState([])
    const navigation = useNavigation();

    const {t} = useTranslation()

    const {
        control: controlAddress,
        watch: watchAddress,
        errors: errorsAddress
    } = useForm({defaultValues: {isNew: true}});



    useEffect(() => {
        async function getBudgets() {
            try {
                const {data} = await API(`policy/budget/list/${proposalId}?limit=100&offset=0&filter[isInOffer]=1`);
            } catch (e) {
                console.log(e)
            }
        }

        const getAddresses = async (userId) => {
            const {data: {data}} = await API("user/address/list?filter[userId]=" + userId);
            setAddresses(data.map(address => ({label: address.street, value: address.id.toString()})))
        }

        getAddresses(userId)
        getBudgets();
    }, [])
    return <Box>


        <Box mt={"3"}>
            <FormInput control={control} errors={errors} name={"iban"} label={"Bank account (IBAN)"} rules={{required: true, pattern: formErrorValidationsConst("iban", t)}}/>
        </Box>

        <Box w={"100%"} mt={"3"} mb={3}>
            <FormInput control={controlAddress} errors={errorsAddress} name={"isNew"}
                       type={"radio"}
                       options={[{
                           label: t("Create new address"),
                           value: true
                       }, {
                           label: t("Use an actual address"),
                           value: false
                       }]}
            />
        </Box>

        {watchAddress("isNew") && <Box><AddressForm
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
        /></Box>}

        {!watchAddress("isNew") && <FormInput control={control} errors={errors} name={"addressId"}
                                              type={"select"}
                                              options={addresses}
        />}


        <Box w={"100%"} mt={6}>
            <HStack mt="5" space={3}>

                <Box w={4}>
                    <FormInput control={control} errors={errors} name={"isTermsAccepted"}
                               type={"checkbox"}
                               rules={{required: true}}/>
                </Box>
                <Text w="100%">
                    {t("I accept the")} <Link
                    onPress={() => navigation.navigate('legal-advise')}>{t("Legal Advise")}</Link> {t("and the")} <Link
                    onPress={() => navigation.navigate('privacy-policy-and-cookies')}>{t("Privacy Policy and Cookies")}</Link>
                </Text>
            </HStack>

        </Box>


        <Button mt={6} onPress={handleSubmit(onSubmit)}>
            {t("Hire")}
        </Button>
        <Row mt={"3"} justifyContent={"center"}>
            <Pressable onPress={onGoBack}>
                <Text fontSize={16}>{t("Go back")}</Text>
            </Pressable>
        </Row>

    </Box>
}
