/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/12/2023
 * @description The `AboutPage` component provides comprehensive information about Prudenzia, showcasing its identity, advantages, regulatory authorization, independence from insurance companies, contact information, and deregistration process. It highlights Prudenzia's unique position as the first "phygital" brokerage, offering digital and physical services for managing insurance policies. The component elaborates on the benefits of using Prudenzia, such as mobile management of insurances, personalized advisory, access to a wide range of insurance companies, innovative products, and yearly updates on contracts. It assures the company's independence from insurers, ensuring unbiased service to clients. Contact details are provided for further engagement, and instructions are given for users wishing to deregister from the app. The `DotList` component is used to neatly present the advantages, enhancing readability and engagement.
 */
import Container from "../../components/Container/Container";
import {Box, Heading, Link, Text} from "native-base";
import DotList from "../../components/ListDot/ListDot";
import TelephoneLink from "../../components/TelephoneLink/TelephoneLink ";
import { StyleSheet } from 'react-native';
import EmailLink from "../../components/EmailLink/EmailLink";

export const styles = StyleSheet.create({
    link: {
      color: '#007BFF',
      textDecorationLine: 'underline',
    },
});

const firstDotList = [
    {
    value: "EL 100% de tus gestiones se realizan desde tu móvil.",
    children: [{value: "Visualizar tus seguros estén mediados por Prudenzia o no."}, {value: "Recibir consejos en tus renovaciones."}, {
        value: "Comunicar siniestros desde cualquier lugar sin tener que ir a una oficina."
    }, {
        value: "Renovar tus contratos, anularlos, modificarlos... y todo desde tu dispositivo móvil."
    }],
},
    {
        value: "Asesoría personalizada. Un asesor personal es asignado en el momento de bajarte la App."
    },  {
        value: "“Acceso a más de 40 compañías de seguros desde una única App."
    },  {
        value: "Acceso a productos innovadores y exclusivos. Garantías complementarias únicas en el mercado."
    },  {
        value: "Actualización año a año de tus contratos para evitar “sorpresas” en precio y garantías."
    }
]
export default function AboutPage() {
    return <Container>

        <Box mb={6}>
            <Heading mb={3}>¿Quiénes somos?</Heading>
            <Text mb={3}>Prudenzia es la primera correduría phygital, ofrecemos tanto desde nuestra
                app como nuestras oficinas las posibilidad de contratar y gestionar todas tus
                pólizas de seguros.</Text>
            <Text mb={3}>Trabajamos con los principales actores del sector asegurador en un amplio
                número de especialidades y ramos.</Text>
            <Text mb={3}>Contamos con un equipo especializado para ofrecer a nuestros clientes
                presencialmente o en remoto con una misión; ofrecer siempre los mejores
                productos con las mejores condiciones a nuestros clientes.</Text>



        </Box>

        <Box mb={6}>
            <Heading mb={3}>Ventajas de Prudenzia</Heading>

            <DotList data={firstDotList}></DotList>
        </Box>

        <Box mb={6}>
            <Heading mb={3}>Autorización para operar con Seguros</Heading>
            <Text mb={3}>Prudenzia Broker Group con C.I.F.: B05461165 y domicilio en Paseo Doce
                Estrellas,2,1ªA, 28042, Madrid, es una correduría de seguros con
                autorización en la DGSFP J-4007, que desempeña la actividad de mediación
                de seguros conforme al artículo 131 del R.D. Ley 03-2020 de Trasposición de
                la Directiva (UE) 2016/97 del Parlamento Europeo y del Consejo, sobre la
                distribución de seguros, de 4 de febrero de 2020.</Text>

        </Box>

        <Box mb={6}>
            <Heading mb={3}>Independencia de las compañías aseguradoras</Heading>
            <Text mb={3}>
                Prudenzia Broker Group es una correduría de seguros, por lo tanto somos totalmente
                independientes a las compañías aseguradoras.</Text>

            <Text mb={3}>Nuestros ingresos provienen de la actividad usual de las corredurías de seguros y nuestro
                accionariado no está compuesto por ninguna compañía de seguros.</Text>
        </Box>

        <Box mb={6}>
            <Heading mb={3}>Contacta con nosotros</Heading>
            <Text mb={3}>Si quieres recibir información sobre como mejorar tus seguros en cuanto a
                condiciones y precios ponte en contacto con nosotros:</Text>
            <Text mb={3}>Email: <EmailLink email={"hola@prudenzia.es"}></EmailLink></Text>
            <Text mb={3}>Teléfonos:</Text>
            <TelephoneLink telephone={"0059107"} ext={"+91"}>
                <Text style={styles.link} mb={3}> {"+91 00 59 107"} </Text>
            </TelephoneLink>
            <TelephoneLink telephone={"10801044"} ext={"+93"}>
                <Text style={styles.link} mb={3}> {"+93 10 80 10 44"} </Text>
            </TelephoneLink>
        </Box>

        <Box mb={6}>
            <Heading mb={3}>¿Cómo me doy de baja en Prudenzia?</Heading>
            <Text mb={3}>
                Si quieres darte de baja de la APP de Prudenzia, por favor envíanos un email al correo <EmailLink email={"bajas@prudenzia.es"}></EmailLink> con tu nombre y número de telefono con el que te registraste y nosotros la gestionamos.</Text>

        </Box>
    </Container>
}
