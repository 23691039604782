/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 19/10/2023
 * @description This React component, `AddressSearch`, is designed for searching and selecting address-related information like municipalities or road names based on user input. It dynamically fetches and displays a list of search results from an API, filtering results based on the `type` prop which can either be `address_town` or `address_road_name`, and additional parameters such as `provinceId` and `municipalityId` for more refined searches. The component maintains its state for the search text (`showingText`), the list of items to display (`showingList`), and the selected address (`address`). On selecting an item from the list, it updates the selected address and clears the list. The component is designed to be reusable with customizable placeholders, read-only, and disabled states.
 */

import {useState} from "react";
import {API} from "../../queries/api";
import CommonSearch from "../CommonSearch/CommonSearch";

export default function AddressSearch({type, onChange, placeholder, readOnly, disabled, provinceId, municipalityId}) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);
    const [address, setAddress] = useState();

    const onSearch = async (targetValue) => {
        setShowingText(targetValue)
        let newData;
        if (targetValue !== "") {
            if (type === "address_town") {
                const {data} = await API(
                    `data/location/municipality/list/${provinceId}?search=` + targetValue
                );
                newData = data;
            } else if (type === "address_road_name") {
                const {data} = await API(
                    `data/location/road/list/${provinceId}/${municipalityId}/${targetValue}`
                );
                newData = data.filter(item => item.type).map(item => ({
                    ...item,
                    label: item.type.name + " " + item.name
                }));
            }
            setShowingList(newData)

        } else {
            setShowingList([])
        }
    }

    const skip = {
        skip: [
            {fieldKey: "nationality", value: "AD"}, {fieldKey: "nationality", value: "IE"}
        ]
    }

    const selectItem = (item) => {
        onChange(item);
        setAddress({...address, [type]: item.id})
        setShowingText(item.name)
        setShowingList([])
    }

    return <CommonSearch showingList={showingList} placeholder={placeholder} onSearch={onSearch}
                         showingText={showingText} selectItem={selectItem}
                         disabled={disabled} readOnly={readOnly}/>

}
