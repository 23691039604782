/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/10/2023
 * @description A React component for an address form, dynamically loading provinces and then further details based on user selections. It uses the `useForm` hook for form control and validation, and custom hooks for fetching provinces and address details (floors and doors options) from an API. The form fields dynamically update based on user input, with validations ensuring required fields are filled. The `getFloorsAndDoors` function processes building data to populate floor and door options. The component integrates `useTranslation` for i18n, supporting internationalization.
 */
import {Box} from "native-base";
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import {API} from "../../queries/api";
import {useTranslation} from "react-i18next";
import AddressSearch from "../AddressSearch/AddressSearch";

let buildingData;
export default function AddressForm({control, setValue, errors, watch}) {
    const [provinces, setProvinces] = useState([]);
    const {t} = useTranslation()
    const [floorsOptions, setFloorsOptions] = useState([]);
    const [doorsOptions, setDoorsOptions] = useState([]);

    useEffect(() => {
        const getProvinces = async () => {
            const {data} = await API(
                `data/location/province/list`
            );
            setProvinces(data.map(item => ({
                label: item.name,
                value: item.id
            })).sort((a, b) => a.label < b.label ? -1 : 1))
        }

        getProvinces()
    }, []);

    useEffect(() => {
        const getAddressInfo = async () => {
            if (watch("province") && watch("address_town_search") && watch("address_road_name") && watch("address_road_number")) {
                const {data} = await API(
                    `data/location/address/list/${watch("province")}/${watch("address_town_search")}/${watch("address_road_name")}/${watch("address_road_number")}`
                );
                buildingData = data;
                let addressData;
                if (data && data.length > 1) {
                    addressData = getFloorsAndDoors(data)
                } else {
                    setValue("address_floor", null)
                    setValue("address_door", null)
                    addressData = {floors: null, doors: null}
                }
                setFloorsOptions(addressData.floors)
                setDoorsOptions(addressData.doors)
            }

        }
        getAddressInfo()
    }, [watch("province"), watch("address_town_search"), watch("address_road_name"), watch("address_road_number")]);

    useEffect(() => {
        const addressFloor = watch("address_floor");
        const addressDoor = watch("address_door");
        if ( (addressFloor || addressFloor === null) && (addressDoor || addressDoor === null) && buildingData) {
            const addressInfoData = addressDoor === null ? buildingData[0] : buildingData.find(floorData => floorData.address.floor === addressFloor && floorData.address.door === addressDoor)
            const addressData = addressInfoData ? addressInfoData.address : null;
            if (addressData && addressData.roadType) {
                setValue("address_cadastral_reference", addressData.cadastralReference)
                setValue("address_road_type", addressData.roadType.id)
                setValue("address_town", addressData.town.id)
                const completeAddressName = `${addressData.roadType.name.length > 0 ? addressData.roadType.name + " " : ""}${addressData.roadName} ${addressData.roadNumber}, ${addressData.floor && addressData.door ?addressData.floor  + " " +addressData.door + " ,": "" }${addressData.postalCode}${addressData.town ? ` ${addressData.town.name} (${addressData.town.province.name}` : ""})`
                setValue("address", completeAddressName)
                setValue("address_postal", addressData.postalCode)
            }
        }
    }, [watch("address_floor"), watch("address_door")]);


    return <Box>
        <Box w={"100%"}>
            <FormInput control={control} errors={errors} placeholder={t("Select your province")} name={"province"}
                       rules={{required: true}} type={"select"} options={provinces}/>
        </Box>

        {watch("province") && <Box w={"100%"} mt={"3"}>
            <Controller
                control={control}
                name={"address_town_search"}
                render={({field: {onChange, onBlur, value}}) => (
                    <AddressSearch
                        placeholder={t("Search municipality")}
                        provinceId={watch("province")}
                        onChange={e => {
                            onChange(e.id)
                        }} type={"address_town"}/>
                )}/>
        </Box>}

        {watch("address_town_search") && <Box w={"100%"} mt={"3"}>
            <Controller
                control={control}
                name={"address_road_name"}
                render={({field: {onChange, onBlur, value}}) => (
                    <AddressSearch
                        placeholder={t("Search address")}
                        provinceId={watch("province")}
                        municipalityId={watch("address_town_search")}
                        onChange={e => {
                            onChange(e.id)
                        }} type={"address_road_name"}/>
                )}/>
        </Box>}

        {/*<FormInput control={control} errors={errors} placeholder={t("Postal code")} name={"postalCode"}*/}
        {/*           rules={{required: true}}/>*/}

        {watch("address_road_name") && <Box w={"100%"} mt={"3"}>
            <FormInput control={control} errors={errors} placeholder={t("Portal number")} name={"address_road_number"}
                       rules={{required: true}}/>
        </Box>}


        {watch("address_road_number") && floorsOptions && <Box w={"100%"} mt={"3"}>
            <FormInput control={control} errors={errors} placeholder={t("Select your floor")}
                       name={"address_floor"}
                       rules={{required: true}} type={"select"} options={floorsOptions}/>
        </Box>}

        {watch("address_floor") && doorsOptions && <Box w={"100%"} mt={"3"}>
            <FormInput control={control} errors={errors} placeholder={t("Select your door")}
                       name={"address_door"}
                       rules={{required: true}} type={"select"}
                       options={doorsOptions.filter(door => door.floor === watch("address_floor"))}/>
        </Box>}


        {/*{watch("address_door") && <Box w={"100%"} mt={"3"}>*/}
        {/*    <FormInput control={control} errors={errors} placeholder={t("Additional data")} name={"address_extra"}*/}
        {/*    />*/}
        {/*</Box>}*/}
    </Box>
}

const getFloorsAndDoors = (floors) => {
    const floorsOptions = []
    const doorsOptions = []
    for (const floor of floors) {
        if (floor) {
            const address = floor.address;
            const addressFloor = address.floor;
            const addressDoor = address.door;
            const floorFound = floorsOptions.find(floorOption => floorOption.value === floor.address.floor)
            if (!floorFound) {
                floorsOptions.push({label: addressFloor, value: addressFloor})
            }
            doorsOptions.push({label: addressDoor, value: addressDoor, floor: addressFloor})
        }
    }

    return {floors: floorsOptions, doors: doorsOptions}
}
