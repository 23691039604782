/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/12/2023
 * @description The `BudgetsGallery` component displays a collection of insurance budget options in a responsive gallery layout. It dynamically adjusts the number of columns based on the screen size, with fewer columns on mobile devices for a better viewing experience. Each budget item in the gallery includes:
 *
 * - A company logo, presented through an `Image` component.
 * - The insurance modality name, if available.
 * - A tag representing a key feature or category of the insurance, emphasized in the layout.
 * - The pricing information, showing the first bill plus any other bills divided by the payment frequency, rounded to two decimal places for clarity.
 * - A button labeled with either a custom `buttonLabel` or a default label indicating the user's interest in the option.
 * - A link to detailed information about the offer, allowing users to explore further.
 *
 * The component is designed for flexibility, accommodating various data structures and providing users with essential information at a glance. The inclusion of actionable buttons and links encourages user interaction, guiding them towards making informed decisions regarding their insurance needs.
 */
import {Box, Button, Column, Flex, Image, Link, Row, Text, useBreakpointValue, useMediaQuery} from "native-base";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {StyleSheet} from "react-native";
import {useTranslation} from "react-i18next";
import {breakpoints, theme} from "../../styles/theme";
import {useState} from "react";

const gap = 15;
export default function BudgetsGallery({data, onPress, buttonLabel, }) {
    const {t} = useTranslation()
    const [individualSize, setIndividualSize] = useState()
    const minWidth = useBreakpointValue({
        md: breakpoints.md
    });
    const [isDesktop] = useMediaQuery({
        minWidth
    });
    const numColumns = (isDesktop ? 3 : 1)
    return <Box
        onLayout={(event) => setIndividualSize(event.nativeEvent.layout.width / numColumns - gap + (gap / numColumns))}
        style={styles.container}>


        {individualSize && data && data.map(item => <Flex flexDirection={ "column"}
                                                          key={item.id} style={styles.item} space={3}
                                                          marginBottom={3}
                                                          alignItems={"center"} padding={["16px", "16px"]}
                                                          width={ individualSize}>
            <Column justifyContent={"center"} alignItems={"center"}>
                <Image
                    style={styles.image}
                    resizeMode={"contain"}
                    source={{
                        uri: process.env.REACT_APP_IMAGE_URL + item.company.logo,
                    }}
                    alt={item.name}
                />
                {item.modalityName && <Text
                    numberOfLines={1}
                    fontSize={14}
                >
                    {item.modalityName}
                </Text>}
                <Text fontSize={14} h={30}
                      fontWeight={"600"}
                      color={"secondary.700"}>{t(item.tag).toUpperCase()}</Text>
                <Row alignItems={"center"}>
                    <Text fontSize={18}
                          fontWeight={"600"}
                          color={"secondary.700"}> {Math.trunc(
                        (((item.firstBill ? item.firstBill : 0) + (item.otherBills ? item.otherBills : 0)) /
                            paymentFrequencyConst.find(
                                (paymentFrequency) =>
                                    paymentFrequency.value === item.frequency
                            ).fractional) *
                        100
                    ) / 100}
                        €{" "}</Text>
                    <Text color={"secondary.700"}
                    >
                        {" "}
                        {"/" + t(item.frequency).toLowerCase()}
                    </Text>


                </Row>
            </Column>

            <Column alignItems={"center"}>

                <Button onPress={() => onPress(item)}>{buttonLabel ? buttonLabel : t("I want it")}</Button>
                <Link  mt={2} href={process.env.REACT_APP_IMAGE_URL + item.fileLocation} isExternal={true}>
                    {t("See detail")}
                </Link>
            </Column>
        </Flex>)}
    </Box>
}
const styles = StyleSheet.create({
    container: {
        flexWrap: "wrap",
        gap,
        display:"flex",
        flexDirection: "row",
        width: "100%",
    },
    item: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        borderRadius: 6,
    },

    image: {
        width: 100,
        height: 100
    },
});
