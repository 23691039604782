import {Box, Button, Pressable, Row, Text} from "native-base";
import FormInput from "../../../../components/FormInput/FormInput";
import React from "react";
import {goToUtil} from "../../../../utils/goTo.util";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {API} from "../../../../queries/api";

export default function OffersHireCheck({onSubmit, onCallAdvisor}) {
    const {t} = useTranslation()
    const {control, handleSubmit, formState: {errors},} = useForm();



    return <Box>
        <FormInput control={control} errors={errors} name={"date"} label={"Initial coverage date"}
                   type={"calendar"}
                   rules={{required: true}}
        />

        <Button mt={6} onPress={handleSubmit(onSubmit)}>
            {t("Confirm data")}
        </Button>
        <Row mt={"3"} justifyContent={"center"}>
            <Pressable onPress={() => {
                goToUtil("offers-detail", navigation, {id})
            }}>
                <Text fontSize={16} onPress={onCallAdvisor}>{t("I prefer to get call by my advisor")}</Text>
            </Pressable>
        </Row>
    </Box>
}
