/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 08/09/2023
 * @description A React component for displaying a gallery of insurance policies with the option to add, edit, or remove individual policies. It adapts to screen size using responsive design techniques to set the number of columns displayed. The component allows for interaction with each policy through pressable elements, dynamically adjusting layout and styling based on the presence of policies and user actions. The gallery layout is computed on layout events to ensure proper spacing and alignment. It leverages translation for text elements and icons for visual cues.
 */
import { Box, Column, Image, Pressable, Row, Text, useBreakpointValue, useMediaQuery } from "native-base";
import { StyleSheet } from 'react-native';
import { breakpoints, theme } from "../../styles/theme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

const gap = 15;
export default function AddPolicyGallery ({ data, onPress, onSubRemove = () => {}, onSubEdit = () => {} }) {
    const [individualSize, setIndividualSize] = useState()
    const minWidth = useBreakpointValue({
        md: breakpoints.md
    });
    const [isDesktop] = useMediaQuery({
        minWidth
    });
    const { t } = useTranslation();

    const numColumns = (isDesktop ? 3 : 1)
    return <Box
        onLayout={(event) => setIndividualSize(event.nativeEvent.layout.width / numColumns - gap + (gap / numColumns))}
        style={styles.container}>
        {individualSize && data && data.map((insurance, insuranceIndex) => <Column key={insurance.id}
        >
            <Pressable onPress={() => onPress(insurance)}
                       width={individualSize}
                       style={insurance.policies ? { ...styles.box, ...styles.boxWithSub } : styles.box}>
                <Row space={3} alignItems={"center"} justifyContent="space-between" w={"100%"} h={[120, 200]}>
                    <Image style={styles.image} resizeMode={"contain"} source={{
                        uri: process.env.REACT_APP_IMAGE_URL + insurance.logo
                    }} alt={insurance.name}/>
                    <Row space={3} alignItems={"center"}>
                        <Text fontSize={14}>{t("Add policy")}</Text>
                        <MaterialCommunityIcons name="plus-circle-outline" color={theme.colors.gray["400"]} size={18}/>
                    </Row>
                </Row>
            </Pressable>
            {insurance.policies && insurance.policies.map((policy, policyIndex) => <Row
                style={insurance.policies.length === policyIndex + 1 ? { ...styles.subBox, ...styles.subBoxLast } : styles.subBox}
                justifyContent={"space-between"}
                key={policy.id}>
                <Row space={3} alignItems={"center"}>
                    <MaterialCommunityIcons name="file-document-outline" color={theme.colors.gray["100"]}
                                            size={18}/>
                    <Text>{t("Policy")} {policyIndex + 1}</Text>
                </Row>
                <Row space={3} alignItems={"center"}>
                    <MaterialIcons onPress={() => onSubEdit(insurance, policy, policyIndex)} name="mode-edit"
                                   color={theme.colors.gray["400"]} size={18}/>
                    <MaterialIcons onPress={() => onSubRemove(insuranceIndex, policyIndex)} name="delete"
                                   color={theme.colors.gray["400"]} size={18}/>
                </Row>
            </Row>)}
        </Column>)}
    </Box>
}

const styles = StyleSheet.create({
    container: {
        flexWrap: "wrap",
        gap,
        flexDirection: "row",
        width: "100%",
    },
    box: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 6,
        textAlign: 'center',
        alignItems: "center",
        fontWeight: 'bold',
    },
    boxWithSub: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomWidth: 0
    },
    subBox: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 0,
        borderBottomWidth: 0,
        textAlign: 'center',
        alignItems: "center",
        fontWeight: 'bold',
    },
    subBoxLast: {
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        borderBottomWidth: 1,

    },
    selectedBox: {
        borderColor: theme.colors.secondary["500"],
        backgroundColor: theme.colors.secondary["50"],
    },
    image: {
        width: "30%",
        height: "85%"
    }
});
