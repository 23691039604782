/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/10/2023
 * @description This React component, `CompleteProfileMessage`, displays a modal message to users encouraging them to complete their profile if it's not fully completed. It calculates the completion percentage of the user's profile data and only shows the modal if the user is logged in and the profile is not 100% complete. The calculation is done by iterating over the user object's fields, counting non-null fields to determine what percentage of the profile has been filled out. This percentage is visually represented using an `AnimatedCircularProgress` component. The modal contains a pressable area that, when tapped, navigates the user to their profile page for further action. The component leverages the `useTranslation` hook for internationalization, allowing for the dynamic translation of text content.
 */
import { Box, Column, Pressable, Row, Text } from "native-base";
import { useTranslation } from "react-i18next";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { theme } from "../../styles/theme";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../consts/globalContext.const";
import Modal from "../Modal/Modal";
import { useNavigation } from "@react-navigation/native";
import {goToUtil} from "../../utils/goTo.util";

export default function CompleteProfileMessage () {
    const { t } = useTranslation()
    const { user, isLogged } = useContext(GlobalContext);
    const [percentage, setPercentage] = useState()
    const [showModal, setShowModal] = useState(true);
    const navigation = useNavigation();


    useEffect(() => {
        const calcPercentageNoFilledData = (user) => {
            let countFieldNoFilled = 0
            let fieldCount = 0
            for (const key in user) {
                fieldCount++;
                if (user.hasOwnProperty(key)) {
                    countFieldNoFilled = user[key] === null ? countFieldNoFilled + 1 : countFieldNoFilled
                }
            }
            setPercentage(countFieldNoFilled && fieldCount ? (100 - Math.trunc(((countFieldNoFilled / fieldCount) * 100))): 100);
        }

        calcPercentageNoFilledData(user);
        
        const timer = setTimeout(() => {
            setShowModal(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [])


    return isLogged && percentage !== 100 && showModal ?
        <Modal><Pressable onPress={() => goToUtil('profile', navigation)}><Row alignItems="center">
            {percentage && <><Box>
                <AnimatedCircularProgress
                    size={50}
                    width={5}
                    fill={percentage}
                    tintColor={theme.colors.secondary["500"]}
                    backgroundColor={theme.colors.bg["500"]}>
                    {
                        () => (
                            <Text>
                                {percentage}%
                            </Text>
                        )
                    }
                </AnimatedCircularProgress>
            </Box>
                <Column ml={3}>
                    <Text fontWeight={"500"}>{t("Complete your profile")}</Text>
                    <Text>{t("Please, fill your data")}</Text>
                </Column></>}
        </Row></Pressable></Modal> : <></>
}
