/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 15/10/2023
 * @description The `Loading` component displays an activity indicator overlay when the application is in a loading state, utilizing context to manage visibility. It uses the `GlobalContext` to access the `isLoading` flag, showing a centrally positioned activity indicator if `isLoading` is true. The overlay covers the entire screen, ensuring user focus on the loading process, with styling that includes a semi-transparent background. This component enhances user experience by providing visual feedback during data fetching or processing operations.
 */
import {Box} from "native-base";
import {useContext} from "react";
import {ActivityIndicator, StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {GlobalContext} from "../../consts/globalContext.const";

export default function Loading() {
    const {isLoading} = useContext(GlobalContext)
    return isLoading ? <Box style={styles.container}>
        <ActivityIndicator size="large" color={theme.colors.secondary["500"]}/>
    </Box> : <></>
}
const styles = StyleSheet.create({
        container: {
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors.loading["background"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999
        }
    }
)
