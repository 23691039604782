/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/07/2023
 * @description The `Menu` component displays navigation links for a user interface, distinguishing between logged-in user options (`menuList`) and general links (`menuLinksList`). It uses React Navigation for handling route changes and integrates with a global context to access user and application state, such as the logged-in status and specific data like pending offers.
 *
 * - **Logged-in User Links:** Conditionally rendered based on the user's logged-in status. Icons are displayed next to link names, with special handling to hide certain links on desktop or mobile views. It also dynamically highlights the current route and displays a count bubble for specific items like offers.
 *
 * - **General Links:** Always displayed at the bottom, regardless of the user's logged-in status. These links do not include icons but highlight the current route.
 *
 * The component is designed to be flexible and responsive, adapting to different screen sizes and user states. It enhances navigation by providing quick access to various parts of the application, improving the overall user experience.
 */
import {Box, Column, Icon, Pressable, Row, Text} from "native-base";
import {useNavigation, useRoute} from "@react-navigation/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import PoliciesPage from "../../pages/PoliciesPage/PoliciesPage";
import SelectInsurancePage from "../../pages/SelectInsurancePage/SelectInsurancePage";
import HireInsurancePage from "../../pages/HireInsurancePage/HireInsurancePage";
import AddPolicyPage from "../../pages/AddPolicyPage/AddPolicyPage";
import ChatBotPage from "../../pages/ChatBotPage/ChatBotPage";
import MyAccountPage from "../../pages/MyAccountPage/MyAccountPage";
import MyProfilePage from "../../pages/MyProfilePage/MyProfilePage";
import AdvisorPage from "../../pages/AdvisorPage/AdvisorPage";
import OffersPage from "../../pages/OffersPage/OffersPage";
import PoliciesEditPage from "../../pages/PoliciesEditPage/PoliciesEditPage";
import PoliciesDetailPage from "../../pages/PoliciesDetailPage/PoliciesDetailPage";
import {goToUtil} from "../../utils/goTo.util";
import LegalAdvisePage from "../../pages/LegalAdvisePage/LegalAdvisePage";
import AboutPage from "../../pages/AboutPage/AboutPage";
import PrivacyPolicySocial from "../../pages/PrivacyPolicySocial/PrivacyPolicySocial";
import PrivacyPolicyAndCookies from "../../pages/PrivacyPolicyAndCookies/PrivacyPolicyAndCookies";
import ConsentForDataTreatment from "../../pages/ConsentForDataTreatment/ConsentForDataTreatment";
import {GlobalContext} from "../../consts/globalContext.const";
import {StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import OffersDetailPage from "../../pages/OffersDetailPage/OffersDetailPage";
import OffersHirePage from "../../pages/OffersHirePage/OffersHirePage";

export const menuList = [
    {name: "My insurances", icon: "security", to: "policies", component: PoliciesPage},
    {name: "Policy", icon: null, to: "policies-edit", component: PoliciesEditPage},
    {name: "Policy", icon: null, to: "policies-detail", component: PoliciesDetailPage},
    {name: "Advisor", icon: "headset", to: "advisor", component: AdvisorPage, hideOnDesktop: true},
    {name: "Hire insurances", icon: "plus-circle-outline", to: "hire-insurance", component: HireInsurancePage},
    {name: "Offers", icon: "tag", to: "offers", component: OffersPage},
    {name: "Offers", icon: null, to: "offers-detail", component: OffersDetailPage},
    {name: "Hire Offer", icon: null, to: "offers-hire", component: OffersHirePage},

    {
        name: "Add policies",
        icon: "file-upload-outline",
        to: "select-insurance",
        component: SelectInsurancePage,
        hideOnMobile: true
    },
    {name: "Add policies", icon: null, to: "add-policy", component: AddPolicyPage},
    {name: "New proposal", icon: null, to: "chat-bot", component: ChatBotPage},
    {name: "My account", icon: "account", to: "account", component: MyAccountPage},
    {name: "My profile", icon: null, to: "profile", component: MyProfilePage},
]
export const menuLinksList = [
    {name: "Frequent Answers", icon: "office-building", to: "frequent-answers", component: AboutPage},
    {name: "Legal Advise", icon: "content-paste", to: "legal-advise", component: LegalAdvisePage},
    {
        name: "Privacy Policy and Cookies",
        icon: "cookies-policy",
        to: "privacy-policy-and-cookies",
        component: PrivacyPolicyAndCookies
    },
    {
        name: "Social Privacy Policy",
        icon: "file-key-outline",
        to: "social-privacy-policy",
        component: PrivacyPolicySocial
    },
    {
        name: "Consent for Data Treatment",
        icon: "handshake",
        to: "consent-treatment",
        component: ConsentForDataTreatment
    },
]

export default function Menu() {
    const navigation = useNavigation();
    const {t} = useTranslation()
    const route = useRoute();
    const {isLogged, offersBody} = useContext(GlobalContext)

    return <Column style={{flex: 1}} justifyContent={isLogged ? "space-between" : "flex-end"}>
        {isLogged && <Box flexDirection="column">
            {menuList.map((menuItem) => menuItem.icon && !menuItem.hideOnDesktop ?
                <Pressable onPress={() => goToUtil(menuItem.to, navigation)} key={menuItem.to}>
                    <Row w="100%" p="4" h="50" borderBottomWidth={"1"} borderColor={"gray.100"}>
                        <Icon color={route.name === menuItem.to ? "secondary.500" : "gray.400"} size="5"
                              as={MaterialCommunityIcons} name={menuItem.icon} mr={"2"}/>
                        <Text color={route.name === menuItem.to ? "secondary.500" : "gray.400"}
                              fontSize={"sm"}>{t(menuItem.name)}</Text>
                        {menuItem.to === "offers" && offersBody.countUserPendingOffers > 0 &&
                            <Box style={styles.bubble}><Text
                                color={theme.colors.gray["0"]}>{offersBody.countUserPendingOffers}</Text></Box>}
                    </Row>
                </Pressable> : <React.Fragment key={menuItem.to}/>
            )}
        </Box>}

        <Box flexDirection="column">
            {menuLinksList.map((menuItem) =>
                <Pressable onPress={() => goToUtil(menuItem.to, navigation)} key={menuItem.to}>
                    <Row w="100%" py={1} px={4} borderBottomWidth={"1"} borderColor={"gray.100"}>
                        <Text color={route.name === menuItem.to ? "secondary.500" : "gray.400"}
                              fontSize={"10"}>{t(menuItem.name)}</Text>
                    </Row>
                </Pressable>
            )}
        </Box>
    </Column>
}

const styles = StyleSheet.create({
    bubble: {
        backgroundColor: theme.colors.secondary["500"],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        width: 25,
        height: 25,
        borderRadius: 50,
        position: "absolute",
        right: 16,
        top: 12.5
    }
});
