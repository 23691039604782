const defaultProps = {
  _statLabel: {
    fontSize: 'xl',
  },
  _statNumber: {
    fontSize: '4xl',
    fontWeight: 'bold',
    my: 2,
  },
  _statHelpText: {
    _text: {
      color: 'gray.500',
      fontSize: 'xl',
    },
    flexDirection: 'row',
    alignItems: 'center',
  },
  _statGroup: {
    flexWrap: 'wrap',
    space: 4,
    justifyContent: 'space-between',
  },
};

export default {
  defaultProps,
};
