/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/09/2023
 * @description The `ListLink` component displays a list of items where each item is a pressable link, potentially leading to different parts of an application or triggering specific actions. It uses the `useTranslation` hook to support internationalization and the `useNavigation` hook from React Navigation for navigation purposes. Each item can have an associated icon from the Material Community Icons library, enhancing visual identification. The component allows for custom onPress functions for each item, providing flexibility in handling user interactions. Items are styled to differentiate the first item from others, ensuring a cohesive and visually appealing list presentation.
 */
import {Column, Pressable, Row, Text} from "native-base";
import {useNavigation} from "@react-navigation/native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {theme} from "../../styles/theme";
import {useTranslation} from "react-i18next";
import {StyleSheet} from "react-native";
import {goToUtil} from "../../utils/goTo.util";

export default function ListLink({data}) {
    const {t} = useTranslation()
    const navigation = useNavigation();

    return <Column>
        {data.map((item, index) => <Pressable key={index}
                                              onPress={() => {
                                                  item.onPress ? item.onPress() : goToUtil(item.to, navigation)
                                              }}>
                <Row alignItems={"center"} space={3}
                     style={index === 0 ? {...styles.item, ...styles.itemFirst} : styles.item}>
                    <MaterialCommunityIcons name={item.icon} color={theme.colors.secondary["500"]} size={22}/>
                    <Text fontSize={18}>{t(item.name)}</Text>
                </Row>
            </Pressable>
        )}
    </Column>
}

const styles = StyleSheet.create({
        item: {
            padding: 20,
            borderBottomWidth: 1,
            borderColor: theme.colors.gray["100"],
            width: "100%"
        },
        itemFirst: {paddingTop: 0}

    }
)
