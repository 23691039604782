/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/10/2023
 * @description This React component represents an "Offers" page. It fetches offers data and displays them in a gallery format. It utilizes hooks like useNavigation, useContext, and useTranslation for navigation, global state management, and translation respectively. The component fetches offer details and calculates the minimum budget price for each offer. It renders different content based on the number of offers available. If there are no offers, it prompts the user to request new insurance. If there are offers available, it renders them in a gallery, allowing users to view details or hire insurance. The UI elements are structured within a Container component, with conditional rendering based on the availability of offers.
 */
import Container, {getOffers} from "../../components/Container/Container";
import React, {useContext, useEffect} from "react";
import {Button, Column, Heading, Text} from "native-base";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import {goToUtil} from "../../utils/goTo.util";
import {GlobalContext} from "../../consts/globalContext.const";
import OffersGallery from "../../components/OffersGallery/OffersGallery";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";

export default function OffersPage() {
    const navigation = useNavigation();
    const {offersBody, setOffersBody} = useContext(GlobalContext)

    const {t} = useTranslation()

    useEffect(() => {
        const getBudgetsDetail = async () => {
            try {
                const newOffers = (await getOffers()).data;
                for (let i = 0; i < newOffers.length; i++) {
                    const offer = newOffers[i];
                    if (offer.budgets) {
                        const sortedBudgets = offer.budgets.map((budget) => (budget.firstBill) + (budget.otherBills ? budget.otherBills * paymentFrequencyConst.find(
                            (paymentFrequency) =>
                                paymentFrequency.value === budget.frequency
                        ).fractional : 0)).sort()
                        newOffers[i].minBudgetPrice = sortedBudgets[0]
                    }
                }
                setOffersBody({...offersBody, data: newOffers})


            } catch (e) {
                console.log(e)
            }
        }

        getBudgetsDetail()
    }, []);
    return <Container>
        {/*{offers && <PolicyGallery data={offers} onPress={() => {}}/>}*/}
        {(offersBody.count === 0) && <Column>
            <Heading>{t("Oh! You don't have active offers")}</Heading>
            <Text
                mt="3">{t("Request a check for your fees from insurances page or find a new insurance for you")}.</Text>


            <Button mt="6" onPress={() => goToUtil('hire-insurance', navigation)}>{t("Request new insurance")}</Button>

        </Column>}
        {offersBody.count !== 0 && <OffersGallery data={offersBody.data}
                                                  onPress={(offer) => offer.acceptedBudgetId ? goToUtil('offers-hire', navigation, {id: offer.id}): goToUtil('offers-detail', navigation, {id: offer.id})} />}
    </Container>
}
