/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/14/2023
 * @description The `ConsentForDataTreatment` page outlines PRUDENZIA BROKER GROUP S.L's data protection and privacy practices. It informs users about the handling of personal data, including the purpose of data collection, legal basis for processing, user rights, and security measures in place. Key sections cover data processing activities, user consent, rights to access and control personal data, and the company's commitment to data security under GDPR and LOPDGDD regulations.
 */
import Container from "../../components/Container/Container";
import {Box, Heading, Link, Text} from "native-base";
import DotList from "../../components/ListDot/ListDot";
import {theme} from "../../styles/theme";
import {useNavigation} from "@react-navigation/native";


const firstDotList = [
    {
        value: "Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o de sus colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos personales.",
    },
    {
        value: "Realizar estudios de mercado y análisis estadísticos."
    }, {
        value: "Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto que se ponen a su disposición en la página web del RESPONSABLE."
    }, {
        value: "Remitir el boletín informativo online, sobre novedades, ofertas y promociones en nuestra actividad."
    }
]


const secondDotList = [
    {
        value: "Con el consentimiento del USUARIO: remisión de comunicaciones comerciales y del boletín informativo.",
    },
    {
        value: "Por interés legítimo del RESPONSABLE: realizar estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del USUARIO."
    }
]

const thirdDotList = [
    {
        value: "Derecho a retirar el consentimiento en cualquier momento.",
    },
    {
        value: "Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento."
    }, {
        value: "Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente."
    }
]


export default function ConsentForDataTreatment() {
    const navigation = useNavigation();

    return <Container>
        <Box mb={6}>
            <Heading mb={3}>FORMULARIO: Consentimiento para el tratamiento de datos LOPDGDD</Heading>
            <Text mb={3} fontWeight={700}>PROTECCIÓN DE DATOS:</Text>
            <Text mb={3}>De conformidad con las normativas de protección de datos, le facilitamos la siguiente
                información del
                tratamiento:
                Responsable: PRUDENZIA BROKER GROUP S.L
                Fines del tratamiento: mantener una relación comercial y enviar comunicaciones de productos o servicios
                Derechos que le asisten: acceso, rectificación, portabilidad, supresión, limitación y oposición
                Más información del tratamiento en la <Link
                    onPress={() => navigation.navigate('privacy-policy-and-cookies')}>Política de privacidad</Link>
                O Acepto el tratamiento de mis datos para el envío de comunicaciones de productos o servicios</Text>
        </Box>


        <Box mb={6}>
            <Heading mb={3}>POLÍTICA DE PRIVACIDAD</Heading>
            <Heading fontSize={16} mb={3}>1. INFORMACIÓN AL USUARIO</Heading>
            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Quién es el responsable del tratamiento de
                tus datos personales?</Text>
            <Text mb={3}><Text fontWeight={700}>PRUDENZIA BROKER GROUP S.L</Text> es el RESPONSABLE del tratamiento de
                los datos personales del
                USUARIO y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el
                Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre
                (LOPDGDD).</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Para qué tratamos tus datos
                personales?</Text>
            <Text mb={3}>Para mantener una relación comercial con el usuario. Las operaciones previstas para realizar el
                tratamiento son:</Text>

            <DotList data={firstDotList}></DotList>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Por qué motivo podemos tratar tus datos
                personales?</Text>
            <Text mb={3}>Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:</Text>

            <DotList data={secondDotList}></DotList>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Durante cuánto tiempo guardaremos tus
                datos personales?</Text>
            <Text mb={3}>Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o
                existan
                prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán
                con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total
                de los mismos.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿A quién facilitamos tus datos
                personales?</Text>
            <Text mb={3}>No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario
                para el
                desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios
                relacionados
                con comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y
                de encargado de tratamiento exigidos por la normativa vigente de privacidad.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Cuáles son tus derechos?</Text>
            <Text mb={3}>Los derechos que asisten al USUARIO son:</Text>

            <DotList data={thirdDotList}></DotList>

            <Text mb={3} fontWeight={700}>Datos de contacto para ejercer sus derechos:</Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>

        </Box>


        <Box mb={6}>
            <Heading fontSize={16} mb={3}>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA
                POR EL USUARIO</Heading>
            <Text mb={3}>Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en
                los
                campos, marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de
                descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para
                atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos
                restantes. El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y
                se hace responsable de comunicar cualquier modificación de los mismos.
                El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya
                que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten
                todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados
                a
                sus necesidades.</Text>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>3. MEDIDAS DE SEGURIDAD</Heading>
            <Text mb={3}>Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos
                personales, el
                RESPONSABLE está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para
                el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios
                descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente
                en
                relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines
                para los que son tratados.</Text>
            <Text mb={3}>El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para
                aplicar las medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los
                derechos y libertades de los USUARIOS y les ha comunicado la información adecuada para que puedan
                ejercerlos.</Text>
            <Text mb={3}>Para más información sobre las garantías de privacidad, puedes dirigirte al RESPONSABLE a
                través de
                PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>
        </Box>


    </Container>
}
