/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/12/2023
 * @description This React component represents an "Offers Detail" page. It fetches budgets and proposal details based on the provided ID. It also fetches coverages associated with the policy type and formats them accordingly. The component utilizes hooks like useState, useEffect, useRoute, useNavigation, and useTranslation. It renders a gallery of budgets with an option to accept a budget. Once a budget is accepted, it displays coverages in a table format. The coverages are formatted using the formatCoverages function, which maps coverages to budgets and renders them accordingly. The UI elements are structured within a Container component, with conditional rendering based on the state of the accepted budget.
 */
import Container from "../../components/Container/Container";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {useNavigation, useRoute} from "@react-navigation/native";
import {goToUtil} from "../../utils/goTo.util";
import {useTranslation} from "react-i18next";
import BudgetsGallery from "../../components/BudgetsGallery/BudgetsGallery";
import Toast from "react-native-toast-message";
import {Box, Column, Link, Text} from "native-base";
import {globalStyles} from "../../consts/globalStyles.const";
import Table from "../../components/Table/Table";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {theme} from "../../styles/theme";

export default function OffersDetailPage() {
    const [budgets, setBudgets] = useState([])
    const {params: {id}} = useRoute();
    const navigation = useNavigation();
    const {t} = useTranslation()
    const [proposal, setProposal] = useState({})
    const [formattedData, setFormattedData] = useState([]);


    const getBudgetsDetail = async (localBudgets) => {
        const newBudgets = []
        for (const localBudget of localBudgets) {
            try {
                const {data} = await API(`policy/company/view/${localBudget.companyId}`);
                newBudgets.push({...localBudget, company: data})
            } catch (e) {
                console.error(e)
            }
        }
        return newBudgets
    }
    const onAcceptBudget = async (budget) => {
        try {
            await API.post(`policy/proposal/accept/${id}/${budget.id}`);
            Toast.show({
                type: 'info',
                text1: t("Budget selected"),
            });
            goToUtil("offers-hire", navigation, {id})
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        async function getBudgets() {
            try {
                const {data} = await API(`policy/budget/list/${id}?limit=100&offset=0&filter[isInOffer]=1&extraFields=budgetCoverages,budgetCoverages.coverage`);
                return await getBudgetsDetail(data)
            } catch (e) {
                console.log(e)
            }
        }

        const getCoverages = async (policyTypeId, budgets) => {
            try {
                const {data: {data: coverages}} = await API("policy/policy-type/coverage/list/" + policyTypeId);
                setFormattedData(formatCoverages(coverages, budgets))
            } catch (e) {
                console.log(e)
            }
        }


        const init = async () => {
            const {data: proposal} = await getProposal(id);
            const newBudgets = await getBudgets();
            await getCoverages(proposal.policyTypeId, newBudgets)
            setBudgets(newBudgets)
            setProposal(proposal)
        }
        init()
    }, [])
    useEffect(() => {

    }, []);
    const getProposal = async (id) => {
        return API("policy/proposal/view/" + id)
    }

    const headerTemplate = (budget) => {
        return <Column p={6} alignItems={"center"}>
            <Text style={{
                color: theme.colors.secondary["700"],
                textAlign: "center",
                fontWeight: 600,
            }}>{t(budget.tag).toUpperCase()}</Text>
            <Link href={process.env.REACT_APP_IMAGE_URL + budget.fileLocation} isExternal={true}
                  textAlign={"center"}>{t("See detail")}</Link>
        </Column>
    }
    return <Container>


        <Box disabled style={proposal.acceptedBudgetId ? globalStyles.disabled : {}}>
            <BudgetsGallery data={budgets} onPress={onAcceptBudget} acceptedBudgetId={proposal.acceptedBudgetId}/>
        </Box>

        <Box mt={9}>{formattedData.length === 0 ? <Text mb={3} fontSize={14}>{t("No coverages")}</Text> : <>
            <Text mb={3} fontSize={14}>{t("Coverages")}</Text>

            <Table theme={"light"} data={formattedData}
                   dataHeader={["", ...budgets.map(budget => headerTemplate(budget))]}></Table></>
        }  </Box>
    </Container>
}
export const formatCoverages = (coverages, budgets) => {
    const formattedCoverages = []
    for (const coverage of coverages) {
        const newRow = [coverage.name];
        for (const budget of budgets) {
            if (budget.budgetCoverages) {
                const foundBudgetCoverage = budget.budgetCoverages.find(budgetCoverage => budgetCoverage.coverageId === coverage.id)
                newRow.push(<MaterialCommunityIcons style={{textAlign: "center"}}
                                                    name={!!foundBudgetCoverage ? "check" : "close"}
                                                    color={theme.colors.gray["400"]}
                                                    size={18}/>)

            } else {
                newRow.push(<MaterialCommunityIcons style={{textAlign: "center"}} name="close"
                                                    color={theme.colors.gray["400"]}
                                                    size={18}/>)

            }
        }
        formattedCoverages.push(newRow);

    }
    return formattedCoverages;
}
