import React from 'react';
import { StyleSheet } from 'react-native';
import { Text, Linking, TouchableOpacity } from 'react-native';

export const call = (telephone, ext = "+34") => {
    const phoneNumber = ext + telephone;
    if (window.isNativeApp) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'call', phoneNumber: `${phoneNumber}` })); 
    } else {
        webCall(phoneNumber);
    }
};

const webCall = (phoneNumber) => {
    Linking.openURL(`tel:${phoneNumber}`);
};

export const styles = StyleSheet.create({
    link: {
      color: '#007BFF',
      textDecorationLine: 'underline',
    },
});

const TelephoneLink = ({ telephone, ext = "+34", children }) => (
  <TouchableOpacity onPress={() => call(telephone, ext)}>
    {children ? children : <Text style={styles.link}>{telephone}</Text>}
  </TouchableOpacity>
);

export default TelephoneLink;
