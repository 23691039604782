const baseStyle = {
  multiline: true,
  p: '2',
  textAlignVertical: 'top',
  h: '20',
};

export default {
  baseStyle,
  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
};
