/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 08/09/2023
 * @description This `FilePicker` component allows users to select a file, ensuring it doesn't exceed 5MB. It supports file selection through a pressable area, displaying the chosen file's name or prompting the user to add a policy document. The file's size is validated using `isLessThanTheMB`, and the content is converted to a base64 string for iOS and Android. An error toast appears if the file exceeds the size limit. The component also provides a feature to remove the selected file, updating the UI and clearing the file name. It uses `useState` for local state management and `useTranslation` for text localization, with conditional styling for error indication.
 */
import {Pressable, Row, Text} from "native-base";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from 'expo-file-system';

import {Platform, StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {useTranslation} from "react-i18next";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {useState} from "react";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import Toast from "react-native-toast-message";

export const isLessThanTheMB = (fileSize, smallerThanSizeMB) => {
    return fileSize / 1024 / 1024 < smallerThanSizeMB
}
export default function FilePicker({value, onChange, isInvalid, acceptedFileTypes}) {
    const {t} = useTranslation();
    const [fileName, setFileName] = useState();

    const pickFile = async () => {
        let file = await DocumentPicker.getDocumentAsync({type: acceptedFileTypes},);
        const isOk = isLessThanTheMB(file.output[0].size, 5)
        if (isOk) {
            let fileBase64 = (Platform.OS === "ios" || Platform.OS === "android") ? "data:" + acceptedFileTypes + ";base64," + (await FileSystem.readAsStringAsync(file.assets[0].uri, {encoding: "base64"})) : file.assets[0].uri;

            onChange(fileBase64)
            setFileName(file.output[0].name)
        } else {
            Toast.show({
                type: 'error',
                text1: t("Max size exceeded"),
                text2: t("File need to be maximum 5MB")
            });
        }

    };

    const removeFile = () => {
        onChange(null)
        setFileName(null)
    }

    return <Pressable style={isInvalid ? {...styles.container, ...styles.containerError} : styles.container}
                      onPress={pickFile}>
        <MaterialCommunityIcons name="file-document-outline" color={theme.colors.gray["100"]} fontWeight="300"
                                size={30}/>
        {!value && <>
            <Text mt={4} fontSize={16} fontWeight={500}>{t("Add the policy")}</Text>
            <Text color={theme.colors.gray["200"]}>{t("Attach your contracts")}</Text>
        </>}
        {value && <Row mt={4} alignItems={"center"}>
            <Text mr="2" fontSize={16} fontWeight={500}>{fileName ? fileName : t("Policy") + ".pdf"}</Text>
            <MaterialIcons name="cancel" color={theme.colors.gray["100"]} fontWeight="300" onPress={removeFile}
                           size={24}/>
        </Row>}
    </Pressable>
}


const styles = StyleSheet.create({
        container: {
            width: "100%",
            height: 200,
            backgroundColor: "white",
            borderWidth: 3,
            borderColor: theme.colors.gray["100"],
            fontSize: 12,
            borderRadius: 6,
            borderStyle: 'dashed',
            color: theme.colors.gray["700"],
            fontFamily: "Roboto-Light",
            alignItems: "center",
            justifyContent: "center",
            padding: 12
        },
        containerError: {
            borderColor: theme.colors.error["700"],

        }

    }
)
