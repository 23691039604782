/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/12/2023
 * @description The `OffersGallery` component displays a gallery of insurance offers, dynamically adjusting its layout between desktop and mobile views based on screen width. Each offer is presented in a pressable box, with specific styles applied to offers based on their status (`USER_PENDING` or `ACCEPTED`). The gallery supports interactive selection, triggering a provided `onPress` function with the selected offer's details.
 *
 * - **Dynamic Layout:** The number of columns and the size of each offer box adjust based on whether the device is in desktop or mobile view, ensuring a responsive design.
 * - **Conditional Rendering:** Offers are only interactive and styled differently if their status is `USER_PENDING` or `ACCEPTED`. Offers in preparation are displayed with a different layout and are not interactive.
 * - **Custom Badges:** Offers marked as new display a badge, enhancing visual feedback for the user.
 * - **Flexible Content:** The component displays offer details, including a custom icon for the policy type and pricing information, formatted dynamically based on the offer's status.
 *
 * This component is designed for a responsive web environment, catering to a wide range of devices while providing a user-friendly interface for exploring and interacting with insurance offers.
 */
import {Box, Column, Flex, Pressable, Row, Text, useBreakpointValue, useMediaQuery} from "native-base";
import {StyleSheet} from 'react-native';
import {breakpoints, theme} from "../../styles/theme";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomIcons} from "../../App";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

const gap = 15;
export default function OffersGallery({data, onPress}) {
    const [individualSize, setIndividualSize] = useState()
    const minWidth = useBreakpointValue({
        md: breakpoints.md
    });
    const [isDesktop] = useMediaQuery({
        minWidth
    });
    const {t} = useTranslation();

    const numColumns = (isDesktop ? 3 : 1)
    return <Box
        onLayout={(event) => setIndividualSize(event.nativeEvent.layout.width / numColumns - gap + (gap / numColumns))}
        style={styles.container}>
        {individualSize && data && data.map((item) => <Column key={item.id}
        >
            <Pressable disabled={item.status !== "USER_PENDING" && item.status !== "ACCEPTED"} onPress={ () => onPress(item)}
                       width={individualSize}
                       style={item.policies ? {...styles.box, ...styles.boxWithSub} : styles.box}>
                <Column w={"100%"} justifyContent={["center", "center"]} h={[120, 200]}>
                    {(item.status === "USER_PENDING" || item.status === "ACCEPTED") &&
                        <Box style={isDesktop ? {
                            ...styles.newBadge, left: "50%",
                            transform: "translateX(-50%)"
                        } : styles.newBadge}><Text
                            color={theme.colors.gray["0"]}>{t("New insurance")}</Text></Box>}

                    <Flex gap={3}  alignItems={"center"} flexDirection={["row", "column"]} w={"100%"} >
                        {item.policyType.logoLibrary && (
                            <CustomIcons
                                name={item.policyType.logo}
                                color={item.status !== "USER_PENDING" ? theme.colors.gray["100"] : theme.colors.primary["700"]}
                                size={isDesktop ? individualSize / 6 : individualSize / 8}
                            />
                        )}
                        {(item.status === "USER_PENDING" || item.status === "ACCEPTED") && <Column space={3} alignItems={["flex-start", "center"]}>
                            <Text fontSize={18}
                                  fontWeight={"600"}
                                  color={"secondary.700"}>{item.policyType.name}</Text>
                            <Row alignItems={"end"} space={3}>
                                <Text fontSize={14}
                                      color={theme.colors.gray["200"]}>{t("from")}</Text>
                                <Text
                                    fontSize={18}
                                    fontWeight={600}
                                    color={"secondary.700"}>{item.minBudgetPrice} € / {t("ANNUAL")}</Text>
                            </Row>
                            {/*<Text fontSize={14}*/}
                            {/*      color={theme.colors.gray["200"]}>{t("Valid to")} {item.policyType.name}</Text>*/}
                        </Column>}
                        {(item.status !== "USER_PENDING" && item.status !== "ACCEPTED") &&
                            <Column space={3} flex={1} alignItems={["flex-start", "center"]}>
                                <Text fontSize={18}
                                      fontWeight={"600"}
                                      color={"secondary.700"}>{item.policyType.name}</Text>
                                <Row alignItems={"center"} space={3}>
                                    <MaterialCommunityIcons name="clock-time-four-outline"
                                                            color={theme.colors.gray["100"]} size={20}/>

                                    <Text
                                        fontSize={16}
                                        fontWeight={400}
                                        color={"gray.100"}>{t("Preparing your proposal")}</Text>
                                </Row>
                            </Column>}
                    </Flex></Column>
            </Pressable>

        </Column>)}
    </Box>
}

const styles = StyleSheet.create({
    container: {
        flexWrap: "wrap",
        gap,
        flexDirection: "row",
        width: "100%",
    },
    box: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 6,
        textAlign: 'center',
        alignItems: "flex-start",
        fontWeight: 'bold',
        marginTop: 10
    },
    boxWithSub: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomWidth: 0
    },
    subBox: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 0,
        borderBottomWidth: 0,
        textAlign: 'center',
        alignItems: "center",
        fontWeight: 'bold',
    },
    subBoxLast: {
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        borderBottomWidth: 1,

    },
    selectedBox: {
        borderColor: theme.colors.secondary["500"],
        backgroundColor: theme.colors.secondary["50"],
    },
    image: {
        width: "30%",
        height: "85%"
    },
    newBadge: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: theme.colors.primary["700"],
        borderRadius: 20,
        position: "absolute",
        top: -20,

        minWidth: 114,
        display: "block"
    }
});
