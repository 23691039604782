/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description This React component represents a "My Profile" page. It uses hooks like useTranslation, useContext, and useNavigation for translation, global state management, and navigation respectively. The component renders a form with inputs for the user's contact and personal data. The form inputs are managed using useForm hook, with validation rules applied. The onSubmit function handles form submission by making an API call to update user data and then navigating to the "Policies" page. The layout is structured within a Container component, with conditional rendering based on the saveType state. The "Save" button triggers form submission when pressed.
 */
import { Button, Heading, VStack } from "native-base";
import Container from "../../components/Container/Container";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import FormInput from "../../components/FormInput/FormInput";
import { updateUserUtil } from "../../utils/updateUser.util";
import { API } from "../../queries/api";
import { GlobalContext } from "../../consts/globalContext.const";
import { gendersConst } from "../../consts/genders.const";
import { formErrorValidationsConst } from "../../consts/formErrorValidations.const";
import { useNavigation } from "@react-navigation/native";
import {goToUtil} from "../../utils/goTo.util";

export default function MyProfilePage () {
    const { t } = useTranslation();
    const { user, setUser } = useContext(GlobalContext)
    const navigation = useNavigation();

    const [saveType, setSaveType] = useState("basic"); //basic is initial form. email is to show form to confirm
    // email.
    // mobile is to show form to confirm mobile
    const { control, handleSubmit, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            ...user,
            dateBirth: new Date(user.dateBirth)
        }
    });

    const onSubmit = async (data) => {
        await API.put("user/user/data/update", data); //this endpoint is returning nothing so I need to update with
        // local data
        await updateUserUtil(data, setUser)
        goToUtil("policies", navigation)
    };

    return <Container>
        {saveType === "basic" && <>
            <Heading mb="5" fontSize={"md"}>{t("Contact data")}</Heading>

            <VStack space={3}>
                <FormInput disabled control={control} errors={errors} label="Email" name={"email"}
                           rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="Phone" name={"mobile"} rules={{ required: true }}/>
            </VStack>

            <Heading mt="8" mb="5" fontSize={"md"}>{t("Personal data")}</Heading>

            <VStack space={3}>
                <FormInput control={control} errors={errors} label="Name" name={"name"} rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="First surname" name={"firstSurname"}
                           rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="Second surname" name={"secondSurname"}
                           rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="DNI / NIE" name={"nif"} rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="Birth date" name={"dateBirth"}
                           rules={{ required: true }} type={"calendar"}/>
                <FormInput control={control} errors={errors} label="Postal code" name={"postalCode"}
                           rules={{ required: true }}/>
                <FormInput control={control} errors={errors} label="Gender" name={"gender"} rules={{
                    required: true,
                    maxLength: formErrorValidationsConst.postalCode,
                    minLength: formErrorValidationsConst.postalCode
                }} type={"select"} options={gendersConst(t)}/>
            </VStack>
        </>}

        <Button mt="10" onPress={handleSubmit(onSubmit)}>
            {t("Save")}
        </Button>

    </Container>
}
