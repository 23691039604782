/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/07/2023
 * @descriptionThe This `App` component serves as the entry point for the application. It initializes fonts, manages user authentication state, and sets up navigation using React Navigation.
 *
 * Here's a breakdown of its functionality:
 *
 * 1. Imports necessary modules and assets.
 * 2. Initializes fonts using the `useFonts` hook from `expo-font`.
 * 3. Manages local state using `useState` hooks for user data, authentication status, loading state, advisor data, and offer body.
 * 4. Uses `useEffect` hook to initialize local data from AsyncStorage when the component mounts.
 * 5. Renders a loading screen if fonts are not loaded.
 * 6. Provides global state using the `GlobalContext.Provider` to pass down user data, authentication status, loading state, advisor data, and offer body to all components in the application.
 * 7. Renders the `NavigationContainer` from React Navigation with custom linking configuration.
 * 8. Wraps the navigation components with `NativeBaseProvider` from NativeBase to apply a custom theme.
 * 9. Renders the `Routes` component, which defines the navigation routes.
 * 10. Renders the `CompleteProfileMessage` component.
 * 11. Renders the `Toast` component for displaying notifications.
 *
 * Overall, this component sets up the environment for the application, including fonts, navigation, and global state management.
 */
import "./i18n"
import {NavigationContainer} from "@react-navigation/native";
import {NativeBaseProvider} from 'native-base';
import Routes from "./pages/Routes";
import {useEffect, useState} from "react";
import {theme} from "./styles/theme";
import {GlobalContext} from "./consts/globalContext.const";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useFonts} from "expo-font";
import Toast from 'react-native-toast-message';
import * as Notifications from 'expo-notifications';
import {createIconSetFromIcoMoon} from '@expo/vector-icons';
import CompleteProfileMessage from "./components/CompleteProfileMessage/CompleteProfileMessage";

export const CustomIcons = createIconSetFromIcoMoon(
    require('./assets/icomoon/selection.json'),
    'IcoMoon',
    'icomoon.ttf'
);
// this enable creation and customization of routes
const config = {
    screens: {
        profile: 'profile',
        policies: "policies",
        "policies-detail": "policies/:id",
        "policies-edit": "policies/:id/edit",
        "offers-detail": "offers/:id",
        "offers-hire": "offers-hire/:id",
        "chat-bot": "chat-bot/:type"

    },
};

const linking = {
    config,
};
Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

export default function App() {
    const [fontsLoaded] = useFonts({
        'Roboto-Light': require('./assets/fonts/Roboto/Roboto-Light.ttf'),
        'Roboto-Medium': require('./assets/fonts/Roboto/Roboto-Medium.ttf'),
        'Roboto-Bold': require('./assets/fonts/Roboto/Roboto-Bold.ttf'),
        'RobotoSlab': require('./assets/fonts/RobotoSlab/RobotoSlab.ttf'),
        "IcoMoon": require('./assets/icomoon/fonts/icomoon.ttf'),
    });

    const [user, setUser] = useState({});
    const [advisor, setAdvisor] = useState();
    const [offersBody, setOffersBody] = useState({});
    const [isLogged, setIsLogged] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadContent, setLoadContent] = useState(false); //is necessary to double verification token steps

    useEffect(() => {
        const initLocalData = async () => {
            setIsLogged(!!await AsyncStorage.getItem('token'))
            setUser(JSON.parse(await AsyncStorage.getItem('user')))
            setAdvisor(JSON.parse(await AsyncStorage.getItem('advisor')))
        }
        initLocalData()

        console.log("REACT_APP_BACK_URL=" + process.env.REACT_APP_BACK_URL)
        console.log("REACT_APP_IMAGE_URL=" + process.env.REACT_APP_IMAGE_URL)

        return () => {
            // Notifications.removeNotificationSubscription(notificationListener.current);
            // Notifications.removeNotificationSubscription(responseListener.current);
        };


    }, []);


    if (!fontsLoaded) {
        return null;
    }


    return (
        // <GlobalContext.Provider value={{ user, setUser, isLogged, setIsLogged, loadContent, setLoadContent, notification, setNotification }}>
        <GlobalContext.Provider value={{
            user,
            setUser,
            isLogged,
            setIsLogged,
            loadContent,
            setLoadContent,
            isLoading,
            setIsLoading,
            advisor,
            setAdvisor,
            offersBody,
            setOffersBody
        }}>
            <NavigationContainer linking={linking}>
                <NativeBaseProvider theme={theme}>
                    <Routes/>
                    <CompleteProfileMessage/>
                </NativeBaseProvider>
            </NavigationContainer>
            <Toast/>


        </GlobalContext.Provider>
    );
}

