/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/12/2023
 * @description The `Widget` component is designed to notify logged-in users about pending offers by displaying a clickable widget. It checks if the user is logged in and if there's any incomplete action indicated by a percentage not being 100%. On pressing the widget, users are navigated to a specific page, typically the 'offers' page, to view more details about the pending offers. The widget visually stands out with an icon, a title saying "Offers pending," and a brief message "We are preparing your offers," encouraging users to take action. A notification bubble displays the count of pending offers, enhancing the urgency and importance of checking them. This component enhances user engagement by providing a clear and immediate call to action for important user-related information.
 */
import {Box, Column, Pressable, Row, Text} from "native-base";
import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {GlobalContext} from "../../consts/globalContext.const";
import {useNavigation} from "@react-navigation/native";
import {goToUtil} from "../../utils/goTo.util";
import {theme} from "../../styles/theme";
import {StyleSheet} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

export default function Widget() {
    const {t} = useTranslation()
    const {isLogged, offersBody} = useContext(GlobalContext);
    const [percentage, setPercentage] = useState()
    const navigation = useNavigation();


    return isLogged && percentage !== 100 ?
        <Pressable onPress={() => goToUtil('offers', navigation)}>
            <Row alignItems="center" style={styles.container} space={3} justifyContent={"space-between"}>
                <Row alignItems={"center"}>
                    <MaterialCommunityIcons name="tag-multiple" color={theme.colors.secondary["500"]} size={32}/>

                    <Column ml={3}>
                        <Text fontSize={16} color={theme.colors.secondary["700"]}
                              fontWeight={"500"}>{t("Offers pending")}</Text>
                        <Text>{t("We are preparing your offers")}</Text>
                    </Column>
                </Row>

                <Box>
                    <Box style={styles.bubble}><Text color={theme.colors.gray["0"]}>{offersBody.count}</Text></Box>

                </Box>
            </Row>
        </Pressable> : <></>
}
const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        borderRadius: 6,
        textAlign: "center",
        alignItems: "center",
        fontWeight: "bold",
        width: "100%",
        padding: 16
    },

    bubble: {
        backgroundColor: theme.colors.secondary["500"],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        width: 25,
        height: 25,
        borderRadius: 50,
    }
});
