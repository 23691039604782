/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/08/2023
 * @description The `VehicleBrandSearch` component facilitates the search and selection of vehicle brands and models based on user input. It interacts with an external API to fetch matching results as the user types. The component supports both brand and model searches, dynamically adjusting the query based on the `typeOfVehicle` (e.g., "car" or "motorbike") and the specific search type (brand or model). The search results are displayed to the user through the `CommonSearch` component, which handles the display of the search field, list of results, and selection logic. When a user selects an item from the list, the component updates the parent context (`ChatContext`) with the selected item's details and clears the current search. This component is designed to be reusable and adaptable for various vehicle types, enhancing user experience in scenarios requiring detailed vehicle information.
 */
import {useContext, useState} from "react";
import {API} from "../../queries/api";
import CommonSearch from "../CommonSearch/CommonSearch";
import {ChatContext} from "../ChatBot/chatContext";
import {createShowingText} from "../InlineSearch/InlineSearch";

export default function VehicleBrandSearch({type, onChange, readOnly, disabled, typeOfVehicle,keys = ["name"]}) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);
    const {vehicle, setVehicle} = useContext(ChatContext);

    const onSearch = async (targetValue) => {
        setShowingText(targetValue)
        let newData;
        if (targetValue !== "") {
            if (type === typeOfVehicle + "_brand") {
                const {data} = await API(
                    `data/${typeOfVehicle}/brand/list?search=` + targetValue
                );
                newData = data;
            } else if (type === typeOfVehicle + "_model") {
                const {data} = await API(
                    `data/${typeOfVehicle}/model/list/${vehicle[typeOfVehicle + "_brand"]}?search=` + targetValue
                );
                newData = data;
            }

            setShowingList(newData)
        } else {
            setShowingList([])
        }

    }

    const selectItem = (item) => {
        onChange(item);
        setVehicle({...vehicle, [type]: item.id})
        setShowingText(createShowingText(item, keys))
        setShowingList([])
    }

    return <CommonSearch showingList={showingList} onSearch={onSearch} showingText={showingText} selectItem={selectItem}
                         disabled={disabled} readOnly={readOnly}/>

}
