/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/10/2023
 * @description The `MenuMobile` component displays a mobile-friendly bottom navigation menu derived from a global menu list. Each menu item is conditionally rendered based on its visibility on mobile devices and includes an icon, label, and optional notification bubble for specific items (like offers). It utilizes the `useNavigation` and `useTranslation` hooks for navigation and internationalization, respectively. The component also checks the current route to highlight the active menu item. A notification bubble displays the count of user pending offers, adding dynamic content to the menu. The layout is designed to be fixed at the bottom of the screen, with styling that ensures it's visually separated from the main content area.
 */
import { Box } from "native-base";
import { StyleSheet } from "react-native";
import { theme } from "../../styles/theme";

export default function Modal ({ children }) {

    return <Box style={styles.container} bottom={[60, 60, 5]} right={[3, 3, 5]}>
        {children}
    </Box>
}


const styles = StyleSheet.create({
        container: {
            backgroundColor: theme.colors.gray["0"],
            borderRadius: 6,
            position: "fixed",
            padding: 20,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: .1,
            shadowRadius: 3,
            elevation: 5,
        },
    }
)
