/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description The `HireInsurancePage` component allows users to select insurance policies for hiring. It retrieves policy types from the server and renders them in a gallery using the `InsuranceGallery` component. Upon selection, users can create a proposal for the chosen policy type, which triggers navigation to the chat-bot screen with relevant parameters. The `useEffect` hook is utilized to fetch policy types from the server when the component mounts. The `useState` hook manages the state of policy types, and `useNavigation` is used for navigation within the app. Additionally, `AsyncStorage` is employed to store data related to the selected proposal and policy type. The `useTranslation` hook facilitates language translation.
 */
import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import { API } from "../../queries/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { GlobalContext } from "../../consts/globalContext.const";
import InsuranceGallery from "../../components/InsuranceGallery/InsuranceGallery";
import { Heading } from "native-base";
import { useTranslation } from "react-i18next";
import {goToUtil} from "../../utils/goTo.util";

export default function HireInsurancePage () {
    const [policyTypes, setPolicyTypes] = useState()
    const navigation = useNavigation();
    const { user } = useContext(GlobalContext)

    const { t } = useTranslation();

    const createProposal = async ({ id: policyTypeId }) => {
        const { data: { id } } = await API.post("policy/proposal/create", { policyTypeId, userId: user.id })
        await AsyncStorage.setItem("actualProposalId", id.toString())
        await AsyncStorage.setItem("actualPolicyTypeId", policyTypeId.toString())

        // in this case we need to navigate and reset. We need to exe initial useEffect
        goToUtil("chat-bot", navigation,{ type: "proposal" }  )

    }


    useEffect(() => {
        async function getPolicyTypes () {
            try {
                const { data } = await API("policy/policy-type/list?filter[visible]=1");
                setPolicyTypes(data)
            } catch (e) {
                console.log(e)
            }
        }

        getPolicyTypes();
    }, [])
    return <Container>
        <Heading mb={6}>{t("What insurance do you want to hire?")}</Heading>

        {policyTypes && <InsuranceGallery data={policyTypes} onPress={createProposal}/>}
    </Container>

}
