/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description This React component, `ChatBotList`, renders a list of messages within a chatbot interface. It iterates through the `chat` array prop, displaying each message with styling based on the sender (`bot` or `client`). Bot messages are aligned to the start and styled differently to distinguish them from user messages, which are aligned to the end. Error messages from the bot have a distinct background color for better visibility. The component uses the `useTranslation` hook to support internationalization, allowing message texts to be translated based on the current locale. The dynamic rendering of message fields with emphasized font weight enhances the chat's interactivity and readability.
 */
import { Box, Text, View } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";

export default function ChatBotList ({ chat }) {
    const { t } = useTranslation()

    return <Box style={styles.container}>
        {chat.map((message, index) => <View   key={index}
            style={message.user === "bot" ? [styles.message, styles.botMessage, message.type === "error" ? {backgroundColor: theme.colors.error["200"]} : {} ] : [styles.message]}><Text
          >{t(message.text)}{message.field ? <Text fontWeight={"800"}> {message.field}</Text> : ""}</Text></View>)}
    </Box>
}


const styles = StyleSheet.create({
    container: {},
    message: {
        marginTop: 14,
        padding: 16,
        backgroundColor: theme.colors.chat["human"],
        borderRadius: 6,
        alignSelf: 'flex-end'
    },
    botMessage: {
        alignSelf: 'flex-start',
        backgroundColor: theme.colors.chat["bot"],

    }
});

