export const chatBotSentences = (key) => {
    const selectedSentences = sentences[key] ? sentences[key] : sentences["error-required"]
    return selectedSentences[Math.floor(Math.random() * (selectedSentences.length - 1))]
}

const sentences = {
    hi: ["Hello! looks like you want to hire an insurance. Complete information I will request you. Simply and fast!"],
    first: ["First all I need..."],
    next: ["Well done! Next information I would need is...", "Perfect! Keep going with", "This looks so good. Please" +
    " fill" +
    " next field too", "Slowly and carefully...well done! Neeext!"],
    "first-section": ["First all I will need data from"],
    "new-section": ["Next information will be about"],
    empty: ["You leave field empty. No problem, this is not mandatory. Keep going with"],
    "error-validation": ["The information you filled have a wrong format. Please change it"],
    "error-required-year": ["This fill is required. You need select a valid date"],
    "error-required-address": ["This fill is required. You need to search a real address and select one from the provided list"],
    "error-required-boolean": ["This fill is required. You need to check it"],
    "error-required-selector": ["This fill is required. You need to select one option"],
    "error-required-numeric": ["This fill is required. You need to insert a number"],
    "error-required-multiselector": ["This fill is required. You need select at least one option"],
    "error-required": ["This fill is required. You need to fill it"],
    "no-answer":["I prefer not to answer"]
}
