/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/07/2023
 * @description This React component, `Container`, serves as a layout wrapper that adapts its structure based on whether the device is desktop-sized or mobile, using responsive design principles. It utilizes the `useNavigation` and `useTranslation` hooks from React Navigation and i18next, respectively, for navigation and internationalization. The component conditionally renders different layouts for desktop and mobile:
 *
 * - **Desktop Layout**: Features a two or three-column layout depending on the user's logged-in status. The first column is reserved for a menu (`Menu` component), the middle for the main content (`children`), and if logged in, the last column displays an advisor section (`Advisor` component). The desktop layout is activated based on a minimum width breakpoint.
 * - **Mobile Layout**: Presents a single-column view with the main content and a mobile menu (`MenuMobile` component) at the bottom if the user is logged in. It's designed for screens narrower than the specified breakpoint.
 *
 * The component also fetches and sets offer data for logged-in users through `getOffers` function and stores it in global state (`offersBody`), ensuring offers are fetched only once when the app is loaded and the user is logged in.
 *
 * `useLayoutEffect` is used to hide or show the navigation tab bar based on the screen width, providing a more app-like navigation experience on desktop screens by hiding the bottom tab bar.
 *
 * The layout adjustments and data fetching logic are wrapped in `useEffect` hooks to properly manage side effects based on the component's lifecycle.
 *
 * `getOffers` is an asynchronous function that fetches offers data from an API, processes it to count offers pending user action, and returns the processed data along with the count of user-pending offers.
 *
 * This structure allows for a responsive and dynamic user experience that adjusts content layout and availability based on the user's device and login status, while also managing global data like offers within the app's context.
 */
import {Box, Row, ScrollView, Text, useBreakpointValue, useMediaQuery, View} from "native-base";
import Menu from "../Menu/Menu";
import {Dimensions, SafeAreaView} from 'react-native';
import {useContext, useEffect, useLayoutEffect} from "react";
import {useNavigation} from "@react-navigation/native";
import {breakpoints} from "../../styles/theme";
import Advisor from "../Advisor/Advisor";
import {GlobalContext} from "../../consts/globalContext.const";
import {useTranslation} from "react-i18next";
import MenuMobile from "../MenuMobile/MenuMobile";
import {API} from "../../queries/api";
import Loading from "../Loading/Loading";

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
export const menuWidth = 240;
export const menuHeight = 50;
export const headerHeight = 70;
export default function Container({children}) {
    const navigation = useNavigation();
    const {t} = useTranslation();

    const {advisor, isLogged, offersBody, setOffersBody} = useContext(GlobalContext)
    const minWidth = useBreakpointValue({
        md: breakpoints.md
    });
    const [isDesktop] = useMediaQuery({
        minWidth
    });

    useEffect(() => {

        const doGetOffers = async () => {
            setOffersBody(await getOffers())
        }

        if (isLogged && Object.keys(offersBody).length === 0) {
            doGetOffers();
        }
    }, []);
    useLayoutEffect(() => {
        if (isDesktop) {
            navigation.setOptions({
                tabBarStyle: {display: 'none'},
            });
        } else {
            navigation.setOptions({
                tabBarStyle: {display: 'flex'},
            });
        }

    }, [navigation])

    return <>
        {screenHeight && screenWidth && menuHeight && headerHeight && menuWidth && <>
            {isDesktop && <SafeAreaView style={{height: screenHeight - headerHeight}}>
                <Row h={"100%"} w={"100%"}>
                    <Box w={menuWidth + "px"} backgroundColor="bg.500" style={{
                        flex: 1,
                        flexDirection: "column", height: "100%",
                        shadowOffset: {width: 0, height: 0},
                        shadowOpacity: .1,
                        shadowRadius: 3,
                        elevation: 5,
                    }}>
                        <Menu/>
                    </Box>

                    <View width={screenWidth - (menuWidth * (isLogged ? 2 : 1)) - 40} height={"100%"}>
                        <ScrollView>
                            <Box p={10}>
                                {/*<View style={{flex:1}}>*/}
                                {children}
                                <Loading/>

                                {/*</View>*/}
                            </Box>
                        </ScrollView>
                    </View>
                    {isLogged && <><Box w={menuWidth + "px"} backgroundColor="bg.500" style={{
                        padding: 16,
                        flex: 1,
                        flexDirection: "column", height: "100%",
                        shadowOffset: {width: 0, height: 0},
                        shadowOpacity: .1,
                        shadowRadius: 3,
                        elevation: 5,
                    }}>

                        {advisor && <Advisor data={advisor}/>}
                        {!advisor && <Text>{t("No advisor")}</Text>}

                    </Box></>}
                </Row>

            </SafeAreaView>
            }

            {!isDesktop && <>
                <SafeAreaView width={"100%"}>
                    <ScrollView style={{height: isLogged ? screenHeight - menuHeight - headerHeight : "100%"}}>
                        <Box py={5} pr={5} pl={5}>
                            {/*<View style={{flex:1}}>*/}
                            {children}
                            <Loading/>

                            {/*</View>*/}
                        </Box>
                    </ScrollView>
                </SafeAreaView>
                {isLogged && <MenuMobile/>}
            </>}

        </>}
    </>
}


export const getOffers = async () => {
    try {
        const {data} = await API("policy/proposal/list?limit=50&offset=0&extraFields=policyType,budgets");
        const countUserPendingOffers = data.data.reduce((sum, offer) => offer.status === "USER_PENDING" ? sum + 1 : sum
            , 0)
        return {...data, countUserPendingOffers}
    } catch (e) {
        console.error(e)
    }
}
