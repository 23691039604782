import {Pressable, Row, Text, Image, Box, ScrollView} from "native-base";
import * as ImagePickerLib from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import {Platform, StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {useTranslation} from "react-i18next";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {useState} from "react";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import Toast from "react-native-toast-message";
import jsPDF from "jspdf";

const convertUriToBase64 = async (uri) => {
    if (Platform.OS === "web") {
        const response = await fetch(uri);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(",")[1]); // Obtener solo base64
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } else {
        return await FileSystem.readAsStringAsync(uri, {encoding: "base64"});
    }
};

export default function ImagePicker({ value, onChange, isInvalid, hasImages }) {
    const {t} = useTranslation();
    const [images, setImages] = useState([]);
    if (hasImages) hasImages(images.length > 0);
    const [fileName, setFileName] = useState();

    const pickImages = async () => {
        const {status} = await ImagePickerLib.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
            Toast.show({
                type: 'error',
                text1: t("Permission denied"),
                text2: t("You need to grant permission to select images"),
            });
            return;
        }
    
        const result = await ImagePickerLib.launchImageLibraryAsync({
            mediaTypes: ImagePickerLib.MediaTypeOptions.Images,
            allowsMultipleSelection: true,
            quality: 1,
        });
    
        if (!result.canceled) {
            const newImages = result.assets.map((asset) => ({
                uri: asset.uri,
                id: `${asset.uri}_${Date.now()}`,
            }));
            setImages((prev) => {
                const updatedImages = [...prev, ...newImages];
                if (hasImages) hasImages(updatedImages.length > 0);
                updatePDF(updatedImages);
                return updatedImages;
            });
        }
    };
    
    const removeImage = (id) => {
        setImages((prev) => {
            const updatedImages = prev.filter((image) => image.id !== id);
            if (hasImages) hasImages(updatedImages.length > 0);
            updatePDF(updatedImages);
            return updatedImages;
        });
    };
    
    const generatePDF = async (imageArray = images) => {
        try {
            const pdf = new jsPDF();
            for (let i = 0; i < imageArray.length; i++) {
                const base64 = await convertUriToBase64(imageArray[i].uri);
                const img = `data:image/jpeg;base64,${base64}`;
                const imgProps = pdf.getImageProperties(img);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
    
                let imgWidth = pdfWidth;
                let imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
                if (imgHeight > pdfHeight) {
                    imgHeight = pdfHeight;
                    imgWidth = (imgProps.width * pdfHeight) / imgProps.height;
                }
    
                pdf.addImage(img, "JPEG", 0, 0, imgWidth, imgHeight);
                if (i < imageArray.length - 1) pdf.addPage();
            }

            // Esto es para adaptar el base64 al esperado por el backend
            const pdfBase64 = pdf.output("datauristring").replace("data:application/pdf;filename=generated.pdf;base64,", "data:application/pdf;base64,");
    
            onChange(pdfBase64);
            setFileName("PolicyCameraResult.pdf");
        } catch (error) {
            console.error(error);
            Toast.show({
                type: 'error',
                text1: t("Error"),
                text2: t("Failed to generate policy from camera"),
            });
        }
    };
    
    const updatePDF = async (imageArray = images) => {
        if (imageArray.length > 0) {
            await generatePDF(imageArray);
        } else {
            onChange(null);
            setFileName(null);
        }
    };
    
    return (
        <>
            <Pressable
                style={isInvalid ? {...styles.container, ...styles.containerError} : styles.container}
                onPress={pickImages}
            >
                <MaterialCommunityIcons name="camera" color={theme.colors.gray["100"]} size={30}/>
                <Text mt={4} fontSize={16} fontWeight={500}>{t("Policy Camera")}</Text>
                <Text color={theme.colors.gray["200"]}>{t("Capture your contracts")}</Text>
            </Pressable>
            <ScrollView horizontal style={styles.scrollView}>
                {images.map((image) => (
                    <Box key={image.id} style={styles.imageBox}>
                        <Image source={{uri: image.uri}} alt="thumbnail" style={styles.thumbnail}/>
                        <MaterialIcons name="cancel" style={styles.removeIcon} onPress={() => removeImage(image.id)}/>
                    </Box>
                ))}
            </ScrollView>
            {/* {images.length > 0 && (
                <Pressable style={styles.generateButton} onPress={generatePDF}>
                    <Text fontSize={16} fontWeight={500} color="white">{t("Generate PDF")}</Text>
                </Pressable>
            )} */}
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: 200,
        backgroundColor: "white",
        borderWidth: 3,
        borderColor: theme.colors.gray["100"],
        borderRadius: 6,
        borderStyle: "dashed",
        alignItems: "center",
        justifyContent: "center",
        padding: 12,
    },
    containerError: {
        borderColor: theme.colors.error["700"],
    },
    scrollView: {
        marginTop: 16,
        paddingHorizontal: 8,
    },
    imageBox: {
        position: "relative",
        marginRight: 8,
    },
    thumbnail: {
        width: 100,
        height: 100 * (297 / 210), // Proporción de DIN A4
        resizeMode: "cover",
        borderRadius: 6,
    },
    removeIcon: {
        position: "absolute",
        top: 4,
        right: 4,
        color: theme.colors.error["700"],
        fontSize: 24,
    },
    generateButton: {
        marginTop: 16,
        backgroundColor: theme.colors.primary["500"],
        padding: 12,
        alignItems: "center",
        borderRadius: 6,
    },
});
