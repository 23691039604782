/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 23/08/2023
 * @description This React component, `ChatBot`, simulates a chatbot interface for collecting user inputs through a series of form fields. It manages state for the current field index, custom chat text, and the chat history. The chat history is initialized with greetings and the first question. As the user progresses, their responses and subsequent questions are dynamically added to the chat. The component supports conditional field visibility and validation based on user responses, including specific checks for driver's license validity. It uses the `useForm` hook for form control and validation, and custom logic to determine the next set of questions based on the current inputs and field dependencies. The chatbot facilitates a conversational UI for data collection, enhancing user engagement.
 */
import {Box, Button} from "native-base";
import FormInput from "../FormInput/FormInput";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {chatBotSentences} from "./chatBotSentences";
import ChatBotList from "../ChatBotList/ChatBotList";
import {formTypesConst} from "../../consts/formTypes.const";
import {ChatContext} from "./chatContext";
import {API} from "../../queries/api";
import AddressForm from "../AddressForm/AddressForm";

export default function ChatBot({
                                    onSubmit,
                                    fieldsList,
                                    setFieldsList,
                                    defaultValues,
                                }) {
    const {t} = useTranslation();
    const [fieldIndex, setFieldIndex] = useState(0);
    const [vehicle, setVehicle] = useState({});
    const [customChatText, setCustomChatText] = useState(null); // this is necessary when user is filling dropdown
    // or search cause of we have label and value. Label is not saved, so we need this to keep it and show it on chat
    const [chat, setChat] = useState([
        {
            user: "bot",
            text: chatBotSentences("hi"),
        },
        {
            user: "bot",
            text: chatBotSentences("first-section"),
            field: fieldsList[0].section,
        },
        {
            user: "bot",
            text: chatBotSentences("first"),
            field: fieldsList[0].name,
        },
    ]);
    const {control, handleSubmit, setValue, errors, watch} = useForm({
        defaultValues,
    });

    const next = (fieldIndex, isBlocked) => {
        const field = fieldsList[fieldIndex];
        const isOk = isBlocked || checkIsOk(field);
        const isOkDriverLicense =
            field.key === "driver_birthdate"
                ? checkDriverLicenseIsOk(watch("driver_licence_type") ? watch("driver_licence_type") : "B", watch(field.key))
                : true;
        if (isOkDriverLicense) {
            if (isOk) {
                // if last, send data
                if (fieldIndex === fieldsList.length - 1) {
                    handleSubmit(onSubmit)();
                } else {
                    const nextFieldIndex = whatIsNextIndex(fieldsList, fieldIndex);
                    const nextField = fieldsList[nextFieldIndex];
                    const isNextBlocked = checkBlocks(nextField, watch());
                    const userValue = watch(field.key);
                    // isNextBlocked ? insertSentences() : insertSentences()
                    insertSentences(
                        field,
                        isNextBlocked ? fieldsList[nextFieldIndex + 1] : nextField,
                        userValue,
                        isBlocked
                    );

                    if (!isNextBlocked) {
                        setCustomChatText(null);
                        setFieldIndex(nextFieldIndex);
                    } else {
                        setCustomChatText(null);
                        setFieldIndex(nextFieldIndex);
                        next(nextFieldIndex, isNextBlocked);
                    }
                }
            }
        } else {
            setChat([
                ...chat,
                {
                    user: "bot",
                    type: "error",
                    text:
                        t("For the license") + " " + watch("driver_licence_type") + ", " + t("your age need to be over") +
                        " " +
                        vehiclesLicendes[watch("driver_licence_type")],
                },
            ]);
        }
    };

    const insertSentences = (field, nextField, userValue, isBlocked) => {
        const newSentences = [
            {
                user: "client",
                text: customChatText
                    ? customChatText
                    : userValue === false || userValue === "false"
                        ? t("No")
                        : userValue === true || userValue === "true"
                            ? t("Yes")
                            : (userValue && userValue !== "") || isBlocked
                                ? userValue
                                : chatBotSentences("no-answer"), // if user not answer cause of field is not mandatory. User send specific message
            },
        ];
        if (field.section !== nextField.section) {
            newSentences.push({
                user: "bot",
                text: chatBotSentences("new-section"),
                field: nextField.section,
            });
        }
        setChat([
            ...chat,
            ...newSentences,
            {
                user: "bot",
                text: chatBotSentences(
                    (userValue && userValue !== "") || isBlocked ? "next" : "empty"
                ),
                field: nextField.name,
            },
        ]);
    };

    const checkIsOk = (field) => {
        
        // JHCORE: Función que valida el formulario

        // checkbox cannot be required
        const isRequired =
            (field.required && formTypesConst[field.type] !== "checkbox") ||
            checkRelations(field.key, field.relations, watch()).required;
        if (isRequired) {
            const value = watch(field.key);
            const regex = new RegExp(field.validation);
            insertChatTextIfNotOk(field.validation, field.name, value, field.type);
            return (
                !!(!field.validation && (value === 0 || value === false || value)) ||
                (field.validation && regex.test(value))
            );
        } else {
            return true;
        }
    };

    const insertChatTextIfNotOk = (fieldValidation, fieldName, value, fieldType) => {
        let chatBotSentenceKey;
        const regex = new RegExp(fieldValidation);

        if ((!value && value !== 0 && value !== false) || value === "") {
            chatBotSentenceKey = "error-required-" + fieldType;
        } else if (fieldValidation && !regex.test(value)) {
            chatBotSentenceKey = "error-validation";
        } else {
            return;
        }
        setChat([
            ...chat,
            {
                user: "bot",
                type: "error",
                text: chatBotSentences(chatBotSentenceKey),
            },
        ]);
    };

    useEffect(() => {
        const knowTypeVehicle = () => {
            const car = fieldsList.find((item) => item.type === "car_model");
            if (car) {
                return "car";
            } else {
                const motorbike = fieldsList.find(
                    (item) => item.type === "motorbike_model"
                );
                return motorbike ? "motorbike" : "car";
            }
        };

        let typeOfVehicle = knowTypeVehicle();
        const getVehicleModels = async () => {
            if (watch("engine")) {
                const capitalizeEngine =
                    watch("engine").charAt(0).toUpperCase() + watch("engine").slice(1);
                const {data} = await API(
                    `data/${typeOfVehicle}/list/${vehicle[typeOfVehicle + "_brand"]}/${
                        vehicle[typeOfVehicle + "_model"]
                    }/${capitalizeEngine}`
                );

                const copyFields = [...fieldsList];

                const index = copyFields.findIndex(
                    (field) => field.type === typeOfVehicle + "_version"
                );

                //map is necessary for FormInput type select structure. This will be modify on this component html too
                copyFields[index] = {
                    ...copyFields[index],
                    options: data.map((item) => ({value: item.name, key: item.code})),
                };
                setFieldsList(copyFields);
            }
        };

        getVehicleModels();
    }, [watch("engine")]);

    return (
        <Box>
            <ChatBotList chat={chat}/>
            <ChatContext.Provider
                value={{vehicle, setVehicle, engine: watch("engine")}}
            >
                <Box mt={4}>
                    {fieldsList.map(
                        (field, index) =>
                            fieldIndex === index &&
                            !checkBlocks(field, watch()) && (
                                <Box key={index} w={"100%"}>
                                    {field.type !== "address" && (
                                        <FormInput
                                            control={control}
                                            errors={errors}
                                            onEnterPress={() => next(fieldIndex)}
                                            setCustomChatText={setCustomChatText}
                                            label={field.name}
                                            name={field.key}
                                            rules={checkRequired(field, watch())}
                                            type={formTypesConst[field.type]}
                                            options={
                                                field.options
                                                    ? field.options.map((option) => ({
                                                        label: option.value,
                                                        value: option.key,
                                                    }))
                                                    : null
                                            }
                                        />
                                    )}
                                    {field.type === "address" && field.key === "address" && (
                                        <AddressForm
                                            control={control}
                                            setValue={setValue}
                                            errors={errors}
                                            watch={watch}
                                        />
                                    )}
                                </Box>
                            )
                    )}
                </Box>
            </ChatContext.Provider>

            <Button mt={3} onPress={() => next(fieldIndex)}>
                {fieldsList.length - 1 === fieldIndex ? t("Save") : t("Next")}
            </Button>
        </Box>
    );
}

const checkRequired = (
    {required, validation, relations, key, type},
    formData
) => {
    // If have required true is required except if field type is "checkbox"
    return required
        ? validation
            ? {
                required: formTypesConst[type] !== "checkbox",
                pattern: new RegExp(validation),
            }
            : {
                required: formTypesConst[type] !== "checkbox",
                pattern:
                    formTypesConst[type] === "number" ? "^(0|[1-9][0-9]*)$" : null,
            }
        : checkRelations(key, relations, formData);
};

const checkRelations = (key, relations, formData) => {
    if (relations) {
        let isRequired;
        for (const relation of relations) {
            if (relation.relationType === "required") {
                isRequired = true;
                //only have effect with 2 or more rules
                for (const rule of relation.rules) {
                    const regex = new RegExp(rule.fieldValue);
                    if (
                        relation.rulesType === "AND" &&
                        (!formData[rule.fieldKey] ||
                            formData[rule.fieldKey] === "" ||
                            (rule.fieldRegexp && !regex.test(formData[rule.fieldKey])))
                    ) {
                        isRequired = false;
                        break;
                    }
                    if (relation.rulesType === "OR") {
                        if (
                            formData[rule.fieldKey] &&
                            formData[rule.fieldKey] !== "" &&
                            (!rule.fieldRegexp ||
                                (rule.fieldRegexp && regex.test(formData[rule.fieldKey])))
                        ) {
                            isRequired = true;
                            break;
                        } else {
                            isRequired = false;
                        }
                    }
                }
            }
        }
        return {required: isRequired};
    } else {
        return {};
    }
};

const checkBlocks = ({key, relations}, formData) => {
    if (relations) {
        let isBlocked;
        for (const relation of relations) {
            if (relation.relationType === "blocks") {
                isBlocked = true;
                //only have effect with 2 or more rules
                for (const rule of relation.rules) {
                    const regex = new RegExp(rule.fieldValue);
                    if (
                        relation.rulesType === "AND" &&
                        ((!rule.fieldRegexp &&
                                formData[rule.fieldKey] !== rule.fieldValue) ||
                            (rule.fieldRegexp && !regex.test(formData[rule.fieldKey])))
                    ) {
                        isBlocked = false;
                        break;
                    }
                    if (relation.rulesType === "OR") {
                        if (
                            (!rule.fieldRegexp &&
                                formData[rule.fieldKey] &&
                                formData[rule.fieldKey] === rule.fieldValue) ||
                            (rule.fieldRegexp && regex.test(formData[rule.fieldKey]))
                        ) {
                            isBlocked = true;
                            break;
                        } else {
                            isBlocked = false;
                        }
                    }
                }
            }
        }
        return isBlocked;
    } else {
        return false;
    }
};

const whatIsNextIndex = (fieldsList, fieldIndex) => {
    const field = fieldsList[fieldIndex];
    if (field.type === "address") {
        const finalFieldAddressIndex = fieldsList.findLastIndex(
            (field) => field.type === "address"
        );
        return finalFieldAddressIndex + 1;
    } else {
        return fieldIndex + 1;
    }
};

const checkDriverLicenseIsOk = (license, birthday) => {
    const ageDifMs = Date.now() - birthday;
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age >= vehiclesLicendes[license];
};

const vehiclesLicendes = {
    LCM: 14,
    AM: 15,
    A1: 16,
    A2: 18,
    B: 18,
    A: 20,
};
