export const transformDynamicData = (obj) => {
    const transformedData = []
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)) {
            let value;
            if (typeof obj[key] === "number" || typeof obj[key] === "boolean" || Array.isArray(obj[key])) {
                value = obj[key].toString();
            } else if (obj[key] instanceof Date) {
                value = obj[key].toJSON().substring(0, 10);
            } else {
                value = obj[key]
            }
            // need to parse all numbers and booleans to string
            transformedData.push({key, value})
        }
    }
    return transformedData;
}
