/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 22/07/2023
 * @description This React component represents a login page. It utilizes hooks like useForm, useNavigation, and useTranslation for form handling, navigation, and translation respectively. The onSubmit function handles form submission, making an API call to login. The UI includes input fields for phone number, a message about receiving a verification SMS, and a login button. It also provides a link to navigate to the registration page. The layout is structured using VStack, HStack, Box, Heading, Text, Input, FormInput, Button, and Link components, with styling and responsiveness applied using props like p, mt, w, and alignItems.
 */
import {Box, Button, Heading, HStack, Icon, Input, Link, Stack, Text, VStack} from "native-base";
import React, { useContext } from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {API} from "../../queries/api";
import FormInput from "../../components/FormInput/FormInput";
import {useNavigation} from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {formErrorValidationsConst} from "../../consts/formErrorValidations.const";
import {goToUtil} from "../../utils/goTo.util";
import Loading from "../../components/Loading/Loading";
import { GlobalContext } from "../../consts/globalContext.const";

export default function LoginPage() {

    const { isLoading, setIsLoading } = useContext(GlobalContext);

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            telephone: '',
        }
    });
    const navigation = useNavigation();


    const {t} = useTranslation();

    const onSubmit = async ({telephone}) => {
        setIsLoading(true);
        try {
            const phone = Number("34" + telephone);
            const {data: resData} = await API.post("user/login", {telephone: phone});
            await AsyncStorage.setItem("userId", resData.userId)
            await AsyncStorage.setItem("phone", JSON.stringify(phone))
            await AsyncStorage.setItem("msgId", resData.msgId)

            goToUtil('verification', navigation)
        } catch (e) {
            setIsLoading(false);
            console.error(e)
        } finally {
        }
    }


    return <VStack h={"100%"} justifyContent="center" alignItems={"center"}>
        <Loading />
        <Box p={12} backgroundColor="white" borderRadius="6" w={["90%", "90%", "600px"]}> <Box p={[0, 12]}>
            <Heading>{t("Join account")}</Heading>

            <Stack direction={["column", "row"]} mt="10" justifyContent="center">
                <Box w={["100%", "20%"]} alignItems="center">
                    <Input placeholder="+34" w="100%" isDisabled={true}/>
                </Box>
                <Box mt={["3", "0"]} pl={["0", "3"]} w={["100%", "80%"]} alignItems="center">
                    <FormInput control={control} w="100%" errors={errors} placeholder="Phone" name={"telephone"}
                               onEnterPress={handleSubmit(onSubmit)} type="number"
                               rules={{required: true, pattern: formErrorValidationsConst("phone", t)}}/>
                </Box>
            </Stack>

            <HStack mt="5" space={3}>
                <Icon size="5" mt="0.5" as={MaterialCommunityIcons} name="lock"/>
                <Text w="100%">
                    {t("You will receive an verification SMS code")}
                </Text>
            </HStack>

            <Button mt={"8"} w="100%" onPress={handleSubmit(onSubmit)}>{t("Log in")}</Button>

            <HStack mt="5" space={3} justifyContent="center">
                <Text>
                    {t("Don't you have account?")}
                    {" "}
                    <Link onPress={() => navigation.navigate('register')}>
                        {t("Register for free")}</Link>
                </Text>
            </HStack>
        </Box>
        </Box>
    </VStack>

}
