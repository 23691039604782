/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @description The `SendAgain` component manages a countdown timer, initially set to 60 seconds, allowing users to trigger an action (e.g., resend an SMS) only after the timer has elapsed. When the user clicks on the provided link (children of the component), the countdown starts, and the action is triggered. During the countdown, the component displays a message indicating the remaining time in seconds. Once the countdown reaches zero, it resets to the initial value, allowing the action to be triggered again. This component is useful for implementing rate-limited actions, such as resending verification codes, to enhance security and prevent abuse.
 */
import React, { useState, useEffect } from "react";
import { Box, Link, Text } from "native-base";
import { useTranslation } from "react-i18next";

let interval;
const initialCountValue = 60;
export default function SendAgain ({ onPress, children }) {
    const [sendAgainCount, setSendAgainCount] = useState(initialCountValue)
    const { t } = useTranslation()

    useEffect(() => {
        const counting = () => {
            setSendAgainCount((sendAgainCount) => {
                if (sendAgainCount === 0) {
                    clearInterval(interval)
                    return sendAgainCount
                } else {
                    return sendAgainCount - 1
                }
            })
        }

        interval = setInterval(counting, 1000)
        return () => clearInterval(interval)
    }, [])

    const onInitCountPress = () => {
        setSendAgainCount(initialCountValue)
        interval = setInterval(() => {
            setSendAgainCount((sendAgainCount) => {
                if (sendAgainCount === 0) {
                    clearInterval(interval)
                    return sendAgainCount
                } else {
                    return sendAgainCount - 1
                }
            })
        }, 1000)
        onPress()
    }

    return <Box>
        {sendAgainCount !== 0 &&
        <Text>{t("You need to wait")} 00:{("0" + sendAgainCount).slice(-2)} {t("seconds to send another SMS")}</Text>}
        {sendAgainCount === 0 && <Link onPress={onInitCountPress}>
            {children}
        </Link>}
    </Box>
}