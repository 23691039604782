/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/08/2023
 * @description This React component, `CommonSearch`, provides a generic search input functionality, displaying a list of suggestions based on user input. It debounces search input by 300 milliseconds to limit the frequency of `onSearch` callback executions, enhancing performance and user experience by reducing unnecessary API calls or processing. The component synchronizes its internal state with the `showingText` prop to reflect external changes. It features a scrollable list of suggestions (`showingList`) that appears below the search box, allowing users to select an item by tapping. The search box is styled to include an embedded search icon. Disabled and read-only states are supported, making the component adaptable to various UI contexts. The styles are defined to ensure the search suggestions list overlays other content appropriately with correct positioning, z-index, and styling.
 */
import {Box, Input, ScrollView, Text} from "native-base";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import {StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import {useEffect, useState} from "react";

export default function CommonSearch({
                                         showingList,
                                         onSearch,
                                         disabled,
                                         readOnly,
                                         selectItem,
                                         showingText,
                                         placeholder
                                     }) {
    const [searchTerm, setSearchTerm] = useState(showingText)

    useEffect(() => {
        if (showingText !== searchTerm) {
            const delayDebounceFn = setTimeout(() => {
                onSearch(searchTerm)
            }, 300)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchTerm])

    useEffect(() => {
        setSearchTerm(showingText)
    }, [showingText]);

    return <>
        <Box style={styles.searchBox}>
            <Box>
                <Input readOnly={readOnly} value={searchTerm}
                       disabled={disabled} placeholder={placeholder}
                       onChangeText={setSearchTerm}
                />
                <MaterialIcons style={styles.searchIcon} name="search" size={26}/>
            </Box>
            {showingList && showingList.length > 0 && <ScrollView maxH={200} style={styles.searchList}>
                {showingList.map((showingItem) => <Text key={showingItem.id}
                                                        onPress={() => selectItem(showingItem)}>{showingItem.label ? showingItem.label : showingItem.name}</Text>)}
            </ScrollView>}
        </Box>

    </>
}

const styles = StyleSheet.create({
            searchBox: {
                position: "relative",
                zIndex: undefined
            },
            searchIcon: {
                position: "absolute",
                top: 11,
                right: 11
            },
            searchList: {
                position: "absolute",
                top: 48,
                left: 0,
                width: "100%",
                backgroundColor: "white",
                boxSizing: "border-box",
                zIndex: 99,
                padding: 12,
                listStyle: "none",
                borderRadius: 6,
                borderWidth: 1,
                borderColor: theme.colors.gray["100"],
            },
            searchItem: {
                backgroundColor: "white",
                borderRadius: 6

            }
        }
    )
;
