import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import { API } from "../../queries/api";
import FormInput from "../../components/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Box, Button, Heading, Text, ScrollView } from "native-base";
import { globalStyles } from "../../consts/globalStyles.const";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import InsuranceGallery from "../../components/InsuranceGallery/InsuranceGallery";
import { goToUtil } from "../../utils/goTo.util";
import { Dimensions } from "react-native";

export default function SelectInsurancePage() {
    const [allInsurances, setAllInsurances] = useState([]);
    const [filteredInsurances, setFilteredInsurances] = useState([]);
    const [selectedPolicies, setSelectedPolicies] = useState([]);
    const { t } = useTranslation();
    const { control, errors, watch } = useForm({ defaultValues: { name: "" } });
    const navigation = useNavigation();

    const screenHeight = Dimensions.get("window").height;

    useEffect(() => {
        async function getAllPolicies() {
            try {
                const { data } = await API("company/company/all");
                setAllInsurances(data);
                setFilteredInsurances(data);
            } catch (e) {
                console.log(e);
            }
        }

        if (allInsurances.length === 0) {
            getAllPolicies();
        }
    }, [allInsurances]);

    useEffect(() => {
        const filterName = watch("name");
        if (filterName !== "") {
            const filtered = allInsurances.filter(insurance =>
                insurance.name.toLowerCase().includes(filterName.toLowerCase())
            );
            setFilteredInsurances(filtered);
        } else {
            setFilteredInsurances(allInsurances);
        }
    }, [watch("name"), allInsurances]);

    const selectPolicy = async (policy) => {
        const foundIndex = selectedPolicies.findIndex(selectedPolicy => selectedPolicy.id === policy.id);
        if (foundIndex !== -1) {
            const copySelectedPolicies = [...selectedPolicies];
            copySelectedPolicies.splice(foundIndex, 1);
            setSelectedPolicies(copySelectedPolicies);
        } else {
            setSelectedPolicies([...selectedPolicies, policy]);
        }
    }

    const doNext = async () => {
        await AsyncStorage.setItem("selectedInsurances", JSON.stringify(selectedPolicies));
        goToUtil("add-policy", navigation);
    }

    return (
        <Container style={{ height: '100%' }}>
            {filteredInsurances && (
                <>
                    <Heading mb={6}>{t("Actual insurances")}</Heading>
                    <Box>
                        <FormInput
                            control={control}
                            errors={errors}
                            placeholder={t("Search your company")}
                            name={"name"}
                            rules={{ required: true }}
                        />
                    </Box>
                    <Button
                        onPress={doNext}
                        mt={6}
                        style={(selectedPolicies.length === 0 || selectedPolicies.length > 9) ? globalStyles.disabled : null}
                        pointerEvents={(selectedPolicies.length === 0 || selectedPolicies.length > 9) ? "none" : "auto"}
                    >
                        {t("Next")}
                    </Button>
                    <Box mt={6} style={{ height: screenHeight * 0.6 }}>
                        <Text mb={2}>{t("Select companies which you have insurances hired")}</Text>
                        <ScrollView>
                            <InsuranceGallery
                                data={filteredInsurances}
                                onPress={selectPolicy}
                                selectedData={selectedPolicies}
                            />
                        </ScrollView>
                    </Box>
                </>
            )}
        </Container>
    );
}
