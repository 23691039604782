/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/08/2023
 * @description The `InlineSearch` component filters options based on user input, allowing selection from a dynamically generated list. It updates the search text to display selected item details, using specified keys to format this text. The component supports `readOnly` and `disabled` states and uses a `CommonSearch` component for displaying the search interface and list. Selection triggers an `onChange` callback with the chosen item.
 */
import {useState} from "react";
import CommonSearch from "../CommonSearch/CommonSearch";

export default function InlineSearch({options, onChange, readOnly, disabled, keys = ["name"]}) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);

    const onSearch = async (targetValue) => {
        if (targetValue !== "") {
            setShowingText(targetValue)
            const newShowingList = options.filter(option => option.label.toLowerCase().includes(targetValue.toLowerCase()))
            setShowingList(newShowingList)
        } else {
            setShowingText("")
            setShowingList([])
        }
    }

    const selectItem = (item) => {
        onChange(item); // cause of codescopic all data
        // need to
        // be string
        setShowingText(createShowingText(item, keys))
        setShowingList([])
    }

    return <CommonSearch showingList={showingList} onSearch={onSearch} showingText={showingText} selectItem={selectItem}
                         disabled={disabled} readOnly={readOnly}/>

}
export const createShowingText = (item, keys) => {
    return keys.reduce((textToShow, key) => textToShow === "" ? item[key] ? item[key] : "" : textToShow + (item[key] ? " " + item[key] : ""), "")
}
