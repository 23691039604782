import * as React from "react"
import Svg, { Path } from "react-native-svg"
const Logo = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" {...props}>
    <Path
      fill="#1395ec"
      d="M39.79 98.04c-1.3 1.22-2.67 2.6-2.04 4.67 1.42 4.59 5.87 3.25 8.55.84q8.4-7.52 16.8-15.06c6.32-5.66 10.16-10.25 17.68-7.51q2.88 1.05 6.75 4.91 11.05 11.03 22.1 22 2.16 2.14 2.37 3.78c.41 3.36-4.06 5.87-7.05 2.89q-12.2-12.18-24.37-24.4c-2.8-2.81-5.26-1.71-7.96.66q-10.37 9.14-20.63 18.39c-5.89 5.31-13.7 7.22-19.38.44-5.54-6.61-3.29-13.18 2.39-18.44q14.95-13.88 29.9-27.73 5.72-5.29 8.99-6.24 6.5-1.88 12.41 3.97 23.37 23.12 46.64 46.37c2.78 2.78 7.28-.19 6.75-3.64q-.25-1.64-2.34-3.75-40.4-40.82-57.96-57.59c-2.2-2.1-4.8-.58-6.72 1.34q-31.78 31.72-63.61 63.4c-2.28 2.27-5.44.67-6.48-1.77-1.06-2.47.77-3.99 2.38-5.61q30.17-30.31 60.62-60.33c8.19-8.07 13.85-8.55 21.96-.5q28.04 27.88 55.98 55.85c12.77 12.79-4.99 29.45-16.93 17.66q-23.27-22.98-46.42-46.09c-3.75-3.75-6.28-.3-9.03 2.25Q55.41 83.35 39.79 98.04Z"
    />
  </Svg>
)
export default Logo
