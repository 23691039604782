import {Box, Column, Link, Text} from "native-base";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BudgetsGallery from "../../../../components/BudgetsGallery/BudgetsGallery";
import Table from "../../../../components/Table/Table";
import {theme} from "../../../../styles/theme";
import {API} from "../../../../queries/api";
import {formatCoverages} from "../../../OffersDetailPage/OffersDetailPage";

export default function OffersHireInfo({budget, policyTypeId, onHireInsurance}) {
    const {t} = useTranslation()
    const [formattedData, setFormattedData] = useState([]);

    const headerTemplate = (budget) => {
        return <Column p={6} alignItems={"center"}>
            <Text style={{
                color: theme.colors.secondary["700"],
                textAlign: "center",
                fontWeight: 600,
            }}>{t(budget.tag).toUpperCase()}</Text>
            <Link href={process.env.REACT_APP_IMAGE_URL + budget.fileLocation} isExternal={true}
                  textAlign={"center"}>{t("See detail")}</Link>
        </Column>
    }

    useEffect(() => {
        const getCoverages = async (policyTypeId, budgets) => {
            try {
                const {data: {data: coverages}} = await API("policy/policy-type/coverage/list/" + policyTypeId);
                setFormattedData(formatCoverages(coverages, budgets))
            } catch (e) {
                console.log(e)
            }
        }
        getCoverages(policyTypeId, [budget])

    }, []);

    return <Box>
        <BudgetsGallery data={[budget]} onPress={onHireInsurance}
                        buttonLabel={t("Hire insurance")}/>

        <Box mt={9}>{formattedData.length === 0 ? <Text mb={3} fontSize={14}>{t("No coverages")}</Text> : <>
            <Text mb={3} fontSize={14}>{t("Coverages")}</Text>

            <Table theme={"light"} data={formattedData}
                   dataHeader={["", ...[budget].map(budget => headerTemplate(budget))]}></Table></>
        }  </Box>

    </Box>
}
