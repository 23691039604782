/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 08/09/2023
 * @description The `PolicyGallery` component renders a list of insurance policies in a gallery format, allowing users to interact with each policy. It supports both mobile and desktop layouts, adjusting its design responsively. The gallery displays key information about each policy, including the company logo, policy type name, reference value, and policy number. Additionally, it shows the annual payment adjusted by payment frequency and provides a button or pressable area (depending on the screen size) for further action, labeled with dynamic text. This component is designed for flexibility in use, enabling actions like viewing more details about a policy or initiating a claim. The layout and functionality are optimized for user engagement, making it easy to navigate through various insurance policies.
 */
import {Box, Button, Column, Flex, Image, Pressable, Row, Text,} from "native-base";
import {StyleSheet} from "react-native";
import {theme} from "../../styles/theme";
import React from "react";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {useTranslation} from "react-i18next";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {truncateNumUtil} from "../../utils/truncateNum.util";

export default function PolicyGallery({data, onPress, onPressBtn, text}) {
    const {t} = useTranslation();
    return (
        <Box style={styles.container}>
            {data &&
                data.map((item, index) => (
                    <Pressable
                        onPress={() => onPress(item)}
                        key={index}
                        mb={3}
                        style={styles.box}
                        paddingLeft={["16px", "40px"]}
                        padding={["16px", "10px"]}
                    >
                        <Flex
                            flexDirection={["column", "row"]}
                            space={3}
                            width={"100%"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Row
                                flexDirection={["column", "row"]}
                                width={["100%", "55%"]}
                                alignItems={"center"}
                                space={6}
                            >
                                {item.company && (
                                    <Image
                                        style={styles.image}
                                        resizeMode={"contain"}
                                        source={{
                                            uri: process.env.REACT_APP_IMAGE_URL + item.company.logo,
                                        }}
                                        alt={t("Company logo")}
                                    />
                                )}

                                {!item.company && <Logo style={styles.image}/>}

                                <Column>
                                    <Text
                                        textAlign={["center", "left"]}
                                        numberOfLines={1}
                                        fontSize={"18"}
                                        fontWeight={"600"}
                                        color={"secondary.700"}
                                    >
                                        {item.policyType.name}
                                    </Text>
                                    <Text
                                        textAlign={["center", "left"]}
                                        numberOfLines={1}
                                        fontSize={14}
                                    >
                                        {item.referenceValue}
                                    </Text>

                                    {item.policyNumber && <Text
                                        textAlign={["center", "left"]}
                                        numberOfLines={1}
                                        fontSize={14}
                                    >
                                        {item.policyNumber}
                                    </Text>}
                                </Column>
                            </Row>
                            <Row
                                width={["100%", "45%"]}
                                mt={["3", 0]}
                                justifyContent={["center", "space-between"]}
                            >
                                <Column width={["auto", "50%"]}>
                                    <Text
                                        textAlign={["center", "left"]}
                                        numberOfLines={1}
                                        fontSize={"18"}
                                        fontWeight={"600"}
                                        color={"secondary.700"}
                                    >
                                        {truncateNumUtil(
                                            (item.annualPayment /
                                                paymentFrequencyConst.find(
                                                    (paymentFrequency) =>
                                                        paymentFrequency.value === item.frequency
                                                ).fractional))}
                                        €
                                    </Text>
                                    <Text
                                        textAlign={["center", "left"]}
                                        numberOfLines={1}
                                        fontSize={14}
                                        fontWeight={"600"}
                                        color={"secondary.700"}
                                    >
                                        {t("to") + " " + t(item.frequency).toLowerCase()}
                                    </Text>
                                </Column>
                                {!item.mediate && <Pressable
                                    onPress={() => onPressBtn(item)}
                                    display={["none", "flex"]}
                                    justifyContent="flex-end"
                                    alignItems={"center"}
                                    flexDirection={"row"}
                                    space={3}
                                >
                                    <Text numberOfLines={1}>{text}</Text>
                                    <MaterialCommunityIcons
                                        name="chevron-right"
                                        color={theme.colors.gray["400"]}
                                        size={24}
                                    />
                                </Pressable>}
                            </Row>
                            {!item.mediate && <Button
                                onPress={() => onPressBtn(item)}
                                width="100%"
                                mt="3"
                                display={["flex", "none"]}
                            >
                                {text}
                            </Button>}
                        </Flex>
                    </Pressable>
                ))}
        </Box>
    );
}

const styles = StyleSheet.create({
    container: {
        flexWrap: "wrap",
        flexDirection: "row",
        width: "100%",
    },
    box: {
        borderWidth: 1,
        borderColor: theme.colors.gray["100"],
        backgroundColor: theme.colors.white,
        borderRadius: 6,
        textAlign: "center",
        alignItems: "center",
        fontWeight: "bold",
        width: "100%",
    },
    image: {
        width: 100,
        height: 100,
    },
});
