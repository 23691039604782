/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/09/2023
 * @description The `RegisterPage` component is a form for user registration. It uses `react-hook-form` for form handling and navigation from `react-navigation`. Users input their email and telephone number, agree to terms, then continue. Upon submission, the data is sent to the server for registration. If successful, users are navigated to the verification page.
 */
import {Box, Button, Heading, HStack, Icon, Input, Link, Stack, Text, VStack} from "native-base";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {API} from "../../queries/api";
import FormInput from "../../components/FormInput/FormInput";
import {useNavigation} from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function RegisterPage() {
    const [groups, setGroups] = useState()
    
    useEffect(() => {
        async function getGroups() {
            try {
                const res = await API.get("user-bo/groups");
                const colectivos = res.data.data.map(o => {
                    return {
                        key: o.id, 
                        label: o.description,
                        value: o.id
                    }
                });
                setGroups(colectivos);
            } catch (error) {
                console.error(error);
                setGroups([]);
            }
        }

        getGroups();
    }, [])

    const {control,watch, handleSubmit, errors} = useForm({
        defaultValues: {
            telephone: '',
            isGroupSelected: false
        }
    });
    const navigation = useNavigation();


    const {t} = useTranslation();

    const onSubmit = async ({telephone, email, advisorId}) => {
        try {
            const phone = Number("34" + telephone);
            advisorId =  watch("isGroupSelected") && advisorId != "__NativebasePlaceholder__" && advisorId != "" ? advisorId : undefined;
            const {data: resData} = await API.post("user/register", {email, phone, advisorId});
            await AsyncStorage.setItem("userId", resData.userId)
            await AsyncStorage.setItem("phone", JSON.stringify(phone))
            await AsyncStorage.setItem("msgId", resData.msgId)

            navigation.navigate('verification', {isFromRegister: true})
        } catch (e) {
            console.error(e)
        }
    }


    return <VStack h={"100%"} justifyContent="center" alignItems={"center"}>
        <Box p={12} backgroundColor="white" borderRadius="6" w={["90%", "90%", "600px"]}> <Box p={[0, 12]}>
            <Heading>{t("Register free")}</Heading>


            <Box mt="10" w={"100%"} alignItems="center">
                <FormInput control={control} errors={errors} placeholder={t("Email")} name={"email"}
                           onEnterPress={handleSubmit(onSubmit)}
                           rules={{required: true}}/>
            </Box>
            <Stack direction={["column", "row"]} w="100%" justifyContent="center">
                <Box mt={["3", "3"]} w={["100%", "20%"]} alignItems="center">
                    <Input placeholder="+34" w="100%" isDisabled={true}/>
                </Box>
                <Box mt={["3", "3"]} pl={["0", "3"]} w={["100%", "80%"]} alignItems="center">
                    <FormInput control={control} w="100%" errors={errors} placeholder={t("Phone")} name={"telephone"}
                               onEnterPress={handleSubmit(onSubmit)}

                               rules={{required: true}}/>
                </Box>
            </Stack>
            <Box w={"100%"}>
                <HStack mt="5" space={3}>
                    <Box w={4}>
                        <FormInput control={control} errors={errors} name={"isGroupSelected"}
                                   type={"checkbox"}
                                   rules={{required: false}}/>
                    </Box>
                    <Text w="100%">
                        {t("Pertenezco a un colectivo.")}
                    </Text>
                </HStack>
                {watch("isGroupSelected") && (
                    <Box mt="5">
                        <FormInput
                            control={control}
                            errors={errors}
                            name={"advisorId"}
                            type={"select"}
                            options={groups}
                            placeholder={t("Seleccione su colectivo")}
                            setCustomChatText={(text) => text}
                            rules={{required: false}}
                        />
                    </Box>
                )}
            </Box>
            <Box w={"100%"}>
                <HStack mt="5" space={3}>

                    <Box w={4}>
                        <FormInput control={control} errors={errors} name={"isTermsAccepted"}
                                   type={"checkbox"}
                                   rules={{required: true}}/>
                    </Box>
                    <Text w="100%">
                        {t("I accept the")} <Link  onPress={() => navigation.navigate('legal-advise')}>{t("Legal Advise")}</Link> {t("and the")} <Link  onPress={() => navigation.navigate('privacy-policy-and-cookies')}>{t("Privacy Policy and Cookies")}</Link>
                    </Text>
                </HStack>

            </Box>

            <Button isDisabled={!watch("isTermsAccepted")} mt={"8"} w="100%" onPress={handleSubmit(onSubmit)}>{t("Continue")}</Button>



            <HStack mt="5" space={3}>
                <Icon size="5" mt="0.5" as={MaterialCommunityIcons} name="lock"/>
                <Text w="100%">
                    {t("You will receive an verification SMS code")}
                </Text>
            </HStack>


            <HStack mt="5" space={3} justifyContent="center">
                <Text>
                    {t("Do you have an account already?")}
                    {" "}
                    <Link onPress={() => navigation.navigate('login')}>
                        {t("Log in")}
                    </Link>

                </Text>
            </HStack>
        </Box>
        </Box>
    </VStack>

}
