/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 07/12/2023
 * @description React component that represents a Privacy Policy and Cookies page. This page includes various sections explaining the privacy policy, the use of cookies, and how to manage them. Here's a breakdown of the component:
 *
 * Privacy Policy Section:
 *
 * It includes information about the responsible entity for data processing, the purpose of data processing, legal basis, data retention period, and the user's rights.
 * The user's rights and contact information for exercising those rights are provided.
 * Cookies Information Section:
 *
 * It explains what cookies are and their purpose.
 * It describes different types of cookies based on their purpose, ownership, and storage duration.
 * Cookies Used on the Website:
 *
 * It lists the cookies controlled by the website's editor, categorized by their purpose (technical, analytical, or advertising).
 * It also lists third-party cookies and provides links to their privacy policies.
 * Cookie Configuration Panel:
 *
 * It mentions a panel where users can configure the cookies that the website can install in their browser, excluding necessary technical or functional cookies.
 * Managing Cookies from the Browser:
 *
 * It provides information on how to manage and delete cookies from various commonly used browsers.
 * The component structure is well-organized, with headings, text paragraphs, dot lists, and tables used appropriately to present the information clearly. The information provided covers essential aspects of privacy policies and cookie usage on the website.
 */
import Container from "../../components/Container/Container";
import {Box, Heading, Text, View} from "native-base";
import DotList from "../../components/ListDot/ListDot";
import {theme} from "../../styles/theme";
import Table from "../../components/Table/Table";


const firstDotList = [
    {
        value: "Remisión de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio electrónico o físico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones serán realizadas por el RESPONSABLE y estarán relacionadas con sus productos y servicios, o de sus colaboradores o proveedores, con los que este haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos personales.",
    },
    {
        value: "Realizar estudios de mercado y análisis estadísticos."
    }, {
        value: "Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las formas de contacto que se ponen a su disposición en la página web del RESPONSABLE."
    }, {
        value: "Remitir el boletín informativo online, sobre novedades, ofertas y promociones en nuestra actividad."
    }
]


const secondDotList = [
    {
        value: "Con el consentimiento del USUARIO: remisión de comunicaciones comerciales y del boletín informativo.",
    },
    {
        value: "Por interés legítimo del RESPONSABLE: realizar estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del USUARIO."
    }
]

const thirdDotList = [
    {
        value: "Derecho a retirar el consentimiento en cualquier momento.",
    },
    {
        value: "Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento."
    }, {
        value: "Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente."
    }
]


const firstDotListC = [
    {
        value: "Derecho a retirar el consentimiento en cualquier momento.",
    },
    {
        value: "Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento."
    }, {
        value: "Derecho a presentar una reclamación ante la Autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente."
    }
]
const firstTableHeaderC = ['Propiedad', 'Cookie', 'Finalidad', 'Plazo'];
const firstTableC = [
    ["google.com", "__Secure-1PAPISID", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en un año"],
    ["google.com", "__Secure-1PSID", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en un año"],
    ["google.com", "__Secure-1PSIDCC", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en un año"],
    ["google.com", "__Secure-3PSIDCC", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en un año"],
    ["google.com", "__Secure-ENID", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en 6 meses"],
    ["google.com", "AEC", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en 5 meses"],
    ["google.com", "SEARCH_SAMESITE", "SameSite evita que el navegador envíe esta cookie junto con las solicitudes entre sitios. El objetivo principal es mitigar el riesgo de fuga de información de origen cruzado. También proporciona cierta protección contra ataques de falsificación de solicitudes entre sitios", "en 3 meses"],
    ["prudenzia.es", "moove_gdpr_popup", "Cookie necesaria para la utilización de las opciones y servicios del sitio web", "en un año"],

]


const secondTableC = [
    ["google.com", "__Secure-3PAPISID", "Estas cookies se utilizan para entregar anuncios más relevantes para usted y sus intereses", "en un año"],
    ["google.com", "__Secure-3PSID", "Estas cookies se utilizan para entregar anuncios más relevantes para usted y sus intereses", "en un año"]
]

const thirdTableC = [
    ["google.com", "1P_JAR", "Estas cookies se establecen a través de vídeos de youtube incrustados. Registran los datos estadísticos anónimos sobre, por ejemplo, cuántas veces se reproduce el vídeo y las configuraciones que se utilizan para la reproducción", "en un mes"],
    ["google.com", "APISID", "Descargar ciertas herramientas de Google y guardar ciertas preferencias, por ejemplo, el número de resultados de la búsqueda por hoja o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la búsqueda de Google", "en un año"],
    ["google.com", "HSID", "Descargar ciertas herramientas de Google y guardar ciertas preferencias, por ejemplo, el número de resultados de la búsqueda por hoja o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la búsqueda de Google", "en un año"],
    ["google.com", "NID", "Estas cookies se utilizan para recopilar estadísticas del sitio web y rastrear las tasas de conversión y la personalización de anuncios de Google", "en 7 meses"],
    ["google.com", "SAPISID", "Descargar ciertas herramientas de Google y guardar ciertas preferencias, por ejemplo, el número de resultados de la búsqueda por hoja o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la búsqueda de Google", "en un año"],
    ["google.com", "SID", "Descargar ciertas herramientas de Google y guardar ciertas preferencias, por ejemplo, el número de resultados de la búsqueda por hoja o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la búsqueda de Google", "en un año"],
    ["google.com", "SIDCC", "Descargar ciertas herramientas de Google y guardar ciertas preferencias, por ejemplo, el número de resultados de la búsqueda por hoja o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la búsqueda de Google", "en un año"],
    ["google.com", "SSID", "Descarga ciertas herramientas de Google y guarda ciertas preferencias, por ejemplo, el número de resultados de búsqueda por página o la activación del filtro SafeSearch. Ajusta los anuncios que aparecen en la Búsqueda de Google", "en un año"],
]

const fourthTableHeaderC = ['Editor', 'Política de privacidad'];
const fourthTableC = [
    ["Google", "https://privacy.google.com/take-control.html"],
    ["Google", "https://safety.google/privacy/privacy-controls/"]
]

const fifthTableC = [
    ["Eliminar las cookies del dispositivo", "Las cookies que ya están en un dispositivo se pueden eliminar borrando el historial del navegador, con lo que se suprimen las cookies de todos los sitios web visitados. Sin embargo, también se puede perder parte de la información guardada (por ejemplo, los datos de inicio de sesión o las preferencias de sitio web)"],
    ["Gestionar las cookies específicas del sitio", "Para tener un control más preciso de las cookies específicas de cada sitio, los usuarios pueden ajustar su configuración de privacidad y cookies en el navegador"],
    ["Bloquear las cookies", "Aunque la mayoría de los navegadores modernos se pueden configurar para evitar que se instalen cookies en los dispositivos, eso puede obligar al ajuste manual de determinadas preferencias cada vez que se visite un sitio o página. Además, algunos servicios y características pueden no funcionar correctamente (por ejemplo, los inicios de sesión con perfil)"]
]

const sixthTableC = [
    ["Chrome", "http://support.google.com/chrome/answer/95647?hl=es"],
    ["Edge", "https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"],
    ["Explorer", "https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer"],
    ["Firefox", "https://www.mozilla.org/es-ES/privacy/websites/#cookies"],
    ["Safari", "https://support.apple.com/es-es/guide/safari/sfri11471/mac"],
    ["Opera", "https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData"],
]


export default function PrivacyPolicyAndCookies() {
    return <Container>

        <Box mb={6}>
            <Heading mb={3}>POLÍTICA DE PRIVACIDAD</Heading>

            <Heading fontSize={16} mb={3}>1. INFORMACIÓN AL USUARIO</Heading>
            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Quién es el responsable del tratamiento de
                tus datos personales?</Text>
            <Text mb={3}><Text fontWeight={700}>PRUDENZIA BROKER GROUP S.L</Text> es el RESPONSABLE del tratamiento de
                los datos personales del
                USUARIO y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el
                Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre
                (LOPDGDD).</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Para qué tratamos tus datos
                personales?</Text>
            <Text mb={3}>Para mantener una relación comercial con el usuario. Las operaciones previstas para realizar el
                tratamiento son:</Text>

            <DotList data={firstDotList}></DotList>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Por qué motivo podemos tratar tus datos
                personales?</Text>
            <Text mb={3}>Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:</Text>

            <DotList data={secondDotList}></DotList>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Durante cuánto tiempo guardaremos tus
                datos personales?</Text>
            <Text mb={3}>Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o
                existan
                prescripciones legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán
                con medidas de seguridad adecuadas para garantizar la anonimización de los datos o la destrucción total
                de los mismos.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿A quién facilitamos tus datos
                personales?</Text>
            <Text mb={3}>No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario
                para el
                desarrollo y ejecución de las finalidades del tratamiento, a nuestros proveedores de servicios
                relacionados
                con comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y
                de encargado de tratamiento exigidos por la normativa vigente de privacidad.</Text>

            <Text fontSize={16} mb={3} color={theme.colors.secondary["500"]}>¿Cuáles son tus derechos?</Text>
            <Text mb={3}>Los derechos que asisten al USUARIO son:</Text>

            <DotList data={thirdDotList}></DotList>

            <Text mb={3} fontWeight={700}>Datos de contacto para ejercer sus derechos:</Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>

        </Box>


        <Box mb={6}>
            <Heading fontSize={16} mb={3}>2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA
                POR EL USUARIO</Heading>
            <Text mb={3}>Los USUARIOS, mediante la marcación de las casillas correspondientes y la entrada de datos en
                los
                campos, marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de
                descarga, aceptan expresamente y de forma libre e inequívoca, que sus datos son necesarios para
                atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos
                restantes. El USUARIO garantiza que los datos personales facilitados al RESPONSABLE son veraces y
                se hace responsable de comunicar cualquier modificación de los mismos.
                El RESPONSABLE informa de que todos los datos solicitados a través del sitio web son obligatorios, ya
                que son necesarios para la prestación de un servicio óptimo al USUARIO. En caso de que no se faciliten
                todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados
                a
                sus necesidades.</Text>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>3. MEDIDAS DE SEGURIDAD</Heading>
            <Text mb={3}>Que de conformidad con lo dispuesto en las normativas vigentes en protección de datos
                personales, el
                RESPONSABLE está cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para
                el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios
                descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente
                en
                relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines
                para los que son tratados.</Text>
            <Text mb={3}>El RESPONSABLE garantiza que ha implementado políticas técnicas y organizativas apropiadas para
                aplicar las medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los
                derechos y libertades de los USUARIOS y les ha comunicado la información adecuada para que puedan
                ejercerlos.</Text>
            <Text mb={3}>Para más información sobre las garantías de privacidad, puedes dirigirte al RESPONSABLE a
                través de
                PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>
        </Box>


        <Box mb={6}>
            <Heading mb={3}>COOKIES</Heading>
            <Heading fontSize={16} mb={3}>INFORMACIÓN SOBRE COOKIES</Heading>

            <Text mb={3}>Conforme con la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de
                comercio electrónico (LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento Europeo
                y del Consejo, de 27 de abril de 2016, General de Protección de Datos (GDPR) y la Ley Orgánica
                3/2018, de 5 de diciembre, de Protección de Datos y Garantía de los Derechos Digitales
                (LOPDGDD), es obligado obtener el consentimiento expreso del usuario de todas las páginas web
                que usan cookies prescindibles, antes de que este navegue por ellas.</Text>

        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>¿QUÉ SON LAS COOKIES?</Heading>

            <Text mb={3}>Las <Text fontWeight={700}>cookies</Text> y otras tecnologías similares tales como local shared
                objects, flash <Text fontWeight={700}>cookies</Text> o píxeles,
                son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca
                de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio.</Text>
            <Text mb={3}>Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos
                concernientes al usuario, como sus preferencias para la visualización de las páginas de ese
                servidor, nombre y contraseña, productos que más le interesan, etc.</Text>

        </Box>


        <Box mb={6}>
            <Heading fontSize={16} mb={3}>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS</Heading>

            <Text mb={3}>Según la directiva de la UE, las <Text fontWeight={700}>cookies</Text> que requieren el
                consentimiento informado por parte del
                usuario son las <Text fontWeight={700}>cookies</Text> de analítica y las de publicidad y afiliación,
                quedando exceptuadas las de
                carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios
                expresamente solicitados por el usuario.</Text>

        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>TIPOS DE COOKIES</Heading>
            <Text fontWeight={700}>SEGÚN LA FINALIDAD</Text>

            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies técnicas y funcionales:</Text> son aquellas que
                    permiten al usuario la navegación a
                    través de una página web, plataforma o aplicación y la utilización de las diferentes opciones
                    o servicios que en ella existan.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies analíticas:</Text> son aquellas que permiten al
                    responsable de las mismas el
                    seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están
                    vinculadas. La información recogida mediante este tipo de <Text fontWeight={"700"}>cookies</Text> se
                    utiliza en la medición
                    de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de
                    navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de
                    introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del
                    servicio.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies publicitarias:</Text> son aquellas que permiten la
                    gestión, de la forma más eficaz
                    posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página
                    web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios
                    como el contenido editado o la frecuencia en la que se muestran los anuncios.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies de publicidad comportamental:</Text> recogen
                    información sobre las preferencias y
                    elecciones personales del usuario <Text fontWeight={"700"}>(retargeting)</Text> para permitir la
                    gestión, de la forma más
                    eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una
                    página web, aplicación o plataforma desde la que presta el servicio solicitado.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies sociales:</Text> son establecidas por las plataformas
                    de redes sociales en los servicios
                    para permitirle compartir contenido con sus amigos y redes. Las plataformas de medios
                    sociales tienen la capacidad de rastrear su actividad en línea fuera de los Servicios. Esto
                    puede afectar al contenido y los mensajes que ve en otros servicios que visita.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies de afiliados:</Text> permiten hacer un seguimiento de
                    las visitas procedentes de otras
                    webs, con las que el sitio web establece un contrato de afiliación (empresas de afiliación).</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies de seguridad:</Text> almacenan información cifrada
                    para evitar que los datos guardados
                    en ellas sean vulnerables a ataques maliciosos de terceros.</Text>
            </View>


            <Text fontWeight={700}>SEGÚN LA PROPIEDAD</Text>

            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies propias:</Text> son aquellas que se envían al equipo
                    terminal del usuario desde un
                    equipo o dominio gestionado por el propio editor y desde el que se presta el servicio
                    solicitado por el usuario.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies de terceros:</Text> son aquellas que se envían al
                    equipo terminal del usuario desde un
                    equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los
                    datos obtenidos través de las cookies.</Text>
            </View>

            <Text fontWeight={700}>SEGÚN EL PLAZO DE CONSERVACIÓN</Text>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies de sesión:</Text> son un tipo de <Text
                    fontWeight={"700"}>cookies</Text> diseñadas para recabar y almacenar datos
                    mientras el usuario accede a una página web.</Text>
            </View>
            <View ml={6} mb={3}>
                <Text>{`\u2022`} <Text fontWeight={"700"}>Cookies persistentes:</Text> son un tipo de cookies en el que
                    los datos siguen almacenados en
                    el terminal y pueden ser accedidos y tratados durante un período definido por el
                    responsable de la <Text fontWeight={"700"}>cookie</Text>, y que puede ir de unos minutos a varios
                    años.</Text>
            </View>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>TRATAMIENTO DE DATOS PERSONALES</Heading>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L es el <Text fontWeight={"700"}>Responsable del tratamiento</Text> de
                los datos personales del
                <Text fontWeight={"700"}>Interesado</Text> y le informa de que estos datos serán tratados de conformidad
                con lo dispuesto en el
                Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la siguiente
                información del tratamiento:</Text>
            <Text mb={3}><Text fontWeight={"700"}>Fines del tratamiento:</Text> según se especifica en el apartado
                de <Text fontWeight={"700"}>cookies</Text> que se utilizan en este sitio
                web.</Text>
            <Text mb={3}><Text fontWeight={"700"}>Legitimación del tratamiento:</Text> salvo en los casos en los que
                resulte necesario para la navegación
                por la web, por consentimiento del interesado (art. 6.1 GDPR).</Text>
            <Text mb={3}><Text fontWeight={"700"}>Criterios de conservación de los datos:</Text> según se especifica en
                el apartado de <Text fontWeight={"700"}>cookies</Text> utilizadas
                en la web.</Text>
            <Text mb={3}><Text fontWeight={"700"}>Comunicación de los datos:</Text> no se comunicarán los datos a
                terceros, excepto en cookies
                propiedad de terceros o por obligación legal.</Text>

            <Text mb={3} fontWeight={"700"}>Derechos que asisten al Interesado:</Text>
            <DotList data={firstDotListC}/>

            <Text mb={3} fontWeight={"700"}>Datos de contacto para ejercer sus derechos:</Text>
            <Text mb={3}>PRUDENZIA BROKER GROUP S.L. PASEO 12 ESTRELLAS, 2 1 A - 28042 Madrid (Madrid). E-mail:
                administracion@prudenzia.es</Text>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3}>COOKIES UTILIZADAS EN ESTE SITIO WEB</Heading>
        </Box>

        <Box mb={6}>
            <Heading color={theme.colors.secondary["500"]} fontSize={16} mb={3}>COOKIES CONTROLADAS POR EL
                EDITOR</Heading>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>Técnicas y funcionales</Heading>

            <Table data={firstTableC} dataHeader={firstTableHeaderC}/>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>Analíticas</Heading>

            <Table data={secondTableC} dataHeader={firstTableHeaderC}/>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>Publicitarias</Heading>

            <Table data={thirdTableC} dataHeader={firstTableHeaderC}/>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>COOKIES DE TERCEROS</Heading>
            <Text mb={3}>Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en
                todo
                momento sus condiciones de servicio, finalidad y utilización de las cookies, etc.</Text>
            <Text mb={3} fontWeight={"700"}>Proveedores externos de este sitio web:</Text>

            <Table data={fourthTableC} dataHeader={fourthTableHeaderC}/>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>PANEL DE CONFIGURACIÓN DE
                COOKIES</Heading>
            <Text mb={3}>Desde este panel podrá configurar las cookies que el sitio web puede instalar en su navegador,
                excepto las cookies técnicas o funcionales que son necesarias para la navegación y la utilización de
                las diferentes opciones o servicios que se ofrecen</Text>
            {/*El pdf no da ningun enlace*/}
            {/*<Link href={"mailto:hola@prudenzia.es"} isExternal={true}>Panel de cookies</Link>*/}

        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>CÓMO GESTIONAR LAS COOKIES DESDE EL
                NAVEGADOR</Heading>

            <Table data={fifthTableC}/>
        </Box>

        <Box mb={6}>
            <Heading fontSize={16} mb={3} color={theme.colors.secondary["500"]}>CÓMO ELIMINAR LAS COOKIES DE LOS
                NAVEGADORES MÁS COMUNES</Heading>
            <Table data={sixthTableC}/>
        </Box>
    </Container>
}
